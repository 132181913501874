import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { GET_Articles_Tags, DELETE_Articles_Tags, POST_Articles_Tags, Articles_Tags_Unpublish, Edit_Tags_Details, Articles_Update_Tags } from '../../../modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'



const Tags = () => {
    const [getTags, setGetTags] = useState<{ article_tag_id: string; article_tag_title: string; article_tag_status: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tagsError, setTagsError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editModel, setEditModel] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [newTags, setNewTags] = useState('');
    const [loading, setLoading] = useState(false)
    const [editTags, setEditTags] = useState<{ article_tag_id: string, article_tag_title: string, article_tag_status: string }[]>([])
    const [tagsId, setTagsId] = useState('')
    const [currentPage, setCurrentPage] = useState(1); 
    const [filteredTags, setFilteredTags] = useState<{ article_tag_id: string, article_tag_title: string, article_tag_status: string }[]>([]);
 


    const handleButtonClick = () => {
        setShowModal(true); 
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewTags('');
        setTagsError('');
    };
 
    const handle_Add_Tags = async (values: any) => {
        setLoading(true)
        try {
            const newTagsValue = values.cate;

            if (!newTagsValue) {
                setTagsError('Tags name cannot be empty');
                setLoading(false);
                return; // Exit early if the category is empty
            }

            const { data: auth } = await POST_Articles_Tags(newTagsValue);

            // Check if the API request was successful and log the response
            console.log('API Response:', auth);

            if (auth === 'Already Exist') {
                // Handle the case where the API response indicates that the category already exists
                setTagsError('Tag already exist');
                setLoading(false);
            } else {
                setNewTags('');
                setTagsError('');
                setShowModal(false);
                setTimeout(() => {
                    setLoading(false)
                    GET_Tags("0");
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successfully Updated',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }, 500)
            }
        } catch (error) {
            console.error('Error adding tags:', error);
            setLoading(false);
        }
    };

    const GET_Tags = async (get_articles_tags: any) => {
        try {
            const response = await GET_Articles_Tags(get_articles_tags);
            setGetTags(response.data.article_tag_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GET_Tags("0");
    }, []);

    const handleTagDelete = (article_tag_id: string) => {
        const handleDelete = async () => {
            try {
                const response = await DELETE_Articles_Tags(article_tag_id);  
                const filterdata = getTags.filter(
                    (item) => item.article_tag_id !== article_tag_id
                );
                setGetTags(filterdata);
            } catch (error) {
                console.error("Error deleting event:", error);
            }
            // }
        };
        handleDelete();
    };

    const handleDeleteConfirmation = (article_tag_id: string) => {

        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this Tag..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                handleTagDelete(article_tag_id);
                Swal.fire(
                    'Deleted!',
                    'Your tag has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleUnpublish = async (article_tag_id: string, article_tag_status: string) => {
        try {
            const response = await Articles_Tags_Unpublish(article_tag_id, article_tag_status);
            const event_status_new = article_tag_status == '0' ? '1' : '0';
            setGetTags(datas =>
                datas.map(article => article.article_tag_id === article_tag_id ? { ...article, article_tag_status: event_status_new } : article));
        }
        catch (error) { console.error('Error updating event status:', error); }
    };

    const handleEditTags = async (article_tag_id: string) => {
        try {
            const response = await Edit_Tags_Details(article_tag_id)
            setEditTags(response.data.article_tag_details)
            setTagsId(article_tag_id)
            setEditModel(true)
        }
        catch (error) {
            console.error('Error fetching events data:', error);
        }
    }

    const handleUpdateTag = async () => {
        const category_update = document.getElementById('category_title') as HTMLInputElement;
        if (category_update) {
            // Use optional chaining to access the value property safely
            const cat_title = category_update.value;
            setLoading(true)
            try {
                const response = await Articles_Update_Tags(tagsId, cat_title);
                GET_Tags("0");
                setLoading(false)
                setEditModel(false);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully changed',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error updating category:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update category. Please try again.',
                });
            }
        }
    }

    useEffect(() => {
        // Filter getTags based on the search query
        const filtered = getTags.filter((item) => {
            const fullName = `${item.article_tag_title}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredTags(filtered);
    }, [searchQuery, getTags]);


    const itemsPerPage = 10;
    const totalItems = filteredTags.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const TagsDatas = filteredTags.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    return (
        <>
            <div className="card card-custom p-5 mt-5">
                <h3>Tags</h3>
                <div className='d-flex justify-content-between align-items-center mt-10'>
                    <div className="input-group">
                        <input type="search" className="form-control w-50"
                            value={searchQuery} placeholder='Search Tag...'
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={handleButtonClick}>
                            Create New Tag
                        </button>
                    </div>
                </div>
            </div>
            
            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Tag </h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                value={newTags}
                                name='cate'
                                onChange={(e: any) => {
                                    setNewTags(e.target.value);
                                    // Clear the error message when the user starts typing
                                    setTagsError('');
                                }}
                                className="form-control mt-5"
                                placeholder='Enter New Tag'
                            />
                            {tagsError && <div className="text-danger mt-2">{tagsError}</div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Cancel
                                </button>
                                <button type='submit' className={`btn btn-primary ${loading ? 'disabled' : ''}`} onClick={() => handle_Add_Tags({ cate: newTags })}>
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${editModel ? ' show' : ''}`} role="dialog" style={{ display: editModel ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Tag </h5>
                            <button type="button" className="btn-close" onClick={() => setEditModel(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                value={editTags.map((item) => item.article_tag_title)}
                                name='cate'
                                id='category_title'
                                onChange={(e: any) => {
                                    const updateEditTag = editTags.map((item) => ({
                                        ...item,
                                        article_tag_title: e.target.value
                                    }))
                                    setEditTags(updateEditTag);
                                    // Clear the error message when the user starts typing
                                    setTagsError('');
                                }}
                                className="form-control mt-5"
                                placeholder='Enter New Tag'
                            />
                            {tagsError && <div className="text-danger mt-2">{tagsError}</div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditModel(false)}>
                                    Cancel
                                </button>
                                <button type='submit' className={`btn btn-primary ${loading ? 'disabled' : ''}`} onClick={() => handleUpdateTag()}>
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (TagsDatas.length > 0 ? (
                <div className="table-responsive mt-4">
                    <div className="card card-custom mt-4">
                        <div className="card-body">
                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                        <th>Tag Name</th>
                                        <th className='text-center'>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {TagsDatas?.map((tag) =>
                                        <tr key={tag.article_tag_id}>
                                            <td>{tag.article_tag_title}</td>
                                            <td className='text-center'>
                                                {tag.article_tag_status === "0" ? (
                                                    <span className="badge bg-dark text-white">Unpublished</span>
                                                ) : tag.article_tag_status === "1" ? (
                                                    <span className="badge bg-success text-white">Published</span>
                                                ) : null}
                                            </td>
                                            <div className="dropdown">
                                                <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-3"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleEditTags(tag.article_tag_id)}>Edit</button></li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleDeleteConfirmation(tag.article_tag_id)}
                                                    >
                                                        Delete
                                                    </button>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(tag.article_tag_id, tag.article_tag_status)}>{tag.article_tag_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                                                </ul>
                                            </div>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            )
                :
                <div className="card card-custom mt-5">
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No Tags found</h3>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default Tags