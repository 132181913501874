import React from 'react'
import { Link } from 'react-router-dom'
import {useLocation} from 'react-router'

const Onboarding = () => {
    const location = useLocation()

    return (
        <>
            <div className="card card-custom ps-20 pe-20">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 members_tab">
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-onboarding/invite_page' className={"nav-link " + (location.pathname === '/admin-panel/settings-onboarding/invite_page' && 'active')}>
                            Invite page
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-onboarding/membership-questions' className={"nav-link " + (location.pathname === '/admin-panel/settings-onboarding/membership-questions' && 'active')}>
                            Membership questions
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Onboarding