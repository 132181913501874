import React from 'react';

interface CommentTimeDifferenceProps {
  createdAt: Date;
}

const CommentTimeDifference: React.FC<CommentTimeDifferenceProps> = ({ createdAt }) => {
  const calculateTimeDifference = () => {
    const today = new Date();
    const differenceInTime = today.getTime() - createdAt.getTime();

    const seconds = Math.floor(Math.abs(differenceInTime) / 1000); // Use Math.abs to ensure a positive value
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds > 0) {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    } else {
      return `Just now`; // Handle the case when the time is exactly the same as the current time
    }
  };

  return <span>{calculateTimeDifference()}</span>;
};

export default CommentTimeDifference;
