/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react'
import {KTIcon, toAbsoluteUrl,toAbsoluteUrlImage} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {Link} from 'react-router-dom'

import { getMembers } from "../../../../app/modules/auth/core/_requests";

type Props = {
  className: string
}

interface Member {
  first_name: string;
  last_name: string;
  company: string;
  role: string;
  id: number;
  user_pic: string;
  // Add other properties as needed
}

const ListsWidget2: React.FC<Props> = ({className}) => {
  const [membersData, setMembersData] = useState<Member[]>([]);
  const offset = 0;
const limit = 5;
useEffect(() => {
  const fetchData = async () => {
    try {
      const searchTerm = "";
      const response = await getMembers('get_members', offset, limit,searchTerm);
      const { data } = response;
      setMembersData(data.members);
     // console.log(data.members)
    } catch (error) {
      console.error('Error fetching members data:', error);
    }
  };

  fetchData();
}, []);


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Featured Members</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <Link
              to='/members'>
               <button
            type='button'
            className='btn btn-sm btn-light-success btn-rounded btn-active-light-primary'

          >
          View all
          </button>
          </Link>

          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {membersData.map((member, index) => (


        <div className='d-flex align-items-center mb-7' key={index}>

          <div className='symbol symbol-50px me-5 member_profile'>
          {(member.user_pic)!? <img src={toAbsoluteUrlImage(member.user_pic)} alt='User Pic' /> : <div className="symbol-label fs-5 fw-bold text-success"> {member.first_name.charAt(0)}{member.last_name.charAt(0)}</div>}

          </div>

          <div className='flex-grow-1'>
            <span className='text-dark fw-bold  fs-6'>
              {member.first_name}     {member.last_name}
            </span>
            <span className='text-muted d-block fw-semibold'> {member.role} {member.company ? ` - ${member.company}` : ''}</span>

          </div>
          {/* end::Text */}
        </div>
        ))}

      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget2}
