import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'

const ArticlesPages = () => {
    const location = useLocation()

    return (
        <>
            <div className="card card-custom">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 members_tab">
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-article/create-articles' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-article/create-articles') && 'active')}>
                            Article
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-article/category' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-article/category') && 'active')}>
                            Category
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-article/tags' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-article/tags') && 'active')}>
                            Tag
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ArticlesPages