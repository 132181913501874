/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl,toAbsoluteUrlImage} from '../../../helpers'
import {Link} from 'react-router-dom'


type Props = {
  cover_img?: string
  title?: string
  desc?: string
  posted_on: string
  posted_by: string
  status: string
  article_id: string
}

const ArticleCard: FC<Props> = ({
  cover_img,
  title,
  desc='',
  posted_on,
  posted_by,
  status,
  article_id
}) => {
  const truncateDescription = (text: string, maxWords: number): string => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    return truncatedWords.join(' ');
  };

  const truncatedDesc = truncateDescription(desc, 20); // Truncate to 20 words

  return (

    <>
    {status=='1'? (
<div className='col-lg-3 col-md-6 mb-4'>
    <div className="card h-100">
  <img src={cover_img} className="card-img-top" alt={title}/>
  <div className="card-body">
    <h5 className="card-title">{title}</h5>
    <span className='card-date pb-2'><i className="bi bi-calendar4"></i> {posted_on}</span>
    <p className="card-text mt-2" ><div dangerouslySetInnerHTML={{ __html: truncatedDesc }} /></p>
    {/* <a href="#" className="btn btn-success btn-sm">Read More</a> */}
    <Link to={`/article/${article_id}`} className="btn btn-success btn-sm">Read More</Link>
  </div>
</div>
</div>
):''}
</>
  )
}

export {ArticleCard}
