/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  event_title: string,
  event_description:string,
  event_startdate:string,
  event_starttime:string,
  event_enddate:string,
  event_endtime:string,
  event_timezone:string,
  event_id:string,
  event_location:string,
  event_status:string,
}

const formatDate = (dateString: string) => {
  if (!dateString) {
      return '';
  }
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  return `${month}`;
};


const ListsWidgetUpcomingEvents: React.FC<Props> = ({
  event_title,
  event_description,
  event_startdate,event_starttime,event_enddate,event_endtime,event_timezone,event_id,event_location,event_status

}) => {
  return (


    <>

    <div className='card card-xl-stretch mb-5 mb-xl-8 event-card'>
      {/* begin::Header */}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3 mt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center'>
          {/* begin::Symbol */}
          <div className="symbol symbol-70px me-5 text-center" >
          <div className="symbol-label fs-2 fw-bold text-dark">
  {event_startdate === event_enddate ? (
    <div className="text-center d-flex flex-column align-items-center">
      <span className='fs-6'>{event_startdate.slice(-2)}</span>
      <span className='fs-6 '>{formatDate(event_startdate)}</span>
    </div>
  ) : (
    <div className="text-center d-flex flex-column align-items-center">
      <span className='fs-6'>{event_startdate.slice(-2)}-{event_enddate.slice(-2)}</span>
      <span className='fs-6 '>{formatDate(event_startdate)}</span>
    </div>
  )}
</div>

                                </div>
          {/* end::Symbol */}
          {/* begin::Content */}
          <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
            {/* begin::Title */}
            <div className='flex-grow-1 my-lg-0 my-2 me-2'>
              <span className='text-gray-800 fw-bold text-hover-primary fs-6'>
               {event_title}
              </span>
              <span className='text-muted fw-semibold d-block pt-1'>{event_starttime} - {event_endtime} {event_timezone}</span>
              {event_location === 'Online' ? (
              <span className='badge badge-success mt-2'>{event_location}</span>
              ) : ( <span className='badge badge-primary mt-2'>{event_location}</span> ) }

              {/* <span className='text-muted fw-semibold d-block pt-1'>{event_description}</span> */}

              {/* <div className='me-6'>

                <div className="symbol-group symbol-hover mt-1">
  <div className="symbol symbol-circle symbol-40px ">
    <img src={toAbsoluteUrl("/media/avatars/300-6.jpg")} alt="" />
  </div>
  <div className="symbol symbol-circle symbol-40px">
    <img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="" />
  </div>
  <div className="symbol symbol-circle symbol-40px ">
    <img src={toAbsoluteUrl("/media/avatars/300-2.jpg")} alt="" />
  </div>

</div>
              </div> */}
            </div>
            {/* end::Title */}
            {/* begin::Section */}

            <div className='d-flex align-items-center'>
            <Link
          to={`/event-details/${event_id}/`}
              className='card border border-2 border-gray-300 border-hover'>
                 <button className={`btn btn-success btn-light btn-sm `}>
                 <KTIcon iconName='arrow-right' className='fs-2' />
                  View Event</button>
          </Link>

            </div>
            {/* end::Section */}
          </div>
          {/* end::Content */}
        </div>



        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>

    </>
  )
}

export {ListsWidgetUpcomingEvents}
