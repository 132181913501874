import React, { useState, useCallback, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getEventDetails, confirmEvent } from '../../app/modules/auth/core/_requests'
import { useAuth } from '../modules/auth'
import { number } from "yup";

interface EventDatas {
  event_name: string;
  event_start_date: string;
  event_end_date: string;
  event_time_zone: string;
  event_created_on: string;
  event_created_by: string;
  event_start_time: string;
  event_end_time: string;
  event_status: string;
  event_description: string;
  event_id: string;
  event_cover_img: string;
  event_location: string;
  event_location_url: string;
  rsvp_status: number;
  event_curr_status: string;
}


const formatDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  return `${month}`;
};


export function UserEventDetails() {
  const [Events, setEvents] = useState<EventDatas[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const { event_details_id } = useParams<{ event_details_id: string }>();
  const { currentUser, logout } = useAuth()
  const [userid, setUserid] = useState(currentUser?.id || 0)

  const fetchEventData = async (event_details_id: string, userid: number) => {
    try {
      // Perform an asynchronous API call using Axios
      const response = await getEventDetails(event_details_id, userid);
      setEvents(response.data.event_details);
      setIsLoading(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
      setEvents([]);
    }
  }

  useEffect(() => {


    fetchEventData(event_details_id ?? '', userid);


  }, []);

  const handleJoinNowClick = async (event_confirm: string) => {
    setbtnloading(true)
    try {
      // Perform an asynchronous API call using Axios
      const response = await confirmEvent(event_confirm, userid);
      setbtnloading(false)
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
    }
    fetchEventData(event_details_id ?? '', userid);
  };
  // const intl = useIntl()
  return (
    <>
      {loading ? (
        <div
          className="spinner-border text-success"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        Events.length > 0 ? (
          Events.map((event, index) => (
            <div key={index}>
              {event.event_status == '1' ? (
                <div className='row gy-5 g-xl-8'>

                  <div className='col-xl-8'>
                    <div className='card p-8'>

                      <img src={event.event_cover_img} className='w-100 border border-1 bg-light rounded'
                        style={{
                          objectFit: "cover"
                        }} alt='' />
                      <h3 className='mt-10 mb-10'>{event.event_name}</h3>
                      {/* <p style={{ whiteSpace: 'pre-wrap' }}>{event.event_description}</p> */}
                      <div className="article_para" dangerouslySetInnerHTML={{ __html: event.event_description}} />
                    </div>
                    {/* <div className='card p-5 mt-10'>
          <div className='d-flex align-items-sm-top mb-5 mt-2 '>

          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} className='' alt='' />
          </div>

          <div className='d-flex align-items-top flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2 '>
                <textarea className='form-control form-control-sm form-control-solid p-3' style={{height:'100px'}}placeholder='Share your Thoughts'></textarea>
                <button className='btn btn-success w-30 mt-3'>Post</button>
            </div>

          </div>


        </div>
          </div> */}
                    {/* <FeedsWidget3 className='mb-5 mb-xl-8 mt-5' /> */}
                  </div>
                  <div className='col-xl-4 sticky-column'>
                    <div className='card mb-xl-8 p-8'>
                      <div className='d-flex align-items-sm-top mb-5'>


                        <div className='symbol symbol-45px me-5 mt-3'>
                          <i className="las la-calendar-alt fs-2x"></i>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2 mt-3'>

                            <span className='text-gray-800 fs-6 fw-bold'>
                              Date & Time
                            </span>
                            <span className='text-muted fw-semibold d-block fs-7'>  {event.event_start_date === event.event_end_date ? (
                              <div>
                                {event.event_start_date.slice(-2)} {' '}
                                {formatDate(event.event_start_date)} {' '} {event.event_end_date.slice(0, 4)}
                              </div>
                            ) : (
                              <div>
                                {event.event_start_date.slice(-2)} - {event.event_end_date.slice(-2)} {' '}
                                {formatDate(event.event_start_date)} {' '} {event.event_end_date.slice(0, 4)}
                              </div>
                            )}</span>
                            <small className='text-muted fw-semibold d-block'> {event.event_start_time} - {event.event_end_time} {event.event_time_zone}</small>

                          </div>

                        </div>

                      </div>
                      <div className='d-flex align-items-sm-center mb-5'>

                        <div className='symbol symbol-45px me-5'>
                          <i className="las la-map-marker fs-2x"></i>
                        </div>

                        <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2'>
                            <span className='text-gray-800 fs-6 fw-bold'>
                              {event.event_location}
                            </span>
                            {event.rsvp_status == 0 ? (
                              <span className='text-muted fw-semibold d-block fs-7'>Event URL visible to attendees</span>
                            ) : (<a href={event.event_location_url} className='text-primary fw-semibold d-block fs-7' target="_blank">{event.event_location_url}</a>)}
                          </div>

                        </div>

                      </div>
                      {/* <div className='d-flex align-items-sm-center mb-5'>

          <div className='symbol symbol-45px me-5 mt-3'>
          <i className="las la-dollar-sign fs-2x"></i>
            </div>

          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>

              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
              Event Cost
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>Free</span>
            </div>

          </div>

        </div> */}
                      {/* <div className='d-flex align-items-sm-center mb-5'>

          <div className='symbol symbol-45px me-5 mt-3'>
          <i className="las la-users fs-2x"></i>
            </div>

          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>

              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Attendees(20+)
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>Mark</span>
            </div>

          </div>


        </div> */}
                      {/* <button className='btn btn-success w-100 mb-5'>RSVP</button> */}
                      {event.event_curr_status == 'Upcoming' ? (
                        event.rsvp_status == 0 ? (

                          <button className='btn btn-success w-100 mb-5 mt-5' onClick={() => handleJoinNowClick(event.event_id)}
                          >   {!btnloading && <span className='indicator-label'>RSVP</span>}
                            {btnloading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}</button>
                        ) : (<span className="text-success ms-2 "><i className="bi bi-check2-circle text-success  fs-3 me-3"></i> Confirmed</span>)
                      ) : ''}

                    </div>

                  </div>

                </div>
              ) : (<h4><i className="bi bi-emoji-frown-fill fs-3 pe-2"></i>This event is currently removed</h4>)}
            </div>
          ))
        ) : !loading && (
          <p>No result found</p>
        )
      )}
    </>
  )
}

