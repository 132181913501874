import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../../_metronic/helpers"


const Get_Started = () => {
    return (
        <>
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">Setup checklist</h3>
                </div>

                <img src={toAbsoluteUrl('/media/stock/Banner.png')} />
                <div className='ps-6 pe-6 mt-10'>
                    <h3>Create A Community</h3>
                    <p>Set up the key elements of your community</p>
                    <div className="w-95 bg-light mb-3">
                        <div className="bg-success rounded h-15px" role="progressbar" style={{ width: "75%" }}></div> {/* Increased height from h-5px to h-10px */}
                    </div>
                </div>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_1'
                            >
                                Active Home Page
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_1'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_1'
                            data-bs-parent='#kt_accordion_1'
                        >
                            {/* <div className='accordion-body'>
                                <strong>This is the first item's accordion body.</strong>It is hidden by
                                default, until the collapse plugin adds the appropriate classes that we use to
                                style each element. These classes control the overall appearance, as well as the
                                showing and hiding via CSS transitions. You can modify any of this with custom
                                CSS or overriding our default variables. It's also worth noting that just about
                                any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div> */}
                        </div>
                    </div>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_2'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_2'
                            >
                                Create Channel
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_2'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_2'
                            data-bs-parent='#kt_accordion_1'
                        >
                            {/* <div className='accordion-body'>
                                <strong>This is the second item's accordion body.</strong>It is hidden by
                                default, until the collapse plugin adds the appropriate classes that we use to
                                style each element. These classes control the overall appearance, as well as the
                                showing and hiding via CSS transitions. You can modify any of this with custom
                                CSS or overriding our default variables. It's also worth noting that just about
                                any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div> */}
                        </div>
                    </div>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_3'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_3'
                            >
                                Create Events
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_3'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_3'
                            data-bs-parent='#kt_accordion_1'
                        >
                            {/* <div className='accordion-body'>
                                <strong>This is the third item's accordion body.</strong>It is hidden by
                                default, until the collapse plugin adds the appropriate classes that we use to
                                style each element. These classes control the overall appearance, as well as the
                                showing and hiding via CSS transitions. You can modify any of this with custom
                                CSS or overriding our default variables. It's also worth noting that just about
                                any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Get_Started