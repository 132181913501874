// Channels.tsx
import React, { useState,useCallback,useEffect  } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {channels } from '../../app/modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../_metronic/helpers'

interface ChannelData {
  channel_id: string;
  channel_name: string;
  channel_desc: string;
  channel_image: string;
  total_members: string;
  channel_status: string;
  created_on: string;
}

export function Channels() {
  const [channelDataArray, setChannelDataArray] = useState<ChannelData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const ChannelData = useCallback(async (get_channel: string) => {

    try {
      // Perform an asynchronous API call using Axios
      const response = await channels(get_channel);
     // console.log(response);
      setChannelDataArray(response.data.channel_datas);
      setIsLoading(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    // Call fetchData with 'get_all' value to fetch initial data
    ChannelData('0');

  }, []);

  return (
    <>
       <div className="d-flex justify-content-center p-10">
                    <div className="car">
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">Coming Soon!</h3>
                    </div>
                </div>
      {/* <div className="d-flex flex-wrap flex-stack mb-6">
      {channelDataArray.length > 0 ?
      channelDataArray && channelDataArray.map((channel) => (
        <div className='card h-100 mb-5' style={{cursor:'pointer'}}>
          <div className='card-body d-flex flex-center flex-column'>
            <div className='mb-5'>

    <div style={{width: '18rem'}}>
  <img src={channel.channel_image} alt={channel.channel_name} className="card-img-top" width={'200px'}/>

    <h5 className="card-title mt-3">{channel.channel_name}</h5>
    <p className="card-text">{channel.channel_desc}</p>
    <Link to={`/channel/${channel.channel_id}`} className="btn btn-success btn-sm">View Channel</Link>

</div>
            </div>
          </div>
        </div>

)): !isLoading && (
  <p className="text-center">No Channel Found</p>
)}

{isLoading && (
          <span className="indicator-progres">
            Please Wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
  </div> */}
  </>
  );
};


