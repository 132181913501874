import React, { useState, useEffect } from 'react'; 
import { members_question,members_view_answers } from "../../../modules/auth/core/_requests" 

const MembersQuestions = () => {

    interface QuestionData {
        user_id: number
        user_name: string;
        user_company: string;
        user_country: string;
        user_state: string;
        submitted_on: string;
    }

    interface AnswerData {
        question_1: string;
        question_2: string;
        question_3: string;
        question_4: string;
        question_5: string;
        question_6: string;
        question_7: string;
        question_8: string;
        question_9: string;
        submitted_on: string;
    }

    const [questionsData, setQuestionsData] = useState<QuestionData[]>([]); 
    const [selectedUserAnswers, setSelectedUserAnswers] = useState<AnswerData[]>([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [Loadingtable, setLoadingtable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails]: any = useState({}); 
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredMembers, setFilteredMembers] = useState<QuestionData[]>([])
 

    useEffect(() => {
        const fetchQuestions = async (getall: string) => {
            try {
                setLoadingtable(true);
                const response = await members_question(getall); // Call the function with the parameter
                setLoadingtable(false);
                setQuestionsData(response.data?.members_questions);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions("0");
    }, []);

    const handleViewAnswers = async (userId: number, item_details: any) => { 
        setDetails(item_details)
        try {
            setIsLoading(true);
            const response = await members_view_answers(userId);
            setIsLoading(false);
            setSelectedUserAnswers(response.data.members_questions); // Fix the variable name here
            setShowOffcanvas(false);

        } catch (error) {
            console.error('Error fetching answers:', error);
        }
    }
    
    const itemsPerPage = 10;
    const totalItems = filteredMembers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentMembers = filteredMembers.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };
   
    useEffect(() => { 
        const filtered = questionsData.filter((question) => {
            const fullName = `${question.user_name}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, questionsData]);

    return (
        <div className="card card-custom p-5">
             <h3>Members Questions</h3>
            <div className="input-group mt-10">
                <input type="search" className="form-control w-50"
                    value={searchQuery} placeholder='Search...'
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button type="button" className="btn btn-primary" >
                    <i className="fas fa-search"></i>
                </button>
            </div>
            <div className='pt-10'>
                <table className="table table-rounded table-striped border gy-7 gs-7">
                    <thead>
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>User Name</th>
                            <th>User Company</th> 
                            <th>User State</th>
                            <th>User City</th>
                            <th>Submitted On</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {Loadingtable ? (
                        // Show loading animation using Bootstrap spinner
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <tbody>
                            {currentMembers?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.user_name}</td>
                                        {/* <td>{item?.user_company}</td> */}
                                        <td>{item.user_company !== null && item.user_company !== "" ? item.user_company : "-"}</td>
                                        <td>{item?.user_state}</td>
                                        <td>{item?.user_country}</td> 
                                        <td>{item?.submitted_on}</td>
                                        <td style={{ paddingTop: "10px" }}>
                                            <div className="dropdown">
                                                <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                     <i className="bi bi-three-dots fs-3"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => handleViewAnswers(item.user_id, item)}
                                                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                                    >
                                                        View Answers
                                                    </button>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    )}
                </table>
                {renderPagination()}
            </div>
         
            <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <h4 id="offcanvasRightLabel">Onboarding Answers</h4>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => setShowOffcanvas(false)}
                    ></button>
                </div>
                <div className='ms-10'>
                    <h6> Name:  {details.user_name}</h6>
                    <h6>User Company : {details.user_company} </h6>
                    <h6> User Country : {details.user_country}</h6>
                </div>
                <div className="offcanvas-body">
                    {isLoading ? (
                        // Show loading animation using Bootstrap spinner
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <ol>
                            {selectedUserAnswers.map((answer, index) => (
                                <div key={index}>
                                    <li className="fw-bold">Why did you join the Leaders Club?</li>
                                    <p>{answer.question_1}</p>
                                    <li className="fw-bold">What is the most important aspect of joining a business club?</li>
                                    <p>{answer.question_2}</p>
                                    <li className="fw-bold">Would you be interested in participating in a small cohort of 6-10 Leaders with in-person meetings on a regular basis?</li>
                                    <p>{answer.question_3}</p>
                                    <li className="fw-bold">Do you prefer?</li>
                                    <p>{answer.question_4}</p>
                                    <li className="fw-bold">Do you like to attend virtual events to listen to well-known Speakers?</li>
                                    <p>{answer.question_5}</p>
                                    <li className="fw-bold">What is the most important aspect of joining a business club?</li>
                                    <p>{answer.question_6}</p>
                                    <li className="fw-bold">Please answer these questions if you are interested in being matched for advisor/ mentorship opportunities with companies/ start-ups?</li>
                                    <p>{answer.question_7}</p>
                                    <li className="fw-bold">Please select your desired startup size where you are comfortable advising?</li>
                                    <p>{answer.question_8}</p>
                                    <li className="fw-bold">Would you like to provide (unpaid) mentorship to aspiring leaders?</li>
                                    <p>{answer.question_9}</p>
                                    <p>Submitted On:{answer.submitted_on}</p>
                                </div>
                            ))}
                        </ol>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MembersQuestions;

