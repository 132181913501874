import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Message = () => {
    return (
        <>
            <div className="card card-custom">
                <div className="d-flex justify-content-center p-10">
                    <div className="car">
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">Coming Soon!</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Message