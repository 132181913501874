import React, { useState, useEffect, useCallback } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ProfileDetailsCreate, profileCreateInitValues as initialValues } from '../../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import { useFormik } from 'formik'
import { ProfileCreate } from '../../../../modules/auth/core/_requests'
import { all_members } from '../../../../modules/auth/core/_requests';


const All_Members = () => {
    interface postData {
        id: number;
        first_name: string;
        last_name: string;
        company: string;
        role: string;
        status: string;
    }

    const [showModal, setShowModal] = useState(false);
    const [membersdata, setMembers] = useState<postData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredMembers, setFilteredMembers] = useState<postData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<ProfileDetailsCreate>(initialValues)
    const updateData = (fieldsToUpdate: Partial<ProfileDetailsCreate>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    // const avatar_check = userpic!=''? data.avatar : '/media/avatars/blank.png';
    const [avatar, setAvatar] = useState<string | null>(null);


    // Function to handle avatar change when a new image is selected
    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setAvatar(reader.result as string);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to remove the current avatar
    const removeAvatar = () => {
        setAvatar(null);
    };

    // Use the avatar and userpic state to determine the background image URL
    const backgroundImageUrl = avatar !== null ? avatar : toAbsoluteUrl('/media/avatars/blank.png');


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const profileDetailsSchema = Yup.object().shape({
        fName: Yup.string().required('First name is required'),
        lName: Yup.string().required('Last name is required'),
        company: Yup.string().required('Company name is required'),
        contactEmail: Yup.string().required('Contact Email is required'),
        country_zipcode: Yup.string().required('Country Zipcode is required'),
        country: Yup.string().required('Country is required'),
        country_state: Yup.string().required('State is required'),
        country_city: Yup.string().required('City is required'),
        timeZone: Yup.string().required('Time zone is required'),
    })

    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
    };


    const fetchMembersData = async (getall: any) => {
        try {
            // Perform an asynchronous API call using Axios
            const response = await all_members(getall);
            setMembers(response.data.members);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        // Call fetchMembersData with '0' value to fetch initial data
        fetchMembersData("0");
    }, []);


    const [loading, setLoading] = useState(false)
    const formik = useFormik<ProfileDetailsCreate>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { resetForm }: any) => {
            setLoading(true)
            const formData = new FormData();
            const imagefile = document.querySelector<HTMLInputElement>('#file');
            if (imagefile?.files && imagefile.files.length > 0) {
                formData.append("avatar", imagefile.files[0]);
            }
            const {data} =  await ProfileCreate(
                formData,
                values.fName,
                values.lName,
                values.company,
                values.contactEmail,
                values.contactPhone,
                values.country,
                values.country_state,
                values.country_city,
                values.country_zipcode,
                values.timeZone,
            );

            formik.setFieldValue('avatar', null); // Reset the file input
            formik.setFieldValue('communications.email', ''); // Reset email
            formik.setFieldValue('communications.phone', ''); // Reset phone

            if (data === 'User Already Exist') {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'User Already Exist',
                    text: values.contactEmail,
                    showConfirmButton: true,
                });
                setLoading(false);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Created',
                    showConfirmButton: true,
                    timer: 1500,
                });
                setLoading(false);
                handleCloseModal();
                resetForm();
                fetchMembersData('0');
            }
        },
    })

    useEffect(() => {
        // Filter membersdata based on the search query
        const filtered = membersdata.filter((member) => {
            const fullName = `${member.first_name} ${member.last_name} ${member.company} ${member.role}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, membersdata]);


    const itemsPerPage = 10;
    const totalItems = filteredMembers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentMembers = filteredMembers.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    return (
        <>
            {isLoading ? (
                // Show loading animation using Bootstrap spinner
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="card card-custom p-5 mt-5">
                    <h3>Members</h3>
                    <div className='d-flex justify-content-between align-items-center mt-10'>
                        <div className="input-group">
                            <input type="search" placeholder='Search anything...' className="form-control w-50" value={searchQuery} onChange={handleSearchChange} />
                            <button type="button" className="btn btn-primary">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={handleButtonClick}>
                                Add Member
                            </button>
                        </div>
                    </div>

                    <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
                            <div className="modal-content" >
                                <div className="modal-header">
                                    <h5 className="modal-title">Create New Member</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                                </div>
                                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                    <div className='border-top p-9'>

                                        {/* Avatar */}

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                                            <div className='col-lg-8'>
                                                <div className="image-input image-input-outline">
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={backgroundImageStyle}
                                                    ></div>
                                                    <label
                                                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="change"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click"
                                                        title="Change avatar"
                                                    >
                                                        <i className="ki-duotone ki-pencil fs-6">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        <input type="file" id="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleAvatarChange} />
                                                        {avatar && <input type="hidden" name="avatar_remove" />}
                                                    </label>
                                                    {avatar && (
                                                        <>
                                                            <span
                                                                className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="cancel"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-dismiss="click"
                                                                title="Cancel avatar"
                                                                onClick={removeAvatar}
                                                            >
                                                                <i className="ki-outline ki-cross fs-3"></i>
                                                            </span>
                                                            <span
                                                                className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="remove"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-dismiss="click"
                                                                title="Remove avatar"
                                                                onClick={removeAvatar}
                                                            >
                                                                <i className="ki-outline ki-cross fs-3"></i>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                                            <div className='col-lg-8'>
                                                <div className='row'>
                                                    <div className='col-lg-6 fv-row'>
                                                        <input
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                            placeholder='First name'
                                                            {...formik.getFieldProps('fName')}
                                                            // value={currentUser?.first_name}
                                                            onChange={formik.handleChange}
                                                        />
                                                        {formik.touched.fName && formik.errors.fName && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>{formik.errors.fName}</div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className='col-lg-6 fv-row'>
                                                        <input
                                                            type='text'
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Last name'
                                                            {...formik.getFieldProps('lName')}
                                                        // {...formik.getFieldProps('lName')}
                                                        //value={(currentUser?.last_name!=null?currentUser?.last_name:'')}
                                                        />
                                                        {formik.touched.lName && formik.errors.lName && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>{formik.errors.lName}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Company name'
                                                    {...formik.getFieldProps('company')}
                                                />
                                                {formik.touched.company && formik.errors.company && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.company}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span>Phone</span>
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='tel'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Phone number'
                                                    {...formik.getFieldProps('contactPhone')}
                                                //value={(currentUser?.phone!=null?currentUser?.phone:'')}
                                                />
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Email</span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Email Address'
                                                    {...formik.getFieldProps('contactEmail')}

                                                />
                                                {formik.touched.contactEmail && formik.errors.contactEmail && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.contactEmail}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Zipcode</span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Enter Zipcode'
                                                    {...formik.getFieldProps('country_zipcode')}
                                                />
                                                {formik.touched.country_zipcode && formik.errors.country_zipcode && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.country_zipcode}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Country</span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    className='form-select form-select-solid form-select-lg fw-bold'
                                                    {...formik.getFieldProps('country')}
                                                >
                                                    <option value=''>Select a Country...</option>
                                                    <option value='AF'>Afghanistan</option>
                                                    <option value='AX'>Aland Islands</option>
                                                    <option value='AL'>Albania</option>
                                                    <option value='DZ'>Algeria</option>
                                                    <option value='AS'>American Samoa</option>
                                                    <option value='AD'>Andorra</option>
                                                    <option value='AO'>Angola</option>
                                                    <option value='AI'>Anguilla</option>
                                                    <option value='AQ'>Antarctica</option>
                                                    <option value='AG'>Antigua and Barbuda</option>
                                                    <option value='AR'>Argentina</option>
                                                    <option value='AM'>Armenia</option>
                                                    <option value='AW'>Aruba</option>
                                                    <option value='AU'>Australia</option>
                                                    <option value='AT'>Austria</option>
                                                    <option value='AZ'>Azerbaijan</option>
                                                    <option value='BS'>Bahamas</option>
                                                    <option value='BH'>Bahrain</option>
                                                    <option value='BD'>Bangladesh</option>
                                                    <option value='BB'>Barbados</option>
                                                    <option value='BY'>Belarus</option>
                                                    <option value='BE'>Belgium</option>
                                                    <option value='BZ'>Belize</option>
                                                    <option value='BJ'>Benin</option>
                                                    <option value='BM'>Bermuda</option>
                                                    <option value='BT'>Bhutan</option>
                                                    <option value='BO'>Bolivia, Plurinational State of</option>
                                                    <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                                                    <option value='BA'>Bosnia and Herzegovina</option>
                                                    <option value='BW'>Botswana</option>
                                                    <option value='BV'>Bouvet Island</option>
                                                    <option value='BR'>Brazil</option>
                                                    <option value='IO'>British Indian Ocean Territory</option>
                                                    <option value='BN'>Brunei Darussalam</option>
                                                    <option value='BG'>Bulgaria</option>
                                                    <option value='BF'>Burkina Faso</option>
                                                    <option value='BI'>Burundi</option>
                                                    <option value='KH'>Cambodia</option>
                                                    <option value='CM'>Cameroon</option>
                                                    <option value='CA'>Canada</option>
                                                    <option value='CV'>Cape Verde</option>
                                                    <option value='KY'>Cayman Islands</option>
                                                    <option value='CF'>Central African Republic</option>
                                                    <option value='TD'>Chad</option>
                                                    <option value='CL'>Chile</option>
                                                    <option value='CN'>China</option>
                                                    <option value='CX'>Christmas Island</option>
                                                    <option value='CC'>Cocos (Keeling) Islands</option>
                                                    <option value='CO'>Colombia</option>
                                                    <option value='KM'>Comoros</option>
                                                    <option value='CG'>Congo</option>
                                                    <option value='CD'>Congo, the Democratic Republic of the</option>
                                                    <option value='CK'>Cook Islands</option>
                                                    <option value='CR'>Costa Rica</option>
                                                    <option value='CI'>Côte d'Ivoire</option>
                                                    <option value='HR'>Croatia</option>
                                                    <option value='CU'>Cuba</option>
                                                    <option value='CW'>Curaçao</option>
                                                    <option value='CY'>Cyprus</option>
                                                    <option value='CZ'>Czech Republic</option>
                                                    <option value='DK'>Denmark</option>
                                                    <option value='DJ'>Djibouti</option>
                                                    <option value='DM'>Dominica</option>
                                                    <option value='DO'>Dominican Republic</option>
                                                    <option value='EC'>Ecuador</option>
                                                    <option value='EG'>Egypt</option>
                                                    <option value='SV'>El Salvador</option>
                                                    <option value='GQ'>Equatorial Guinea</option>
                                                    <option value='ER'>Eritrea</option>
                                                    <option value='EE'>Estonia</option>
                                                    <option value='ET'>Ethiopia</option>
                                                    <option value='FK'>Falkland Islands (Malvinas)</option>
                                                    <option value='FO'>Faroe Islands</option>
                                                    <option value='FJ'>Fiji</option>
                                                    <option value='FI'>Finland</option>
                                                    <option value='FR'>France</option>
                                                    <option value='GF'>French Guiana</option>
                                                    <option value='PF'>French Polynesia</option>
                                                    <option value='TF'>French Southern Territories</option>
                                                    <option value='GA'>Gabon</option>
                                                    <option value='GM'>Gambia</option>
                                                    <option value='GE'>Georgia</option>
                                                    <option value='DE'>Germany</option>
                                                    <option value='GH'>Ghana</option>
                                                    <option value='GI'>Gibraltar</option>
                                                    <option value='GR'>Greece</option>
                                                    <option value='GL'>Greenland</option>
                                                    <option value='GD'>Grenada</option>
                                                    <option value='GP'>Guadeloupe</option>
                                                    <option value='GU'>Guam</option>
                                                    <option value='GT'>Guatemala</option>
                                                    <option value='GG'>Guernsey</option>
                                                    <option value='GN'>Guinea</option>
                                                    <option value='GW'>Guinea-Bissau</option>
                                                    <option value='GY'>Guyana</option>
                                                    <option value='HT'>Haiti</option>
                                                    <option value='HM'>Heard Island and McDonald Islands</option>
                                                    <option value='VA'>Holy See (Vatican City State)</option>
                                                    <option value='HN'>Honduras</option>
                                                    <option value='HK'>Hong Kong</option>
                                                    <option value='HU'>Hungary</option>
                                                    <option value='IS'>Iceland</option>
                                                    <option value='IN'>India</option>
                                                    <option value='ID'>Indonesia</option>
                                                    <option value='IR'>Iran, Islamic Republic of</option>
                                                    <option value='IQ'>Iraq</option>
                                                    <option value='IE'>Ireland</option>
                                                    <option value='IM'>Isle of Man</option>
                                                    <option value='IL'>Israel</option>
                                                    <option value='IT'>Italy</option>
                                                    <option value='JM'>Jamaica</option>
                                                    <option value='JP'>Japan</option>
                                                    <option value='JE'>Jersey</option>
                                                    <option value='JO'>Jordan</option>
                                                    <option value='KZ'>Kazakhstan</option>
                                                    <option value='KE'>Kenya</option>
                                                    <option value='KI'>Kiribati</option>
                                                    <option value='KP'>Korea, Democratic People's Republic of</option>
                                                    <option value='KW'>Kuwait</option>
                                                    <option value='KG'>Kyrgyzstan</option>
                                                    <option value='LA'>Lao People's Democratic Republic</option>
                                                    <option value='LV'>Latvia</option>
                                                    <option value='LB'>Lebanon</option>
                                                    <option value='LS'>Lesotho</option>
                                                    <option value='LR'>Liberia</option>
                                                    <option value='LY'>Libya</option>
                                                    <option value='LI'>Liechtenstein</option>
                                                    <option value='LT'>Lithuania</option>
                                                    <option value='LU'>Luxembourg</option>
                                                    <option value='MO'>Macao</option>
                                                    <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                                                    <option value='MG'>Madagascar</option>
                                                    <option value='MW'>Malawi</option>
                                                    <option value='MY'>Malaysia</option>
                                                    <option value='MV'>Maldives</option>
                                                    <option value='ML'>Mali</option>
                                                    <option value='MT'>Malta</option>
                                                    <option value='MH'>Marshall Islands</option>
                                                    <option value='MQ'>Martinique</option>
                                                    <option value='MR'>Mauritania</option>
                                                    <option value='MU'>Mauritius</option>
                                                    <option value='YT'>Mayotte</option>
                                                    <option value='MX'>Mexico</option>
                                                    <option value='FM'>Micronesia, Federated States of</option>
                                                    <option value='MD'>Moldova, Republic of</option>
                                                    <option value='MC'>Monaco</option>
                                                    <option value='MN'>Mongolia</option>
                                                    <option value='ME'>Montenegro</option>
                                                    <option value='MS'>Montserrat</option>
                                                    <option value='MA'>Morocco</option>
                                                    <option value='MZ'>Mozambique</option>
                                                    <option value='MM'>Myanmar</option>
                                                    <option value='NA'>Namibia</option>
                                                    <option value='NR'>Nauru</option>
                                                    <option value='NP'>Nepal</option>
                                                    <option value='NL'>Netherlands</option>
                                                    <option value='NC'>New Caledonia</option>
                                                    <option value='NZ'>New Zealand</option>
                                                    <option value='NI'>Nicaragua</option>
                                                    <option value='NE'>Niger</option>
                                                    <option value='NG'>Nigeria</option>
                                                    <option value='NU'>Niue</option>
                                                    <option value='NF'>Norfolk Island</option>
                                                    <option value='MP'>Northern Mariana Islands</option>
                                                    <option value='NO'>Norway</option>
                                                    <option value='OM'>Oman</option>
                                                    <option value='PK'>Pakistan</option>
                                                    <option value='PW'>Palau</option>
                                                    <option value='PS'>Palestinian Territory, Occupied</option>
                                                    <option value='PA'>Panama</option>
                                                    <option value='PG'>Papua New Guinea</option>
                                                    <option value='PY'>Paraguay</option>
                                                    <option value='PE'>Peru</option>
                                                    <option value='PH'>Philippines</option>
                                                    <option value='PN'>Pitcairn</option>
                                                    <option value='PL'>Poland</option>
                                                    <option value='PT'>Portugal</option>
                                                    <option value='PR'>Puerto Rico</option>
                                                    <option value='QA'>Qatar</option>
                                                    <option value='RE'>Réunion</option>
                                                    <option value='RO'>Romania</option>
                                                    <option value='RU'>Russian Federation</option>
                                                    <option value='RW'>Rwanda</option>
                                                    <option value='BL'>Saint Barthélemy</option>
                                                    <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                                                    <option value='KN'>Saint Kitts and Nevis</option>
                                                    <option value='LC'>Saint Lucia</option>
                                                    <option value='MF'>Saint Martin (French part)</option>
                                                    <option value='PM'>Saint Pierre and Miquelon</option>
                                                    <option value='VC'>Saint Vincent and the Grenadines</option>
                                                    <option value='WS'>Samoa</option>
                                                    <option value='SM'>San Marino</option>
                                                    <option value='ST'>Sao Tome and Principe</option>
                                                    <option value='SA'>Saudi Arabia</option>
                                                    <option value='SN'>Senegal</option>
                                                    <option value='RS'>Serbia</option>
                                                    <option value='SC'>Seychelles</option>
                                                    <option value='SL'>Sierra Leone</option>
                                                    <option value='SG'>Singapore</option>
                                                    <option value='SX'>Sint Maarten (Dutch part)</option>
                                                    <option value='SK'>Slovakia</option>
                                                    <option value='SI'>Slovenia</option>
                                                    <option value='SB'>Solomon Islands</option>
                                                    <option value='SO'>Somalia</option>
                                                    <option value='ZA'>South Africa</option>
                                                    <option value='GS'>South Georgia and the South Sandwich Islands</option>
                                                    <option value='KR'>South Korea</option>
                                                    <option value='SS'>South Sudan</option>
                                                    <option value='ES'>Spain</option>
                                                    <option value='LK'>Sri Lanka</option>
                                                    <option value='SD'>Sudan</option>
                                                    <option value='SR'>Suriname</option>
                                                    <option value='SJ'>Svalbard and Jan Mayen</option>
                                                    <option value='SZ'>Swaziland</option>
                                                    <option value='SE'>Sweden</option>
                                                    <option value='CH'>Switzerland</option>
                                                    <option value='SY'>Syrian Arab Republic</option>
                                                    <option value='TW'>Taiwan, Province of China</option>
                                                    <option value='TJ'>Tajikistan</option>
                                                    <option value='TZ'>Tanzania, United Republic of</option>
                                                    <option value='TH'>Thailand</option>
                                                    <option value='TL'>Timor-Leste</option>
                                                    <option value='TG'>Togo</option>
                                                    <option value='TK'>Tokelau</option>
                                                    <option value='TO'>Tonga</option>
                                                    <option value='TT'>Trinidad and Tobago</option>
                                                    <option value='TN'>Tunisia</option>
                                                    <option value='TR'>Turkey</option>
                                                    <option value='TM'>Turkmenistan</option>
                                                    <option value='TC'>Turks and Caicos Islands</option>
                                                    <option value='TV'>Tuvalu</option>
                                                    <option value='UG'>Uganda</option>
                                                    <option value='UA'>Ukraine</option>
                                                    <option value='AE'>United Arab Emirates</option>
                                                    <option value='GB'>United Kingdom</option>
                                                    <option value='US'>United States</option>
                                                    <option value='UY'>Uruguay</option>
                                                    <option value='UZ'>Uzbekistan</option>
                                                    <option value='VU'>Vanuatu</option>
                                                    <option value='VE'>Venezuela, Bolivarian Republic of</option>
                                                    <option value='VN'>Vietnam</option>
                                                    <option value='VI'>Virgin Islands</option>
                                                    <option value='WF'>Wallis and Futuna</option>
                                                    <option value='EH'>Western Sahara</option>
                                                    <option value='YE'>Yemen</option>
                                                    <option value='ZM'>Zambia</option>
                                                    <option value='ZW'>Zimbabwe</option>
                                                </select>
                                                {formik.touched.country && formik.errors.country && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.country}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>State</span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Enter State'
                                                    {...formik.getFieldProps('country_state')}
                                                />
                                                {formik.touched.country_state && formik.errors.country_state && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.country_state}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>City</span>
                                            </label>

                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Enter City'
                                                    {...formik.getFieldProps('country_city')}
                                                />
                                                {formik.touched.country_city && formik.errors.country_city && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.country_city}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Zone</label>

                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    className='form-select form-select-solid form-select-lg'
                                                    {...formik.getFieldProps('timeZone')}
                                                >
                                                    <option value=''>Select a Timezone..</option>
                                                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                    <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                    <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                    <option value="America/Managua">(GMT-06:00) Central America</option>
                                                    <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                                    <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                    <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                    <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                    <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                                    <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                    <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                    <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                                    <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                    <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                                    <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                                    <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                                    <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                    <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                    <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                                    <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                    <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                    <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                    <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                                </select>
                                                {formik.touched.timeZone && formik.errors.timeZone && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.timeZone}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                            Cancel
                                        </button>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Submit'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='pt-20'>
                        <table className="table table-rounded table-striped border gy-7 gs-7">
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Company</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMembers.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.first_name}</td>
                                        <td>{item.last_name}</td>
                                        <td>{item.company !== null && item.company !== "" ? item.company : "-"}</td>
                                        <td>{item.role !== null && item.role !== "" ? item.role : "-"}</td>
                                        <td>
                                            {item.status === "0" ? (
                                                <span className="badge bg-secondary">Inactive</span>
                                            ) : (
                                                <span className="badge bg-primary">Active</span>
                                            )}
                                        </td>
                                        <td style={{ paddingTop: "10px" }}>
                                            <div className="dropdown">
                                                <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-3"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li><button className="dropdown-item" type="button">Block</button></li>
                                                    <li><button className="dropdown-item" type="button">Deactivate</button></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination */}
                    {renderPagination()}
                </div>
            )}
        </>
    );
};

export default All_Members;
