import React from 'react'

const InvitePage = () => {
    return (
        <>
            <div className="card card-custom mt-5">
                <div className="card-header">
                    <h3 className="card-title">Customize invite page</h3>
                </div>
                <div className="card-body">
                    <form>
                        <div className="image-input image-input-empty onboarding_form w-100" data-kt-image-input="true">
                            <div className="image-input-wrapper"></div>
                            <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                                title="Change avatar">
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                <input type="hidden" name="avatar_remove" />
                            </label>
                            <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                                title="Cancel avatar">
                                <i className="bi bi-x fs-2"></i>
                            </span>

                            <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                                title="Remove avatar">
                                <i className="bi bi-x fs-2"></i>
                            </span>
                        </div>
                        <label className="required form-label mt-3">Community tagline</label>
                        <textarea className="form-control w-50 mb-5" ></textarea>
                        <h1>Video</h1>
                        <div className='d-flex'>
                            <div className="mb-5">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" value="" id="Video_URL" />
                                    <label className="form-check-label" htmlFor="Video_URL">
                                        Video URL
                                    </label>
                                </div>
                            </div>
                            <div className="mb-5 ps-10">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" value="" id="Upload_from" />
                                    <label className="form-check-label" htmlFor="Upload_from">
                                        Upload from the computer
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label className="form-label mt-3">Video url</label>
                        <input
                            type="text"
                            className="form-control w-50"
                            placeholder="Add youtube or URL"
                        />
                        <h1 className='mt-5'>Plans</h1>
                        <label className="form-label mt-3">Select Plan</label>
                        <select className="form-select w-50" aria-label="Select example">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <h1 className='mt-5'>FAQ</h1>
                        <label className="form-label mt-3">Question</label>
                        <input
                            type="text"
                            className="form-control w-50"
                        />
                        <label className="required form-label mt-3">Answer</label>
                        <textarea className="form-control w-50 mb-5" ></textarea>
                        <button className="btn btn-primary">Save FAQ</button>
                        <div className='d-flex mt-10 float-end'>
                            <div>
                                <button type="button" className="btn btn-primary">
                                    <i className="bi bi-eye"></i> Preview
                                </button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary ms-10 ps-35 pe-35"> Save </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default InvitePage