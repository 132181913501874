// Channels.tsx
import React, { useState,useCallback,useEffect  } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {articles} from '../../app/modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { ArticleCard } from "../../_metronic/partials/content/cards/ArticleCard";

interface ArticleData {
  article_id: string;
  article_title: string;
  article_description: string;
  article_created_on: string;
  article_created_by: string;
  article_status: string;
  article_cover_img: string;
}

export function Articles() {
  const [articleDataArray, setArticleDataArray] = useState<ArticleData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const ArticleData = useCallback(async (get_articles: string) => {

    try {
      // Perform an asynchronous API call using Axios
      const response = await articles(get_articles);
      // console.log(response);
     setArticleDataArray(response.data.all_articles);
      setIsLoading(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    // Call fetchData with 'get_all' value to fetch initial data
    ArticleData('0');

  }, []);

  return (
    <>
       {/* <div className="d-flex justify-content-center p-10">
                    <div className="car">
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">Coming Soon!</h3>
                    </div>
                </div> */}



<div className="row mb-6">
      {articleDataArray.length > 0 ?
      articleDataArray && articleDataArray.map((article,index) => (

<ArticleCard
cover_img= {article.article_cover_img}
title={article.article_title}
desc={article.article_description}
posted_on={article.article_created_on}
posted_by={article.article_created_by}
status={article.article_status}
article_id={article.article_id}
/>

)): !isLoading && (
  <p className="text-center">No Articles Found</p>
)}

{isLoading && (
          <span className="indicator-progres">
            Please Wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
  </div>
  </>
  );
};


