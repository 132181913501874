import React, { useState } from 'react';
import AdminPanelTab from './AdminPanel/Leftsidebar/AdminPanelTab';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import Setup from './AdminPanel/Setup/Setup';
import Channel from './AdminPanel/Channel/Channel';
import SettingsMembers from './AdminPanel/Members/Members';
import Analytics from './AdminPanel/Analytics/Analytics';
import Message from './AdminPanel/Message/Message';
import Get_Started from './AdminPanel/Get_Started/Get_Started';
import Onboarding from './AdminPanel/Onboarding/Onboarding';
import Events from './AdminPanel/Events/Events';
import Upcoming_Events from './AdminPanel/Events/Pages/Upcoming_Events';
import PastEvents from './AdminPanel/Events/Pages/PastEvents';
import InvitePage from './AdminPanel/Onboarding/Pages/InvitePage';
import Membership from './AdminPanel/Onboarding/Pages/Membership';
import Engagement from './AdminPanel/Analytics/AnalyticsPages/Engagement';
import Subscription from './AdminPanel/Analytics/AnalyticsPages/Subscription';
import All_Members from './AdminPanel/Members/MembersPage/All_Members';
import Invite_link from './AdminPanel/Members/MembersPage/Invite_link';
import Pending_invition from './AdminPanel/Members/MembersPage/Pending_invition';
import Members_Questions from './AdminPanel/Members_Questions/Members_Questions';
import { useAuth } from '../modules/auth'
import Horizontal from './AdminPanel/Events/Pages/Horizontal';
import Articles from './AdminPanel/Article/Articles';
import Category from './AdminPanel/Category/Category';
import Tags from './AdminPanel/Tags/Tags';
import ArticlesPages from './AdminPanel/Article/ArticlesPages';

const AdminPanel = () => {
    const { currentUser, logout } = useAuth()
    const [userrole, setUserrole] = useState(currentUser?.admin || '')

    return (
        <div className="row">
            <div className="col-md-2">
                {userrole === "Admin" ?
                    <AdminPanelTab />
                    : ""
                }
            </div>
            <div className="col-md-10">
                {userrole === "Admin" ?
                    <Routes>
                        <Route
                            path='settings-setup'
                            element={
                                <>
                                    <Setup />
                                </>
                            }
                        />
                        <Route
                            path='settingss-members_questions'
                            element={
                                <>
                                    <Members_Questions />
                                </>
                            }
                        />
                        <Route
                            path='settings-channel'
                            element={
                                <>
                                    <Channel />
                                </>
                            }
                        />
                        <Route
                            path='settings-members'
                            element={
                                <>
                                    <SettingsMembers />
                                    <Outlet />
                                </>
                            }
                        >
                            <Route
                                path='all-members'
                                element={
                                    <>
                                        <All_Members />
                                    </>
                                }
                            />
                            <Route
                                path='invite-link'
                                element={
                                    <>
                                        <Invite_link />
                                    </>
                                }
                            />
                            <Route
                                path='pending-invition'
                                element={
                                    <>
                                        <Pending_invition />
                                    </>
                                }
                            />
                            <Route index element={<Navigate to='/admin-panel/settings-members/all-members' />} />
                        </Route>
                        <Route
                            path='settings-analytics/*'
                            element={
                                <>
                                    <Analytics />
                                    <Outlet />
                                </>
                            }
                        >
                            {/* <Route
                            path='engagement'
                            element={
                                <>
                                    <Engagement />
                                </>
                            }
                        />
                        <Route
                            path='subscription'
                            element={
                                <>
                                    <Subscription />
                                </>
                            }
                        />
                        <Route index element={<Navigate to='/admin-panel/settings-analytics/engagement' />} /> */}
                        </Route>
                        <Route
                            path='settings-message'
                            element={
                                <>
                                    <Message />
                                </>
                            }
                        />
                        <Route
                            path='settings-get-started'
                            element={
                                <>
                                    <Get_Started />

                                </>
                            }
                        />
                        <Route
                            path='settings-onboarding'
                            element={
                                <>
                                    <Onboarding />
                                    <Outlet />
                                </>
                            }
                        >
                            <Route
                                path='invite_page'
                                element={
                                    <>
                                        <InvitePage />
                                    </>
                                }
                            />
                            <Route
                                path='membership-questions'
                                element={
                                    <>
                                        <Membership />
                                    </>
                                }
                            />
                            <Route index element={<Navigate to='/admin-panel/settings-onboarding/invite_page' />} />
                        </Route>
                        <Route
                            path='settings-events'
                            element={
                                <>
                                    <Events />
                                    <Outlet />
                                </>
                            }
                        >
                            <Route
                                path='upcoming_events'
                                element={
                                    <>
                                        <Upcoming_Events />

                                    </>
                                }
                            />
                            <Route
                                path='past_events'
                                element={
                                    <>
                                        <PastEvents />
                                    </>
                                }
                            />
                            <Route
                                path='create-events'
                                element={
                                    <>
                                        <Horizontal />
                                    </>
                                }
                            />
                            <Route index element={<Navigate to='/admin-panel/settings-events/upcoming_events' />} />
                        </Route>
                        <Route
                            path='settings-article'
                            element={
                                <>
                                    <ArticlesPages />
                                    <Outlet />
                                </>
                            }
                        >
                            <Route
                                path='create-articles'
                                element={
                                    <>
                                        <Articles />
                                    </>
                                }
                            />
                            <Route
                                path='category'
                                element={
                                    <>
                                        <Category />
                                    </>
                                }
                            />
                            <Route
                                path='tags'
                                element={
                                    <>
                                        <Tags />
                                    </>
                                }
                            />
                            <Route index element={<Navigate to='/admin-panel/settings-article/create-articles' />} />
                        </Route>
                        <Route index element={<Navigate to='/admin-panel/settings-setup' />} />
                    </Routes>
                    : ""
                }
            </div>
        </div >
    );
};

export default AdminPanel;
