import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
 


const Events = () => {
    const location = useLocation()

    return (
        <>
            <div className="card card-custom"> 
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 members_tab"> 
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-events/upcoming_events' className={"nav-link " + (location.pathname === '/admin-panel/settings-events/upcoming_events' && 'active')}>
                            Upcoming Events
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-events/past_events' className={"nav-link " + (location.pathname === '/admin-panel/settings-events/past_events' && 'active')}>
                            Past Events
                        </Link>
                    </li>
                </ul>
            </div> 
        </>
    )
}

export default Events