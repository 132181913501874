import React,{useState,useCallback,useEffect} from "react";
import { useParams } from 'react-router-dom';
import { getArticleDetails,confirmEvent } from '../../app/modules/auth/core/_requests'
import {useAuth} from '../modules/auth'
import { number } from "yup";

interface ArticleDatas {
  article_category: string;
  article_cover_img: string;
  article_created_by: string;
  article_created_on: string;
  article_description: string;
  article_id: string;
  article_status: string;
  article_title:string;
}


const formatDate = (dateString: string) => {
  if (!dateString) {
      return '';
  }
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  return `${month}`;
};


export function ArticleDetails() {
  const [Articles, setArticles] = useState<ArticleDatas[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const { article_details_id } = useParams<{ article_details_id: string }>();
  const {currentUser, logout} = useAuth()
  const [userid, setUserid] = useState(currentUser?.id || 0)

  const fetchEventData = async (article_details_id: string) => {

    try {
      // Perform an asynchronous API call using Axios
      const response = await getArticleDetails(article_details_id);
      setArticles(response.data.artical_details);
      setIsLoading(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
      setArticles([]);
    }
  }

  useEffect(() => {


    fetchEventData(article_details_id ?? '');


  }, []);


    return (
        <>
 {loading ? (
        <div
        className="spinner-border text-success"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      Articles.length > 0 ? (
        Articles.map((article, index) => (
                <div key={index}>
                   {article.article_status=='1'?(
<div className='row gy-5 g-xl-8'>

      <div className='col-xl-8 offset-md-2'>
      <div className='card p-8'>

        <img src={article.article_cover_img} className='w-100 border border-1 bg-light rounded'
                style={{
                  objectFit: "cover"
                }} alt='' />

        <h3 className='mt-10'>{article.article_title}</h3>
             <div className='d-flex align-items-sm-top mb-5'>
                 <div className='symbol symbol-45px me-5 mt-3'>
                      <i className="las la-calendar-alt fs-2x"></i>
                  </div>
                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                          <div className='flex-grow-1 me-2 mt-3'>
                            <p>{article.article_created_on} | Posted By - {article.article_created_by}</p>
                            </div>
                    </div>
               </div>
        <div className="article_para" dangerouslySetInnerHTML={{ __html: article.article_description }} />

        </div>
          {/* <div className='card p-5 mt-10'>
          <div className='d-flex align-items-sm-top mb-5 mt-2 '>

          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} className='' alt='' />
          </div>

          <div className='d-flex align-items-top flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2 '>
                <textarea className='form-control form-control-sm form-control-solid p-3' style={{height:'100px'}}placeholder='Share your Thoughts'></textarea>
                <button className='btn btn-success w-30 mt-3'>Post</button>
            </div>

          </div>


        </div>
          </div> */}
        {/* <FeedsWidget3 className='mb-5 mb-xl-8 mt-5' /> */}
      </div>

    </div>
                   ) : (   <h4><i className="bi bi-emoji-frown-fill fs-3 pe-2"></i>This Article is currently removed</h4>) }
</div>
  ))
  ) : !loading && (
    <p>No result found</p>
  )
)}
        </>
    )
}

