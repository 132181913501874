import React, { useState, useEffect,useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { KTSVG, KTIcon, toAbsoluteUrlImage } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { Formik, Form, Field, ErrorMessage,FormikHelpers  } from 'formik';
import { FeedPost,getFeed,channel,ChannelJoin } from '../../app/modules/auth/core/_requests'
import { string } from 'yup';

interface Image {
  file: File;
  preview: string;
}

interface FormValues {
  post_content: string;
}

// Validation function
const validateForm = (values: FormValues) => {
  const errors: Partial<FormValues> = {};

  if (!values.post_content) {
    errors.post_content = "Post content is required";
  }

  return errors;
};

interface ChannelData {
  channel_id: string;
  channel_name: string;
  channel_image: string;
  total_members: string;
  channel_status: string;
  channel_desc: string;
  created_on: string;
  user_status:string;
}

export function ChannelIndividual () {

  const { channel_id } = useParams<{ channel_id: string }>();
  const [channelDetails, setChannelDetails] = useState<ChannelData[]>([]);
  const { currentUser, logout } = useAuth()
  const [userpic, setUserPic] = useState(currentUser?.pic || '')
  const [images, setImages] = useState<Image[]>([]);
  const [username, setUsername] = useState(currentUser?.first_name+' '+currentUser?.last_name || '')
  const [userid, setUserId] = useState(currentUser?.id as number) || 0
  const [showModal, setShowModal] = useState(false);
  const [showClose, setShowModalClose] = useState({ display: 'none' });
  const [userfirst, setUserfirst] = useState(currentUser?.first_name || '')
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
  const [channel_current, setChannelCurrent] = useState<string | null>(channel_id || null);
  const [channelDataArray, setChannelDataArray] = useState<ChannelData[]>([]);
  const [feeds, setFeeds] = useState<Feed[]>([]);

   const fetchData = useCallback(async (get_all: string) => {
    setIsLoading(true);
    try {
      // Perform an asynchronous API call using Axios
      const response = await getFeed(get_all);
      setFeeds(response.data);
      setIsLoading(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
      setFeeds([]);
    }
  }, []);

  useEffect(() => {
    // Call fetchData with 'get_all' value to fetch initial data
    fetchData(channel_id ?? '');


  }, []);




  //console.log(username);
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files) as File[];
      const selectedImages = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setImages((prevImages) => [...prevImages, ...selectedImages]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    if (submitting) {
      // Form is already being submitted, do nothing
      return;
    }
    setSubmitting(true);
  //console.log(values);
  const formData = new FormData();


  images.forEach((image) => {
    formData.append('feed_images[]', image.file);
  });

  const {data} = await FeedPost(
    formData,
    values.post_content,
    username,
    userid,
    channel_id || ''
      );



  // Send the images to the server (you need to implement the API call)

  // Clear the file input after form submission
  const fileInput = document.getElementById('fileInput') as HTMLInputElement;
  if (fileInput) {
    fileInput.value = '';
  }

  // Set submitting to false when form submission is done
  setSubmitting(false);

  fetchData(channel_id ?? '');
  resetForm();
  setImages([]);
  setIsSuccessAlertVisible(true);
  setTimeout(() => {

    setShowModal(false);
    setShowModalClose({ display: showClose.display === 'block' ? 'none' : 'block' });

  }, 2000);

}, [submitting,images, username, userid,channel_id]);

interface FeedMedia {
  feed_media_url: string;
}

interface Feed {
  feed_id: string;
  feed_data: string;
  posted_by : string;
  posted_pic : string;
  posted_on : string;
  like_count : string;
  comment_count : string;
  feed_media: FeedMedia[];
}



const ChannelData = async (get_channel: string,user_id:number): Promise<any> => {
  try {
    // Perform an asynchronous API call using Axios
    const response = await channel(get_channel,user_id);
    //console.log(response); // Check the response in the console
    setChannelDetails(response.data.channel_datas);
  } catch (error) {
    // Handle errors if the API call fails
   // console.error('Error fetching user data:', error);
  }
}


useEffect(() => {
  ChannelData(channel_id ?? '',Number(currentUser?.id))
    .then(response => {
     // console.log(response);
      setChannelDetails(response.data.channel_datas);
    })
    .catch(error => {
      // Handle the error here or show an error message to the user
    });
}, []);

const NewJoin = async (user_id: number, channel_id: number): Promise<any> => {
  try {
    // Perform an asynchronous API call using Axios
    const response = await ChannelJoin(user_id, channel_id);
    //console.log(response); // Check the response in the console
    ChannelData(channel_id?.toString() ?? '', currentUser?.id ?? 0);
  } catch (error) {
    // Handle errors if the API call fails
    console.error('Error fetching user data:', error);
  }
};

const handleJoinNowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  const user_id = event.currentTarget.value; // Get the value (post ID) from the button
  NewJoin(Number(user_id), Number(channel_id));
};

const ModalOpen = () => {
  setShowModal(true)
  setShowModalClose({ display: showClose.display === 'none' ? 'block' : 'none' });

};
  return (
    <>

<div className={`modal fade ${showModal ? 'show' : ''}`} data-bs-backdrop="static" tabIndex={-1} id="lc_post_popup" style={showClose}  aria-labelledby="lc_post_popup">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title text-center">Create Post</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <Formik
    initialValues={{
      post_content: "",
    }}
    validate={validateForm}
    onSubmit={handleSubmit}
  >

      <Form>
        <div className="modal-body">

        <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-top flex-grow-1'>
                <div className='symbol symbol-50px symbol-circle symbol-lg-50px me-2'>
                  {(userpic !== '') ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
                </div>
                <div className="d-flex flex-grow-1">
                <Field
              as="textarea"
              name="post_content"
              className="form-control border-1 h-150px w-100 mb-2"
              placeholder={`Hi ${userfirst} ! What's on your mind today?`}
              style={{ resize: "both" }}
            />

                </div>

                {/* end::Info */}
              </div>
              {/* end::User */}
            </div>
            <ErrorMessage
              name="post_content"
              component="div"
              className="text-danger"
            />
            <div className="image-preview">
              {images.map((image, index) => (
                <div key={index} className="preview-item">
                  <img src={image.preview} alt={`Preview ${index}`} />
                  <button onClick={() => handleRemoveImage(index)}><span className="post_img_close">x</span></button>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end mb-5 w-100">
              <div>
                <div className="full-width-container d-flex align-items-center border rounded p-2">
                  <span className="me-20 ms-5">Add to your post</span>
                  <label className="file-attachment">
                    <i className="bi bi-images fs-2"></i>
                    <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg"/>
                  </label>
                </div>
              </div>
            </div>
        </div>

        <div className="modal-footer">
 {/* Alert for Success */}
 {isSuccessAlertVisible && (
      <div className="alert alert-success d-flex align-items-center p-5 mb-10">
        <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
          {/* Add your SVG icon here */}
        </span>
        <div className="d-flex flex-column">
          <h5 className="mb-1">Successfully Posted</h5>
        </div>
      </div>
    )}
          <div className="d-grid gap-2 col-12 mx-auto">
            <button
              className="btn btn-success"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Posting..." : "Post"}
            </button>
          </div>
        </div>
      </Form>

  </Formik>
          </div>
        </div>
      </div>

    <div className='row g-5 g-xl-8'>
      <div className='col-xl-8 ps-xl-20'>
        {/* begin::Body */}

        <div className="card mb-5 mb-xl-8">
          <div className='card-body pb-0 d-flex flex-column'>
            {/* begin::Header */}
            <div className='d-flex align-items-center mb-5'>
              {/* begin::User */}
              <div className='d-flex align-items-top flex-grow-1'>
                {/* begin::Avatar */}
                <div className='symbol symbol-50px symbol-circle symbol-lg-50px me-2'>
                  {(userpic !== '') ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
                </div>
                {/* end::Avatar */}

                {/* begin::Info */}
                <div className="d-flex flex-grow-1">
                  <textarea className="form-control h-100px w-100 mb-2"
placeholder={`Hi ${userfirst} !  What's on your mind today?`}
 data-bs-toggle="modal"
data-bs-target="#lc_post_popup"
                   ></textarea>
                </div>
                {/* end::Info */}
              </div>
              {/* end::User */}
            </div>
            {/* end::Header */}

            {/* Separate container for the "Post" button */}

            {/* <div className="d-flex justify-content-end mb-5 ">
<div className="me-10">
<label className="file-attachment">
<i className="bi bi-images"></i>
    <input type="file" multiple onChange={handleImageChange} />
  </label>
    <input type="file" name="file[]" multiple onChange={handleImageChange} />

  </div>
</div> */}
          </div>


        </div>

        {feeds.length > 0 ?
  feeds.map((feed) => (
      <div key={feed.feed_id}>
  <div className={`card mb-3`}>
    {/* begin::Body */}
    <div className='card-body pb-0'>
      {/* begin::Header */}
      <div className='d-flex align-items-center mb-3'>
        {/* begin::User */}
        <div className='d-flex align-items-center flex-grow-1'>
          {/* begin::Avatar */}
          <div className='symbol symbol-45px me-5 symbol-circle'>
            <img src={feed.posted_pic} alt='' />
          </div>
          {/* end::Avatar */}

          {/* begin::Info */}
          <div className='d-flex flex-column'>
            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
          {feed.posted_by}
            </span>
            <small className='text-gray-400 fw-semibold'>Posted On: {feed.posted_on}</small>
          </div>
          {/* end::Info */}
        </div>
        {/* end::User */}

        {/* begin::Menu */}
        <div className='my-0'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-grey btn-active-light-success'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
<span className="svg-icon svg-icon-muted svg-icon-1x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor"/>
<rect x="10" y="3" width="4" height="4" rx="2" fill="currentColor"/>
<rect x="10" y="17" width="4" height="4" rx="2" fill="currentColor"/>
</svg>
</span>
        </button>

        </div>
        {/* end::Menu */}
      </div>
      {/* end::Header */}

      {/* begin::Post */}
      <div className='mb-7'>
        {/* begin::Text */}
        <div className='text-gray-800 mb-5'>
          <div className="post_title">
          <p style={{ whiteSpace: 'pre-wrap' }}>{feed.feed_data}</p>
            </div>
            <div className="post_img">
            <div className="masonry-container">
{feed.feed_media.map((media, index) => (
  <div key={index} className={`masonry-item ${index === 0 ? 'full-width' : ''}`}>
    <img src={media.feed_media_url}  alt={`Media ${index + 1}`} />
  </div>
))}
</div>
</div>
        </div>
        {/* end::Text */}

        {/* begin::Toolbar */}
        {/* <div className='d-flex align-items-center mb-5'>

          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 me-4'
          >
            <KTIcon iconName='like' className='fs-2' />
            {feed.comment_count}
          </a>
          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 '
          >
            <KTIcon iconName='message-text-2' className='fs-2' />
            {feed.like_count}
          </a>

        </div> */}
        {/* end::Toolbar */}
      </div>
      {/* end::Post */}
      {/* <div className='separator mb-4'></div> */}


      {/* begin::Reply input */}
      {/* <form className='position-relative mb-6'>
        <textarea
          className='form-control border-0 p-0 pe-10'
          data-kt-autosize='true'
          rows={1}
          placeholder='Reply..'
        ></textarea>

        <div className='position-absolute top-0 end-0 me-n5'>
          <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
            <KTIcon iconName='message-text' className='fs-3 mb-3' />


          </span>

        </div>
      </form> */}
      {/* edit::Reply input */}
    </div>
    {/* end::Body */}
  </div>
      </div>
)): !isLoading && (
<p className="text-center">No Post Found</p>
)}

{isLoading && (
        <span className="indicator-progres">
          Please Wait...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
</div>




<div className='col-xl-3 col-md-4 sticky-column'>

<div className={`card mb-5 mb-xl-8`}>
          <div className="card-body p-7">

  {channelDetails.map((channel) => (
  <div key={channel.channel_id}>
    <img src={channel.channel_image} className='w-100 h-200px rounded' style={{ objectFit: "cover" }} alt='' />
    <h4 className="mt-3">{channel.channel_name}</h4>
    <p>{channel.channel_desc}</p>
    {channel.user_status !== 'Subscribed' ? (
      <button className='btn btn-success btn-sm fw-bold my-2'  onClick={handleJoinNowClick} value={currentUser?.id}>Join Now</button>
    ) : (
      <span className='badge badge-light fw-bold my-2'>Subscribed</span>
    )}
  </div>
  ))}






{/* {otherChannels.map((channel) => (

<div className='d-flex align-items-center mb-5 flex-row-fluid flex-wrap'>

<div className='symbol symbol-40px me-5'>
<img src={channel.channel_image}
              style={{
                objectFit: "cover"
              }} alt='' />
</div>
<div className='flex-grow-1 me-2'>

<Link to={`/channel/${channel.channel_id}`} className="text-gray-800 text-hover-primary fs-6 fw-bold">{channel.channel_name}</Link>

</div>

{channel.total_members === '0' ? (
              <span className='badge badge-success fw-bold my-2'>Join Now</span>
            ) : (
              <span className='badge badge-light fw-bold my-2'>{channel.total_members} Members</span>
            )}


         </div>
      ))} */}


            </div>
            </div>
        </div>


    </div>


  </>
  );
};

