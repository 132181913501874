import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function Privacypolicy() {
  return (
    <>

      <div className="container pt-10 pb-20">
      <Link to='/' className='link-primary'>


      <span className='mb-12 d-flex justify-content-center'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/lc_logo_app.png')} className='h-55px' />
        </span>
        </Link>
        <div className='row card px-10'>

              <h1 className="text-center mt-20 mb-10 "> Privacy Policy (Member Only)</h1>
          <p className="fs-4">
            All Members of the Leaders Club, collectively referred to as the 'Members,' hereby acknowledge and affirm their commitment to act in the best interest of the Leaders Club. In their capacity as Members, they shall diligently and responsibly perform their duties, promoting the overall well-being, growth, and success of the Leaders Club.
          </p>
          <p className="fs-4">
            Each Member shall prioritize the leaders club interests above their personal or individual interests. They shall exercise their roles and responsibilities with the utmost care, integrity, and professionalism while demonstrating a high level of ethical conduct. Members shall avoid any conflicts of interest that may compromise the Leaders Club's objectives or undermine its reputation.
          </p>
          <p className="fs-4">
            Furthermore, Members shall engage in open and transparent communication, fostering an environment of collaboration, trust, and mutual respect. They shall actively contribute to the Leaders Club's initiatives, sharing knowledge, insights, and experiences for the benefit of all stakeholders involved.
          </p>
          <p className="fs-4">
            Members shall comply with all applicable laws, regulations, and guidelines, ensuring full legal compliance in all their actions and decisions related to the Leaders Club. They shall refrain from engaging in any activities that may cause harm or damage to the Leaders Club or its members.
            In the event of disputes or disagreements, Members shall strive to resolve them amicably, utilizing appropriate conflict resolution mechanisms available within the Leaders Club. They shall prioritize the preservation of harmonious relationships, seeking consensus and common ground whenever possible.
          </p>
          <p className="fs-4">
            This commitment to act in the best interest of the Leaders Club shall endure throughout the duration of each Member's participation in the Leaders Club. Members acknowledge that their actions and conduct reflect upon the Leaders Club as a whole, and they shall conduct themselves in a manner that upholds the Leaders Club's values, reputation, and objectives.
            By accepting membership in the Leaders Club, each Member affirms their understanding of this commitment and agrees to abide by its principles. This commitment shall serve as a guiding principle for all Members, promoting a unified and purposeful approach toward achieving the Leaders Club's goals."
          </p>
          <p className="fs-4">
            Please note that this response is provided for informational purposes only and should not be considered as legal advice. For specific legal matters related to your situation, it is recommended to consult with a qualified attorney.
          </p>
          <p className="fs-4">
            Participants are free to use the information they receive, but they are prohibited from revealing the identity or affiliation of any speaker or participant, nor can they attribute any statements to a particular individual. This allows participants to speak more freely and openly without fear of reprisal or retaliation.The rule helps to ensure that sensitive or confidential information is not leaked to the public, while also allowing participants to benefit from the insights and expertise of others in the group.

          </p>

          <div className="mt-5 mb-20">
            <h1 className='mb-5'>Leaders Club Confidential Agreement</h1>
            <ul className="fs-4">
              <li>You will not share your company's confidential information.</li>
              <li>You will not discuss the exact context of our meetups outside the Leaders Club audience.</li>
              <li>You can refer to any company's CXOs name with their approvals.</li>
              <li>You will not take any pictures or screenshots of virtual meetups.</li>
              <li>You will not share the context of any meeting on any social media.</li>
              <li>You will not invite people without prior approval.</li>
              <li>Any promotional material content is prohibited</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
