import React from 'react'
import { Link } from 'react-router-dom'
import {useLocation} from 'react-router'


const SettingsMembers = () => {
    const location = useLocation()

    return (
        <> 
                <div className="card card-custom"> 
                    <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 members_tab">
                        <li className="nav-item">
                            <Link to='/admin-panel/settings-members/all-members' className={"nav-link " + (location.pathname === '/admin-panel/settings-members/all-members' && 'active')}>
                                All Members
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/admin-panel/settings-members/pending-invition' className={"nav-link " + (location.pathname === '/admin-panel/settings-members/pending-invition' && 'active')}>
                                Pending Invitations
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/admin-panel/settings-members/invite-link' className={"nav-link " + (location.pathname === '/admin-panel/settings-members/invite-link' && 'active')}>
                                Invite links
                            </Link>
                        </li> */}
                    </ul>
                </div>
            
        </>
    )
}

export default SettingsMembers