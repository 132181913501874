import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const EventDetails = (props: any) => {

    const formik = useFormikContext();

    // const [selectedLocation, setSelectedLocation] = useState(''); 
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [editorContent, setEditorContent] = useState('');


    // const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSelectedLocation(event.target.value);
    // };

    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const selectedFile = event.target.files?.[0];
    //     setSelectedFile(selectedFile || null);
    //     if (selectedFile) {
    //         setFileName(selectedFile.name);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreview(reader.result as string);
    //         };
    //         reader.readAsDataURL(selectedFile);
    //     } else {
    //         setImagePreview(null);
    //     }
    // };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
            setSelectedFileName(file.name);
        } 
    };

    var toolbaroptions = [[{ 'header': 1 }, { 'header': 2 }], ['bold', 'italic'], ['link', 'image'], ['underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]]

    const module = {
        toolbar: toolbaroptions, 
    } 

    const imageform = (param: any) => {
        if (param) {
            setEditorContent('');
            setImagePreview(null);
            setSelectedFileName('')
        }
    }

    useEffect(() => {
        imageform(props.resetform)
    }, [props.resetform])

    return (
        <div>
            <div className="form-group">
                <label htmlFor="event_name" className='form-label mb-3'>Event Name</label>
                <Field type="text" name="event_name" className="form-control form-control-lg" />
                <ErrorMessage name="event_name" component="div" className="text-danger" />
            </div>
            {/* <div className="form-group">
                <label htmlFor="event_time_zone" className='form-label mb-3 mt-3'>Event Time Zone</label>
                <Field type="text" name="event_time_zone" className="form-control form-control-lg" />
                <ErrorMessage name="event_time_zone" component="div" className="text-danger" />
            </div> */}
            <div className="form-group">
                <label className="form-label mt-3 mb-3" htmlFor="event_time_zone">Event Time Zone</label>
                <Field
                    as="select"
                    name="event_time_zone"
                    aria-label="Select example"
                    className="form-select"
                >
                    <option value=''>Select a Timezone..</option>
                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                    <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                    <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                    <option value="America/Managua">(GMT-06:00) Central America</option>
                    <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                    <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                    <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                    <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                    <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                    <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                    <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                    <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                    <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                    <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                    <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                    <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                    <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                    <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                    <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                    <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                    <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                    <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                    <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                    <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                </Field>
                <ErrorMessage name="event_time_zone" component="div" className="text-danger" />
            </div>
            <div className="form-group">
                {/* <label htmlFor="event_pricing" className='form-label mb-3 mt-3'>Event Pricing</label> */}
                <Field type="hidden" name="event_pricing" className="form-control form-control-lg" />
                <ErrorMessage name="event_pricing" component="div" className="text-danger" />
            </div>
            <label htmlFor="start_date_time" className="form-label mt-3 mb-3">Start Date & Time</label>
            <div className='d-flex'>
                <div className="form-group">
                    <Field type="date" name="event_start_date" className="form-control" />
                    <ErrorMessage name="event_start_date" component="div" className="text-danger" />
                </div>
                <div className="form-group">
                    <Field type="time" name="event_start_time" className="form-control ms-5" />
                    <ErrorMessage name="event_start_time" component="div" className="text-danger" />
                </div>
            </div>

            <label htmlFor="end_date_time" className="form-label mt-3 mb-3">End Date & Time</label>
            <div className='d-flex'>
                <div className="form-group">
                    <Field type="date" name="event_end_date" className="form-control" />
                    <ErrorMessage name="event_end_date" component="div" className="text-danger" />
                </div>
                <div className="form-group">
                    <Field type="time" name="event_end_time" className="form-control ms-5" />
                    <ErrorMessage name="event_end_time" component="div" className="text-danger" />
                </div>
            </div>
            {/* <div>
                <label htmlFor="event_location" className="form-label mt-3 mb-3">Location</label>
                <div className="form-check form-check-custom form-check-solid d-flex">
                    <div>
                        <Field
                            className="form-check-input"
                            type="radio"
                            name="event_location"
                            id="Online"
                            value="Online"
                        // checked={selectedLocation === 'Online'}
                        // onChange={handleLocationChange}
                        />
                        <label className="form-check-label ps-2" htmlFor="Online">
                            Online
                        </label>
                    </div>
                    <div>
                        <Field
                            className="form-check-input ms-2"
                            type="radio"
                            name="event_location"
                            id="In-Person"
                            value="In-Person"
                        // checked={selectedLocation === 'In-Person'}
                        // onChange={handleLocationChange}
                        />
                        <label className="form-check-label ps-2" htmlFor="In-Person">
                            In-Person
                        </label>
                    </div>
                    <ErrorMessage name="event_location" component="div" className="text-danger" />
                </div>

                {selectedLocation === 'Online' && (
                    <div>
                        <label className="form-label mt-3">Online event URL</label>
                        <Field
                            type="text"
                            name="onlineEventUrl"
                            className="form-control"
                        />
                        <ErrorMessage name="onlineEventUrl" component="div" className="text-danger" />
                    </div>
                )}

                {selectedLocation === 'In-Person' && (
                    <div>
                        <label className="form-label mt-3">Location event URL</label>
                        <Field
                            type="text"
                            name="inPersonEventUrl"
                            className="form-control"
                        />
                        <ErrorMessage name="inPersonEventUrl" component="div" className="text-danger" />
                    </div>
                )}
            </div> */} 
            <label className='form-label mt-3'>Location</label>
            <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="Online">
                <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                        <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                            Online
                        </span>
                    </span>
                </span>
                <span className='form-check form-check-custom form-check-solid'>
                    <Field
                        type="radio"
                        name="event_location"
                        value="Online"
                        id="Online"
                        className="form-check-input"
                    />
                </span>
            </label>
            <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="In-Person">
                <span className='d-flex align-items-center me-2'>
                    <span className='d-flex flex-column'>
                        <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                            In-Person
                        </span>
                    </span>
                </span>
                <span className='form-check form-check-custom form-check-solid'>
                    <Field
                        type="radio"
                        name="event_location"
                        value="In-Person"
                        id="In-Person"
                        className="form-check-input"
                    />
                </span>
            </label>
            <ErrorMessage name="event_location" component="div" className="text-danger" />
            <div className="form-group">
                <label className="form-label mt-3">Event URL</label>
                <Field
                    type="text"
                    name="onlineEventUrl"
                    className="form-control"
                />
                <ErrorMessage name="onlineEventUrl" component="div" className="text-danger" />
            </div>
            <div className="form-group">
                <label className="form-label mt-3 mb-3" htmlFor="event_cover_img">Cover Image</label>
                {/* <Field
                    type="file"
                    id="event_cover_img"
                    name="event_cover_img"
                    className="form-control"
                    onChange={handleFileChange}
                    accept="image/png, image/gif, image/jpeg"
                /> */}
                <input type="file" id="event_cover_img" name="event_cover_img" style={{color:'transparent'}}  onChange={handleImageChange} className="form-control" accept="image/png, image/gif, image/jpeg"  />
                { selectedFileName && <p>Selected File: {selectedFileName}</p>}
                {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}
                <ErrorMessage name="event_cover_img" component="div" className="text-danger" />
            </div>

            {/* <div className="form-group">
                <label className="form-label mt-3 mb-3" htmlFor="event_description">
                    Description
                </label>
                <Field
                    name="event_description"
                    className="form-control"
                    as="textarea"
                />
                <ErrorMessage
                    name="event_description"
                    component="div"
                    className="text-danger"
                />
            </div> */}
            <div className="form-group">
                <label htmlFor="event_description" className='form-label mt-3 mb-2'>Description</label>
                <ReactQuill
                    value={editorContent}
                    placeholder="Write something..."
                    modules={module}
                    onChange={(content) => {
                        setEditorContent(content);
                        formik.setFieldValue('event_description', content); // Use setFieldValue from formik context
                    }}
                />
                {/* <ErrorMessage name="event_description" component="div" className="text-danger" /> */}
            </div>
            {/* <h5 className="mt-5 mb-5">Customize the Lock Screen ?</h5> */}
            <div className="form-check form-switch">
                <Field
                    className="form-check-input"
                    name="event_lockscreen"
                    type="hidden"
                    id="flexSwitchCheckDefault"
                />
                {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Active Lock Screen and Customize the call to action
                </label> */}
            </div>
            {/* Access Level input */}
            <div className="form-group"> 
                <label className='form-label mt-3'>Access Level</label>
                <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="Free">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                Free
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Free for everyone
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_access_level"
                            value="Free"
                            className="form-check-input"
                            id="Free"
                            checked="checked"
                        // checked={values.event_access_level === 'Free'}
                        />
                    </span>
                </label>
                {/* <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="paid">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                Paid members
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Restricted to paid members
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_access_level"
                            value="Paid members"
                            id="paid"
                            className="form-check-input"
                        // checked={values.event_access_level === 'Paid members'} // Update property name here
                        />
                    </span>
                </label>
                <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="one_time">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                One-time fee
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Collect payment for access
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_access_level"
                            value="One-time fee"
                            id="one_time"
                            className="form-check-input"
                        // checked={values.event_access_level === 'One-time fee'}
                        />
                    </span>
                </label>
                <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="By_plans">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                By plans
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Accessible to members on specific plan
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_access_level"
                            value="By plans"
                            id="By_plans"
                            className="form-check-input"
                        // checked={values.event_access_level === 'By plans'}
                        />
                    </span>
                </label> */}
                <ErrorMessage name="event_access_level" component="div" className="text-danger" />
            </div>


            {/* Visibility Setting input */}
            <div className="form-group"> 
                <label className='form-label'>Visibility Setting</label>
                <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="community">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                Community
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Only community member can view
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_visibility"
                            value="Community"
                            className="form-check-input"
                            id="community"
                            checked="checked"
                        // checked={values.event_visibility === 'Community'}
                        />
                    </span>
                </label>
                {/* <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="public">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                Public
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Anyone on the web can view
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_visibility"
                            value="Public"
                            className="form-check-input"
                            id="public"
                        // checked={values.event_visibility === 'Public'}
                        />
                    </span>
                </label> */}
                {/* <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="restricted">
                    <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                            <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                Restricted
                            </span>
                            <span className='fs-6 fw-bold text-gray-400'>
                                Restricted to specific members
                            </span>
                        </span>
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                        <Field
                            type="radio"
                            name="event_visibility"
                            value="Restricted"
                            className="form-check-input"
                            id="restricted"
                        // checked={values.event_visibility === 'Restricted'}
                        />
                    </span>
                </label> */}
                <ErrorMessage name="event_visibility" component="div" className="text-danger" />
            </div>
            <div className="form-group">
                {/* <label htmlFor="event_custom_url" className="form-label mt-3 mb-3">Custom URL</label> */}
                <input type="hidden" name="event_custom_url" className="form-control" />
                {/* <ErrorMessage name="event_custom_url" component="div" className="text-danger" /> */}
            </div>
            <div className="form-group">
                {/* <label htmlFor="channel_name">Created by</label> */}
                <Field type="hidden" name="event_created_by" className="form-control w-50" />
            </div>
        </div>
    )
}

export default EventDetails