import React,{useEffect,useState} from "react";
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {events_get_data } from "../modules/auth/core/_requests";
import { ListsWidgetUpcomingEvents } from "../../_metronic/partials/widgets/lists/UpcomingEventsList";

interface EventsDatas {
  event_name: string;
  event_start_date: string;
  event_end_date: string;
  event_time_zone: string;
  event_created_on: string;
  event_created_by: string;
  event_start_time: string;
  event_end_time: string;
  event_status: string;
  event_description:string;
  event_id:string;
  event_location:string;
}

export function UserEvents() {
  const [upcomingEvents, setupcomingEvents] = useState<EventsDatas[]>([]);
  const [pastEvents, setpastEvents] = useState<EventsDatas[]>([]);
  const [loading, setLoading] = useState(false);

    const handleUpcomingEvents = async (upcoming_events: any) => {
        try {
          setLoading(true);
           const response = await events_get_data(upcoming_events);
           setupcomingEvents(response.data.upcoming_events);
           setLoading(false);
        } catch (error) {
           console.error('Error fetching events data:', error);
        }
    };

  const handlePastEvents = async (past_events: any) => {
      try {
         setLoading(true);
         const response = await events_get_data(past_events);
         setpastEvents(response.data.past_events);
         setLoading(false);
      } catch (error) {
         console.error('Error fetching events data:', error);
      }
  };

useEffect(() => {
  handleUpcomingEvents("1");
}, []);

const UpcomingEventsTab = () => {
  handleUpcomingEvents("1");
};

const PastEventsTab = () => {
  handlePastEvents("0");
};


    return (
        <>
        {/* <div className="d-flex justify-content-center p-10">
          <div className="car">
          <img src={toAbsoluteUrl('/media/custom/coming_soon.png')}
             alt='' className="w-450px" />
               <h3 className="text-center">Coming Soon!</h3>
          </div>


        </div> */}

<ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
  <li className="nav-item" onClick={UpcomingEventsTab}>
    <a
      className="nav-link active"
      data-bs-toggle="tab"
      href="#upcoming_events_tab"
    >
      Upcoming Events
    </a>
  </li>
  <li className="nav-item" onClick={PastEventsTab}>
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#past_events_tab"
    >
     Past Events
    </a>
  </li>

</ul>


        <div className='d-flex flex-wrap flex-stack mb-6'>

        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
               <option value='Active'>Active</option>
              <option value='Approved'>Free</option>
              <option value='Free'>Paid</option>
            </select>
          </div>

        </div> */}
      </div>

      <div className='row g-6 g-xl-9'>


        <div className="tab-content" id="myTabContent">
  <div
    className="tab-pane fade show active"
    id="upcoming_events_tab"
    role="tabpanel"
  >
    {loading ? (
        <div
        className="spinner-border text-success"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
     upcomingEvents.length > 0 ? (
       upcomingEvents.map((upcomingEvent, index) => (
                <div key={index}>
       { upcomingEvent.event_status=='1'?(
 <ListsWidgetUpcomingEvents
event_title= {upcomingEvent.event_name}
event_description = {upcomingEvent.event_description}
event_startdate = {upcomingEvent.event_start_date}
event_starttime = {upcomingEvent.event_start_time}
event_enddate = {upcomingEvent.event_end_date}
event_endtime = {upcomingEvent.event_end_time}
event_timezone = {upcomingEvent.event_time_zone}
event_id= {upcomingEvent.event_id}
event_location= {upcomingEvent.event_location}
event_status = {upcomingEvent.event_status}
/>
):('')}
             </div>
          ))
          ) : !loading && (
            <div className="d-flex justify-content-center p-10">
            <div className="car">
            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')}
               alt='' className="w-350px mb-5" />
                 <h3 className="text-center">No Event Found</h3>
            </div>


            </div>
          )
    )}

  </div>
  <div className="tab-pane fade" id="past_events_tab" role="tabpanel">

  {loading ? (
       <div
       className="spinner-border text-success"
       role="status"
     >
       <span className="visually-hidden">Loading...</span>
     </div>
    ) : (
      pastEvents.length > 0 ? (
        pastEvents.map((pastEvent, index) => (
                <div key={index}>
                 { pastEvent.event_status=='1'?(
 <ListsWidgetUpcomingEvents
event_title= {pastEvent.event_name}
event_description = {pastEvent.event_description}
event_startdate = {pastEvent.event_start_date}
event_starttime = {pastEvent.event_start_time}
event_enddate = {pastEvent.event_end_date}
event_endtime = {pastEvent.event_end_time}
event_timezone = {pastEvent.event_time_zone}
event_id= {pastEvent.event_id}
event_location= {pastEvent.event_location}
event_status = {pastEvent.event_status}
/>
):('')}
             </div>
          ))
          ) : !loading && (
            <div className="d-flex justify-content-center p-10">
            <div className="car">
            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')}
               alt='' className="w-350px mb-5" />
                 <h3 className="text-center">No Event Found</h3>
            </div>


            </div>
          )
    )}

  </div>

</div>
      </div>

      {/* <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

        <ul className='pagination'>
          <li className='page-item previous'>
            <a href='#' className='page-link'>
              <i className='previous'></i>
            </a>
          </li>

          <li className='page-item active'>
            <a href='#' className='page-link'>
              1
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              2
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              3
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              4
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              5
            </a>
          </li>

          <li className='page-item'>
            <a href='#' className='page-link'>
              6
            </a>
          </li>

          <li className='page-item next'>
            <a href='#' className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div> */}

        </>
    )
}

const users1 = [
    {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
    {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
    {name: 'Susan Redwood', initials: 'S', color: 'primary'},
  ]

