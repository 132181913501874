/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl,toAbsoluteUrlImage} from '../../../helpers'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  firstname: string
  job: string
  company: string
  avgEarnings: string
  totalEarnings: string
  follow: string
  lastname: string
}

const Card3: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  firstname,
  lastname,
  job,
  company,
  avgEarnings,
  totalEarnings,
  follow,
}) => {
  return (

    <div className='card h-100' style={{cursor:'pointer'}}>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle member_profile'>
            {avatar === "" || avatar === null ? (
              // <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
              <span className={`symbol-label bg-light-success text-grey fs-5 fw-bolder`}>
              {firstname.toUpperCase().charAt(0)} {lastname.toUpperCase().charAt(0)}
              </span>
            ) : (
              <img alt='Pic' src={toAbsoluteUrlImage(avatar)} className='lozad' data-src="assets/media/misc/stock/600x400/img-1.jpg"/>
            )}
            {online && (
              <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
            )}
          </div>
        </div>

        <span className='fs-4 text-gray-800  text-center text-hover-primary fw-bolder mb-0 '>
          {firstname}{' '}{lastname}
        </span>

        <div className='fw-bold text-gray-400 mb-6'>{job}</div>
        <div className='fw-bold text-success mb-6'>{company}</div>
{/*
        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{avgEarnings}</div>
            <div className='fw-bold text-gray-400'>Avg. Earnings</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{totalEarnings}</div>
            <div className='fw-bold text-gray-400'>Total Sales</div>
          </div>
        </div> */}


        {/* <a href='#' className={follow==='Follow' ? ('btn btn-sm  btn-light') : ( 'btn btn-sm  btn-primary')}>

          <KTIcon iconName={follow==='Follow' ? ('plus') : ( 'check')} className='fs-3' />
          {follow}
        </a> */}
      </div>
    </div>

  )
}

export {Card3}
