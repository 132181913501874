import React from 'react'

const Membership = () => {
  return (
    <>
      <div className="card card-custom mt-5">
        <div className="card-header">
          <h3 className="card-title">Customize membership questions</h3>
        </div>
        <div className="card-body">
          <form>
            <label className="required form-label mt-3">Title</label>
            <textarea className="form-control w-50 mb-5" ></textarea>
            <label className="form-label mt-3">Instructions (optional)</label>
            <input
              type="text"
              className="form-control w-50"
              placeholder="Add youtube or URL"
            />
            <h5 className='mt-5 mb-5'>Skip questions</h5>
            <div className="form-check form-switch">
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                Active Lock Screen and Customize the call to action
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
            <div>
              <button type="button" className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mt-5">
                <i className="bi bi-plus plus_icon"></i> Add questions
              </button>
            </div>
            <div className='d-flex mt-10 float-end'>
              <div>
                <button type="button" className="btn btn-primary">
                  <i className="bi bi-eye"></i> Preview
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-primary ms-10 ps-35 pe-35"> Save </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default Membership