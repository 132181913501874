/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,useState } from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../modules/auth'
import { getUserByToken,questionare } from '../../modules/auth/core/_requests'
import { KTSVG } from '../../../_metronic/helpers'
import Swal from 'sweetalert2';
export function DashboardOnboarding()  {

  const TextIndent = {
    paddingLeft:'1.1em',textIndent:'-1.1em'
  };


  const loginSchema = Yup.object().shape({
    question_1: Yup.array()
      .min(1, 'At least one option must be selected for Question 1')
      .required('Question 1 is Required'),
    question_2: Yup.array()
      .min(1, 'At least one option must be selected for Question 2')
      .required('Question 2 is Required'),
      questions_3: Yup.string().required('Please select one option for Question 3'),
      questions_4: Yup.string().required('Please select one option for Question 4'),
      questions_5: Yup.string().required('Please select one option for Question 5'),
      questions_6: Yup.string().required('Question 6 is Required'),
      questions_7: Yup.string().required('Question 7 is Required'),
      questions_8: Yup.string().required('Question 8 is Required'),
      questions_9: Yup.string().required('Question 9 is Required'),
      acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
  });
  type FormValues = {
    question_1: string[]; // Array of strings for the checkbox values
    question_2: string[];
    questions_3: string;
    questions_4: string;
    questions_5: string;
    questions_6: string;
    questions_7: string;
    questions_8: string;
    questions_9: string;
    acceptTerms:boolean;
  };

  const initialValues: FormValues = {
    question_1: [],
    question_2: [],
    questions_3: '',
    questions_4: '',
    questions_5: '',
    questions_6: '',
    questions_7: '',
    questions_8: '',
    questions_9: '',
    acceptTerms: false,

  }
  const [swalProps, setSwalProps] = useState({});

    const [showModal, setShowModal] = useState(true);
    const [showClose, setShowModalClose] = useState({ display: 'block' });

    const [showModalPrivacy, setShowModalPrivacy] = useState(false);
    const [showClosePrivacy, setShowModalClosePrivacy] = useState({ display: 'none' });


    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    const [showResults, setShowResults] = useState(false)

    const {currentUser, logout} = useAuth()
    const [userauthtoken, setauthtoken] = useState(currentUser?.email || '')
    const formik = useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, { setStatus, setSubmitting }) => {
        setLoading(true)
        try {

            const {data:auth} = await questionare(values.question_1,values.question_2,
            values.questions_3,values.questions_4,values.questions_5,values.questions_6,values.questions_7,values.questions_8,values.questions_9,userauthtoken)
            const { data: user } = await getUserByToken(auth.api_token)

            setCurrentUser(user)
            setSubmitting(false)
            setLoading(false)
            setShowModal(false)
            setShowResults(true)
            // window.location.reload();
            setShowModalClose({ display: showClose.display === 'block' ? 'none' : 'block' });
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Successfully Submitted',
              showConfirmButton: true,
              timer: 3000
          });



           //  // const { data: auth } = await login(values.email, values.password)

        //  // const loginStatus = auth.api_token;
        //   // Assuming 'status' indicates the login status
        //   console.log(loginStatus);
        //   if (loginStatus != undefined) {
        //    // const { data: user } = await getUserByToken(auth.api_token)
        //   //  setCurrentUser(user)
        //   } else {
        //   //  saveAuth(undefined)
        //     setStatus('The login details are incorrect')
        //     setSubmitting(false)
        //     setLoading(false)
        //   }

        } catch (error) {
          console.error(error)
          //saveAuth(undefined)
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      },
    })

    const Results = () => (
      <div className='justify-content-center text-center'>
      <span className="fa-regular fa-circle-check text-success" style={{fontSize:'120px',alignItems:'center'}}></span>
      <h1 className='mt-5'>Thank you for sharing the details!</h1>
      </div>
    )

    const openPrivacymodal = () => {

      setShowModal(false);
      setShowModalClose({ display: 'none' });

      setShowModalPrivacy(true);
      setShowModalClosePrivacy({ display: 'block' });
    };


  const closePrivacymodal = () => {
    setShowModal(true);
    setShowModalClose({ display: 'block' });

    setShowModalPrivacy(false);
    setShowModalClosePrivacy({ display: 'none' });
  };
  return (
    <>
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex={-1} style={showClose} id="kt_modal_1">
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header bg-success">
              {/* <h2 className="modal-title align-center text-light fw-normal">🎉 Please answer these questions to complete your Leaders Club profile <br></br><small>(Total: #9 Questions)</small></h2> */}
              <h2 className="modal-title align-center text-light fw-normal">Please answer these questions to complete your Leaders Club profile <br></br><small>(Total: #9 Questions)</small></h2>

            </div>
            <div className="modal-body">
            { showResults ? <Results /> : null }
            {/* <div className=''>
      <div className=''>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-5'
          id='kt_create_account_stepper'
         >

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='w-100 pb-10' id='kt_create_account_form'>
                <div className='current w-100' data-kt-stepper-element='content'>
                <div className="form-group pb-2 fv-row">
                  <label className="form-label fs-4">1. Why did you join the Leaders Club?</label>

                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                      <input className="form-check-input" name="question_1" type="checkbox" value="" id="warm_intro" />
                      <label className="form-check-label text-dark" htmlFor='warm_intro'>
                        Warm introduction
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                      <input className="form-check-input" name="question_1" type="checkbox" value="" id="network_opportunites" />
                      <label className="form-check-label text-dark" htmlFor='network_opportunites'>
                        Networking opportunities
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                      <input className="form-check-input" name="question_1" type="checkbox" value="" id="knew_people" />
                      <label className="form-check-label text-dark" htmlFor='knew_people'>
                        Knew existing people in the club
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                      <input className="form-check-input" name="question_1" type="checkbox" value="" id="no_fee" />
                      <label className="form-check-label text-dark" htmlFor='no_fee'>
                        No Fee
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                      <input className="form-check-input" name="question_1" type="checkbox" value="" id="access_sleaders" />
                      <label className="form-check-label text-dark" htmlFor='access_sleaders'>
                        Access to senior leaders
                      </label>
                    </div>
                  </div>
                </div>



                </div>

                <div data-kt-stepper-element='content'>
                STEP 2
                </div>

                <div data-kt-stepper-element='content'>
            sdfsdf
                </div>

                <div data-kt-stepper-element='content'>
       sdf
                </div>

                <div data-kt-stepper-element='content'>
           sdf
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-0'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-1'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-1'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div> */}


              <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='onboarding_form'
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      method='POST' encType='multipart/form-data' >

                <div className="form-group pb-2">
                  <label className="form-label fs-4" style={TextIndent}>1. Why did you join the Leaders Club?*</label>

                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_1[]"
              type="checkbox"
              value="Warm introduction"
              id="warm_intro"
              checked={formik.values.question_1.includes('Warm introduction')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_1',
                  checked
                    ? [...formik.values.question_1, value]
                    : formik.values.question_1.filter((item) => item !== value)
                );
              }}
            />                      <label className="form-check-label text-dark" htmlFor='warm_intro'>
                        Warm introduction
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_1[]"
              type="checkbox"
              value="Networking opportunities"
              id="network_opportunites"
              checked={formik.values.question_1.includes('Networking opportunities')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_1',
                  checked
                    ? [...formik.values.question_1, value]
                    : formik.values.question_1.filter((item) => item !== value)
                );
              }}
            />                        <label className="form-check-label text-dark" htmlFor='network_opportunites'>
                        Networking opportunities
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_1[]"
              type="checkbox"
              value="Knew existing people in the club"
              id="knew_people"
              checked={formik.values.question_1.includes('Knew existing people in the club')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_1',
                  checked
                    ? [...formik.values.question_1, value]
                    : formik.values.question_1.filter((item) => item !== value)
                );
              }}
            />                            <label className="form-check-label text-dark" htmlFor='knew_people'>
                        Knew existing people in the club
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_1[]"
              type="checkbox"
              value="No Fee"
              id="no_fee"
              checked={formik.values.question_1.includes('No Fee')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_1',
                  checked
                    ? [...formik.values.question_1, value]
                    : formik.values.question_1.filter((item) => item !== value)
                );
              }}
            />                        <label className="form-check-label text-dark" htmlFor='no_fee'>
                        No Fee
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_1[]"
              type="checkbox"
              value="Access to senior leaders"
              id="access_sleaders"
              checked={formik.values.question_1.includes('Access to senior leaders')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_1',
                  checked
                    ? [...formik.values.question_1, value]
                    : formik.values.question_1.filter((item) => item !== value)
                );
              }}
            />                        <label className="form-check-label text-dark" htmlFor='access_sleaders'>
                        Access to senior leaders
                      </label>
                    </div>
                  </div>
                  {formik.touched.question_1 && formik.errors.question_1 && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.question_1}</span>
          </div>
        )}
                </div>

                <div className="form-group pb-2">
                  <label className="form-label fs-4" style={TextIndent}>2. What is the most important aspect of joining a business club?*</label>

                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Career enhancement"
              id="career_enhancement"
              checked={formik.values.question_2.includes('Career enhancement')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            />                      <label className="form-check-label text-dark" htmlFor='career_enhancement'>
                        Career enhancement
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Access to similar leaders"
              id="atsl"
              checked={formik.values.question_2.includes('Access to similar leaders')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            />                      <label className="form-check-label text-dark" htmlFor='atsl'>
                        Access to similar leaders
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Networking"
              id="networking"
              checked={formik.values.question_2.includes('Networking')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            />
            <label className="form-check-label text-dark" htmlFor='networking'>
                        Networking
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Training courses"
              id="traningCourses"
              checked={formik.values.question_2.includes('Training courses')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            />
                      <label className="form-check-label text-dark" htmlFor='traningCourses'>
                        Training courses
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Mentorship opportunities"
              id="mentorshipOpportunities"
              checked={formik.values.question_2.includes('Mentorship opportunities')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            />
                      <label className="form-check-label text-dark" htmlFor='mentorshipOpportunities'>
                        Mentorship opportunities
                      </label>
                    </div>
                  </div>
                  <div className="mb-2 ms-5 fs-5">
                    <div className="form-check form-check-custom form-check form-check-sm">
                    <input
              className="form-check-input"
              name="question_2[]"
              type="checkbox"
              value="Financial opportunities"
              id="financialOpportunities"
              checked={formik.values.question_2.includes('Financial opportunities')}
              onChange={(e) => {
                const value = e.target.value;
                const checked = e.target.checked;

                formik.setFieldValue(
                  'question_2',
                  checked
                    ? [...formik.values.question_2, value]
                    : formik.values.question_2.filter((item) => item !== value)
                );
              }}
            /><label className="form-check-label text-dark" htmlFor='financialOpportunities'>
                        Financial opportunities
                      </label>
                    </div>
                  </div>
                  {formik.touched.question_2 && formik.errors.question_2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.question_2}</span>
            </div>
          </div>
        )}
                </div>
                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>3. Would you be interested in participating in a small cohort of 6-10 Leaders with in-person meetings on a regular basis?*</label>
                  <div className="ms-5 ">
                  <div className="form-check form-check-custom form-check-sm me-5 mb-2">
  <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_3"
    value="yes" // Set the value to "yes" for the "Yes" option
    id="questions_3_yes"
    checked={formik.values.questions_3 === 'yes'}
    onChange={formik.handleChange}
  />
  <label className="form-check-label text-dark fs-5" htmlFor="questions_3_yes">
    Yes
  </label>
</div>
<div className="form-check form-check-custom form-check-sm me-10">
  <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_3"
    value="no" // Set the value to "no" for the "No" option
    id="questions_3_no"
    checked={formik.values.questions_3 === 'no'}
    onChange={formik.handleChange}
  />
  <label className="form-check-label text-dark fs-5" htmlFor="questions_3_no">
    No
  </label>

</div>

{formik.errors.questions_3 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_3}</span>
          </div>
        )}
                  </div>
                </div>

                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>4. Do you prefer* </label>
                  <div className="ms-5 ">
                    <div className="form-check form-check-custom form-check-sm me-5 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_4"
    value="Intimate group settings for discussions" // Set the value to "yes" for the "Yes" option
    id="questions_4_yes"
    checked={formik.values.questions_4 === 'Intimate group settings for discussions'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_4_yes">
                        Intimate group settings for discussions
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_4"
    value="Larger meetings" // Set the value to "yes" for the "Yes" option
    id="questions_4_no"
    checked={formik.values.questions_4 === 'Larger meetings'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_4_no">
                        Larger meetings
                      </label>
                    </div>
                    {formik.errors.questions_4 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_4}</span>
          </div>
        )}
                  </div>
                </div>
                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>5. Do you like to attend virtual events to listen to well-known Speakers?*
                  </label>
                  <div className=" ms-5 ">
                    <div className="form-check form-check-custom form-check-sm me-5 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_5"
    value="Yes" // Set the value to "yes" for the "Yes" option
    id="questions_5_yes"
    checked={formik.values.questions_5 === 'Yes'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_5_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_5"
    value="No" // Set the value to "yes" for the "Yes" option
    id="questions_5_no"
    checked={formik.values.questions_5 === 'No'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_5_no">
                        No
                      </label>
                    </div>
                  </div>
                  {formik.errors.questions_5 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_5}</span>
          </div>
        )}
                </div>
                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>6. What is the most important aspect of joining a business club*
                  </label>
                  <div className=' ms-5 me-5'>
                  <textarea
            name="questions_6"
            className="form-control"
            value={formik.values.questions_6}
            onChange={formik.handleChange}
          />
        </div>
        {formik.touched.questions_6 && formik.errors.questions_6 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_6}</span>
          </div>
        )}
                </div>

                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>7. Please answer these questions if you are interested in being matched for advisor/ mentorship opportunities with companies/ start-ups*
                  </label>
                  <div className="ms-5 ">
                    <div className="form-check form-check-custom form-check-sm me-5 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_7"
    value="Are you interested in working with Start-Ups in a role as an Advisor" // Set the value to "yes" for the "Yes" option
    id="questions_7_yes"
    checked={formik.values.questions_7 === 'Are you interested in working with Start-Ups in a role as an Advisor'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_7_yes">
                        Are you interested in working with Start-Ups in a role as an Advisor
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_7"
    value="Mentor" // Set the value to "yes" for the "Yes" option
    id="questions_7_no"
    checked={formik.values.questions_7 === 'Mentor'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_7_no">
                        Mentor
                      </label>
                    </div>
                  </div>
                  {formik.touched.questions_7 && formik.errors.questions_7 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_7}</span>
          </div>
        )}
                </div>

                <div className="form-group pb-5">
                  <label className="form-label fs-4" style={TextIndent}>8. Please select your desired startup size where you are comfortable advising*
                  </label>
                  <div className="ms-5 ">
                    <div className="form-check form-check-custom form-check-sm me-5 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_8"
    value="Pre-Seed / Seed" // Set the value to "yes" for the "Yes" option
    id="questions_8_yes"
    checked={formik.values.questions_8 === 'Pre-Seed / Seed'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_8_yes">
                        Pre-Seed / Seed
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_8"
    value="Mentor" // Set the value to "yes" for the "Yes" option
    id="questions_8_no"
    checked={formik.values.questions_8 === 'Mentor'}
    onChange={formik.handleChange}
  />

                      <label className="form-check-label text-dark fs-5" htmlFor="questions_8_no">
                        Mentor
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_8"
    value="Mature companies" // Set the value to "yes" for the "Yes" option
    id="questions_8_other"
    checked={formik.values.questions_8 === 'Mature companies'}
    onChange={formik.handleChange}
  />                      <label className="form-check-label text-dark fs-5" htmlFor="questions_8_other">
                        Mature companies
                      </label>
                    </div>
                  </div>
                  {formik.touched.questions_8 && formik.errors.questions_8 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_8}</span>
          </div>
        )}
                </div>
                <div className="form-group pb-2">
                  <label className="form-label fs-4" style={TextIndent}>9. Would you like to provide mentorship to aspiring leaders?*
                  </label>
                  <div className="ms-5 ">
                    <div className="form-check form-check-custom form-check-sm me-5 mb-2">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_9"
    value="Yes" // Set the value to "yes" for the "Yes" option
    id="questions_9_yes"
    checked={formik.values.questions_9 === 'Yes'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_9_yes">
                        Yes       </label>
                    </div>
                    <div className="form-check form-check-custom form-check-sm me-10">
                    <input
    className="form-check-input h-20px w-20px"
    type="radio"
    name="questions_9"
    value="No" // Set the value to "yes" for the "Yes" option
    id="questions_9_no"
    checked={formik.values.questions_9 === 'No'}
    onChange={formik.handleChange}
  />
                      <label className="form-check-label text-dark fs-5" htmlFor="questions_9_no">
                        No
                      </label>
                    </div>
                  </div>
                  {formik.touched.questions_9 && formik.errors.questions_9 && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.questions_9}</span>
          </div>
        )}
        </div>
        <div className='fv-row mt-8 mb-5'>
        <label className='form-check form-check-inline' style={{cursor:'pointer'}}>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I accept the{' '}
            <span   onClick={openPrivacymodal} className='link-primary'>
             Privacy Policy
            </span>
            .

          </span>

        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
              </form>

            </div>
            <div className="modal-footer">

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='onboarding_form'
          className='btn btn-success'
          form="onboarding_form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
              {/* <button type="submit" form="onboarding_form" className="btn btn-primary">
                Submit
              </button> */}
            </div>

          </div>
        </div>
      </div>


      <div className={`modal fade ${showModalPrivacy ? 'show' : ''}`} id="privacy_modal" style={showClosePrivacy} tabIndex={-1} aria-hidden="true">


<div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title">Privacy Policy (Member Only)</h3>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closePrivacymodal}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
      <div className='row'>
<p className="fs-4">
All Members of the Leaders Club, collectively referred to as the 'Members,' hereby acknowledge and affirm their commitment to act in the best interest of the Leaders Club. In their capacity as Members, they shall diligently and responsibly perform their duties, promoting the overall well-being, growth, and success of the Leaders Club.
</p>
<p className="fs-4">
Each Member shall prioritize the leaders club interests above their personal or individual interests. They shall exercise their roles and responsibilities with the utmost care, integrity, and professionalism while demonstrating a high level of ethical conduct. Members shall avoid any conflicts of interest that may compromise the Leaders Club's objectives or undermine its reputation.
</p>
<p className="fs-4">
Furthermore, Members shall engage in open and transparent communication, fostering an environment of collaboration, trust, and mutual respect. They shall actively contribute to the Leaders Club's initiatives, sharing knowledge, insights, and experiences for the benefit of all stakeholders involved.
</p>
<p className="fs-4">
Members shall comply with all applicable laws, regulations, and guidelines, ensuring full legal compliance in all their actions and decisions related to the Leaders Club. They shall refrain from engaging in any activities that may cause harm or damage to the Leaders Club or its members.
In the event of disputes or disagreements, Members shall strive to resolve them amicably, utilizing appropriate conflict resolution mechanisms available within the Leaders Club. They shall prioritize the preservation of harmonious relationships, seeking consensus and common ground whenever possible.
</p>
<p className="fs-4">
This commitment to act in the best interest of the Leaders Club shall endure throughout the duration of each Member's participation in the Leaders Club. Members acknowledge that their actions and conduct reflect upon the Leaders Club as a whole, and they shall conduct themselves in a manner that upholds the Leaders Club's values, reputation, and objectives.
By accepting membership in the Leaders Club, each Member affirms their understanding of this commitment and agrees to abide by its principles. This commitment shall serve as a guiding principle for all Members, promoting a unified and purposeful approach toward achieving the Leaders Club's goals."
</p>
<p className="fs-4">
Please note that this response is provided for informational purposes only and should not be considered as legal advice. For specific legal matters related to your situation, it is recommended to consult with a qualified attorney.
</p>
<p className="fs-4">
Participants are free to use the information they receive, but they are prohibited from revealing the identity or affiliation of any speaker or participant, nor can they attribute any statements to a particular individual. This allows participants to speak more freely and openly without fear of reprisal or retaliation.The rule helps to ensure that sensitive or confidential information is not leaked to the public, while also allowing participants to benefit from the insights and expertise of others in the group.

</p>

<div className="mt-5 mb-20">
<h1 className='mb-5'>Leaders Club Confidential Agreement</h1>
<ul className="fs-4">
<li>You will not share your company's confidential information.</li>
<li>You will not discuss the exact context of our meetups outside the Leaders Club audience.</li>
<li>You can refer to any company's CXOs name with their approvals.</li>
<li>You will not take any pictures or screenshots of virtual meetups.</li>
<li>You will not share the context of any meeting on any social media.</li>
<li>You will not invite people without prior approval.</li>
<li>Any promotional material content is prohibited</li>
</ul>
</div></div>
      </div>

    </div>
  </div>
</div>
    </>
  )
}


