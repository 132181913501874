import React, { useState, useEffect } from 'react'
import { pastEvents_get_data } from '../../../../modules/auth/core/_requests'
import { events_rsvp_data } from '../../../../modules/auth/core/_requests'
import { Events_publish_data } from '../../../../modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'


interface EventsDatas {
  event_id: string
  event_name: string;
  event_start_date: string;
  event_end_date: string;
  event_time_zone: string;
  event_created_on: string;
  event_created_by: string;
  event_start_time: string;
  event_end_time: string;
  event_status: string;
}

interface RSVPDatas {
  member_id: string;
  member_name: string;
  member_email: string;
  member_roles: string;
  member_pic: string;
  rsvp_date: string;
  member_company: string;
}

const PastEvents = () => {
  const [pastEvents, setPastEvents] = useState<EventsDatas[]>([])
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const [rsvpData, setRsvpData] = useState<RSVPDatas[]>()
  const [isLoading, setIsLoading] = useState(true);
  const [rsvpLoading, setRsvpLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");




  const fetchEventsData = async (past_events: any) => {
    try {
      const response = await pastEvents_get_data(past_events);
      setPastEvents(response.data.past_events);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching events data:', error);
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    return `${month}`;
  };

  const openRSVPModal = (event_id: any) => {
    setShowRSVPModal(true);
    fetchRSVPData(event_id);
  };

  const closeRSVPModal = () => {
    setShowRSVPModal(false);
  };

  const fetchRSVPData = async (event_rsvp_id: any) => {
    setRsvpLoading(true); 
    try {
      const response = await events_rsvp_data(event_rsvp_id); 
      setRsvpData(response.data.rsvp_deails);
      setRsvpLoading(false)
    } catch (error) {
      console.error('Error fetching events data:', error);
    }
  };

  useEffect(() => {
    fetchRSVPData("33");
  }, []);

  const handleUnpublish = async (event_status_id: any, event_status: string) => {
    try {
      const response = await Events_publish_data(event_status_id, event_status);
      const event_status_new = event_status == '0' ? '1' : '0'; setPastEvents(pastEvents => pastEvents.map(event => event.event_id === event_status_id ? { ...event, event_status: event_status_new } : event));
    } catch (error) { console.error('Error updating event status:', error); }
  };

  useEffect(() => {
    fetchEventsData("0");
  }, []);

  const handleSearch = () => {
    // Get the value from the search input and update the searchQuery state
    const searchInput = document.getElementById("form1");
    if (searchInput instanceof HTMLInputElement) {
      setSearchQuery(searchInput.value);
    }
  };

  return (
    <>

      <div className="card card-custom p-5 mt-5">
        <div className='d-flex justify-content-between align-items-center mt-10'>
          <div className="input-group">
            <input type="search" id="form1" className="form-control w-50"
              value={searchQuery} placeholder='Search Events...'
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="button" className="btn btn-primary" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="row mt-10">
          <div className="col-md-5 ps-20">
            <h5>Events Name</h5>
          </div>
          <div className="col-md-4">
            <h5 className="text-center">Submitted On</h5>
          </div>
          <div className="col-md-1">
            <h5>Status</h5>
          </div>
          <div className="col-md-2">
            <h5 className="text-center">Action</h5>
          </div>
        </div>
      </div>

      {/* {pastEvents?.filter(item => item?.event_name.toLowerCase().includes(searchQuery.toLowerCase())).map((event) => (
        <React.Fragment key={event.event_id}>
          <div className="card mb-3 mt-7">
            <div className="card-body row">
              <div className="col-md-1">
                <div className="symbol symbol-75px me-10" >
                  <div className="symbol-label fs-2 fw-bold text-success text-center">
                    {event.event_start_date === event.event_end_date ? (
                      <h5>{event.event_start_date.slice(-2)}<br /><span className='pt-5'>{formatDate(event.event_start_date)}</span></h5>
                    ) : (
                      <h5>
                        {event.event_start_date.slice(-2)}-{event.event_end_date.slice(-2)}<br />
                        {formatDate(event.event_start_date)}
                      </h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className='ms-5'>
                  <h5 >{event.event_name}</h5>
                  <p >{event.event_start_time}-{event.event_end_time} {event.event_time_zone}</p>
                </div>
              </div>
              <div className="col-md-3"> <div>
                <h5>{event.event_created_by}</h5>
                <p >{event.event_created_on}</p>
              </div>
              </div>
              <div className="col-md-1">
                {event.event_status === "0" ? (
                  <span className="badge bg-dark text-white">Unpublished</span>
                ) : event.event_status === "1" ? (
                  <span className="badge bg-success text-white">Published</span>
                ) : null}
              </div>
              <div className="col-md-2 dropdown">
                <div className='ms-10'>
                  <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots fs-3"></i>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(event.event_id, event.event_status)}>{event.event_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                    <li><button className="dropdown-item" type="button" onClick={() => openRSVPModal(event.event_id)}>RSVP List</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))} */}

      {isLoading ? (
        // Show loading animation using Bootstrap spinner
        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        pastEvents.length > 0 ? (
          pastEvents
            .filter((item) =>
              item?.event_name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((event) => (
              <React.Fragment key={event.event_id}>
                <div className="card mb-3 mt-7">
                  <div className="card-body row">
                    <div className="col-md-1">
                      <div className="symbol symbol-75px me-10" >
                        <div className="symbol-label fs-2 fw-bold text-success text-center">
                          {event.event_start_date === event.event_end_date ? (
                            <h5>{event.event_start_date.slice(-2)}<br /><span className='pt-5'>{formatDate(event.event_start_date)}</span></h5>
                          ) : (
                            <h5>
                              {event.event_start_date.slice(-2)}-{event.event_end_date.slice(-2)}<br />
                              {formatDate(event.event_start_date)}
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className='ms-5'>
                        <h5 >{event.event_name}</h5>
                        <p >{event.event_start_time}-{event.event_end_time} {event.event_time_zone}</p>
                      </div>
                    </div>
                    <div className="col-md-3"> <div>
                      <h5>{event.event_created_by}</h5>
                      <p >{event.event_created_on}</p>
                    </div>
                    </div>
                    <div className="col-md-1">
                      {event.event_status === "0" ? (
                        <span className="badge bg-dark text-white">Unpublished</span>
                      ) : event.event_status === "1" ? (
                        <span className="badge bg-success text-white">Published</span>
                      ) : null}
                    </div>
                    <div className="col-md-2 dropdown">
                      <div className='ms-10'>
                        <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi bi-three-dots fs-3"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                          <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(event.event_id, event.event_status)}>{event.event_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                          <li><button className="dropdown-item" type="button" onClick={() => openRSVPModal(event.event_id)}>RSVP List</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
        ) : (
          <div className="card card-custom mt-5">
            <div className="d-flex justify-content-center p-10">
              <div className="car">
                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                <h3 className="text-center">No Events Found</h3>
              </div>
            </div>
          </div>
        )
      )}

      <div className="modal" tabIndex={-1} role="dialog" style={{ display: showRSVPModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          {rsvpLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">RSVP List</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeRSVPModal}></button>
              </div>
              <div className="modal-body">
                {rsvpData && rsvpData.length > 0 && (
                  <div className="row pb-10">
                    <div className="col-md-8 ps-20">
                      <h5>Member Details</h5>
                    </div>
                    <div className="col-md-4">
                      <h5>Submitted On</h5>
                    </div>
                  </div>
                )}
                <table className="table table-striped gy-7 gs-7">
                  <tbody>
                    {(rsvpData && rsvpData.length > 0) ? (
                      rsvpData.map((rsvp) => (
                        <React.Fragment key={rsvp.member_id}>
                          <tr>
                            <td>
                              {rsvp.member_pic ? (
                                <img src={rsvp.member_pic} alt='events' style={{ borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px', objectFit: 'cover' }} />
                              ) : (
                                <div className="symbol symbol-50px">
                                  <div className="symbol-label fs-2 fw-bold text-success" style={{ borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px', objectFit: 'cover' }}>{rsvp.member_name[0]}</div>
                                </div>
                              )}
                            </td>
                            <td>
                              <h6 className='pt-3'>{rsvp.member_name}</h6>
                              <h6>{rsvp.member_company}</h6>
                            </td>
                            <td>
                              <h6 className='pt-5 ps-20'>{rsvp.rsvp_date}</h6>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <p className='text-center'>No RSVPs found.</p>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeRSVPModal}>Close</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PastEvents