/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
type Props = {
  className: string
}

const TablesWidget9: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Upcoming Events</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500+ Members Registered</span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {/* <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a> */}

          {/* <Link to='/events'>
            <button
              type='button'
              className={`btn btn-sm btn-light-success`}
            > View All <KTIcon iconName='arrow-right' className='fs-3' /> </button>
          </Link> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>

      {/* <div className="d-flex justify-content-center p-10">
          <div className="car">
          <img src={toAbsoluteUrl('/media/custom/coming_soon.png')}
             alt='' className="w-350px" />
               <h3 className="text-center">Coming Soon!</h3>
          </div>


        </div> */}

        <div className='table-responsive'>

          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Event Name</th>
                <th className='min-w-140px'>Venue</th>
                <th className='min-w-140px'>Event Date</th>
                {/* <th className='min-w-120px'>Current Stats</th> */}
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2 p-0'>

                        <img
                          src={toAbsoluteUrl('/media/events/speaker_bryanna.png')}
                          className='h-50 align-self-center'
                          alt=''
                        />

                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <span className='text-dark fw-bold fs-6'>
                      Executive hiring/coaching/mentoring
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Speaker: Bryanna Friedlander, President at Ransom Group Executive Search Consultants

                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold d-block fs-6'>
                    Online
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  Google meet
                  </span>
                </td>
                <td> <span className='text-dark fw-bold d-block fs-6'>
                August 17th 2023

                </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  4pm PST
                  </span>
                </td>
                {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{ width: '50%' }}
                      ></div>
                    </div>
                  </div>
                </td> */}
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link to='/event-details'>
                      <button
                        type='button'
                        className={`btn btn-sm btn-success`}
                      >  View Event
                        <KTIcon iconName='arrow-right' className='fs-3' /></button>
                    </Link>

                  </div>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>                </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Advanced Security
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Security
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    XYZ
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    California,USA
                  </span>
                </td>
                <td> <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                  10/01/2024
                </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    5:00 PM to 10:00 PM
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{ width: '70%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link to='/event-details'>
                      <button
                        type='button'
                        className={`btn btn-sm btn-success`}
                      >  Register Now
                        <KTIcon iconName='arrow-right' className='fs-3' /></button>
                    </Link>
                  </div>
                </td>
              </tr>
              <tr>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />     </span>               </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Membership Collobration
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Open Networking
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    XYZ
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Japan
                  </span>
                </td>
                <td> <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                  01/02/2024
                </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    1:00 PM to 5:00 PM
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>60%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{ width: '60%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link to='/event-details'>
                      <button
                        type='button'
                        className={`btn btn-sm btn-success`}
                      >  Register Now
                        <KTIcon iconName='arrow-right' className='fs-3' /></button>
                    </Link>
                  </div>
                </td>
              </tr>
              <tr>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />    </span>                </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Success Meet
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Application Launch
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    The Hill
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>Texas, USA</span>
                </td>
                <td> <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                  12/12/2023
                </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    10:00 AM to 1:00 PM
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{ width: '50%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link to='/event-details'>
                      <button
                        type='button'
                        className={`btn btn-sm btn-success`}
                      >  Register Now
                        <KTIcon iconName='arrow-right' className='fs-3' /></button>
                    </Link>
                  </div>
                </td>
              </tr> */}

            </tbody>

          </table>

        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget9 }
