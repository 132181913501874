import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAuth } from '../../../modules/auth'
import Select from 'react-select';
import {
    ArticlesCreate, Articles_get_data, Articles_delete, Articles_publish_data,
    POST_Articles_Cate, GET_Articles_Cat, POST_Articles_Tags, GET_Articles_Tags, getArticleDetails, Articles_Update
} from "../../../modules/auth/core/_requests"
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

interface ArticlesDatas {
    article_id: string;
    article_title: string;
    article_cover_img: string;
    article_status: string;
    article_created_on: string;
    article_created_by: string;
}

interface EditArticleData {
    article_id: string;
    article_title: string;
    article_cover_img: string;
    article_description: string;
    article_category: [];
    article_visibility: string;
    article_tags: [];
    article_status: string;
    article_created_on: string;
    article_created_by: string;
}

const Articles = () => {
    const { currentUser } = useAuth()
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [eventEditorContent, setEventEditorContent] = useState('');
    const [userfname, setUserFname] = useState(currentUser?.first_name || '')
    const [userlname, setUserLname] = useState(currentUser?.last_name || '')
    const userFullName = `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`;
    const [newTag, setNewTag] = useState('');
    const [TagsAdd, setTagsAdd] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [CategoryAdd, setCategoryAdd] = useState(false);
    const [categoryError, setCategoryError] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [fileName, setFileName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [articlesData, setArticlesData] = useState<ArticlesDatas[]>([])
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [categorySelected, setCategorySelected] = useState<Array<{ value: string; label: string }>>([]);
    const [getCate, setGetCate] = useState<{ article_cat_id: string; article_cat_title: string, article_cat_status: string }[]>([]);
    const [getTags, setGetTags] = useState<{ article_del_id: string; article_tag_title: string, article_tag_status: string }[]>([]);
    const [tagSelected, setTagSelected] = useState<Array<{ value: string; label: string }>>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editArticle, setEditArticle] = useState<EditArticleData[]>([]);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            article_id_upd: '',
            article_title_upd: '',
            article_visibility_upd: '',
            article_description_upd: '',
        }
    )
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredArticles, setFilteredArticles] = useState<ArticlesDatas[]>([])
    const [loadingEdit, setLoadingEdit] = useState(false);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000); // 5000 milliseconds = 5 seconds

            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    // Category Get

    const handleCategory = (selected: any) => {
        setCategorySelected(selected);
        setCategoryAdd(false);
    };

    const GetCategory = async (get_articles_cat: any) => {
        try {
            const response = await GET_Articles_Cat(get_articles_cat);
            setGetCate(response.data.article_cat_details);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetCategory("0");
    }, []);

    const Category_Add = async (values: any) => {
        try {
            const newCategoryValue = values.cate;

            if (!newCategoryValue) {
                setCategoryError('Category name cannot be empty');
                return; // Exit early if the category is empty
            }

            // Check if the category already exists in categorySelected
            const isOptionAlreadySelected = categorySelected.some(option =>
                option.value.toLowerCase() === newCategoryValue
            );

            if (!isOptionAlreadySelected) {
                // Make the API request to add the new category
                const { data: auth } = await POST_Articles_Cate(newCategoryValue);

                // Check if the API request was successful and log the response
                console.log('API Response:', auth);

                if (auth === 'Already Exist') {
                    // Handle the case where the API response indicates that the category already exists
                    setCategoryError('Category already exists. Please select from the dropdown.');
                } else {
                    // Update your component's state with the new category
                    GetCategory("0");
                    setCategorySelected(prevCategorySelected => [
                        ...prevCategorySelected,
                        { value: newCategoryValue, label: values.cate }
                    ]);
                    setNewCategory('');
                    // Clear the error message when a valid value is entered
                    setCategoryError('');
                }
            } else {
                // Notify the user that the category already exists
                setCategoryError('Category already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleTagChange = (selected: any) => {
        setTagSelected(selected);
        setTagsAdd(false);
    };

    const GET_Tags = async (get_articles_tags: any) => {
        try {
            const response = await GET_Articles_Tags(get_articles_tags);
            setGetTags(response.data.article_tag_details);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GET_Tags("0");
    }, []);

    const handleAddOption = async (values: any) => {
        try {
            const newOptionValue = values.tags;

            if (!newOptionValue) {
                setTagsError('Tags name cannot be empty');
                return; // Exit early if the category is empty
            }

            // Check if the category already exists in categorySelected
            const isOptionAlreadySelected = tagSelected.some(option =>
                option.value.toLowerCase() === newOptionValue
            );

            if (!isOptionAlreadySelected) {
                // Make the API request to add the new category
                const { data: auth } = await POST_Articles_Tags(newOptionValue);

                // Check if the API request was successful and log the response
                console.log('API Response:', auth);

                if (auth === 'Already Exist') {
                    // Handle the case where the API response indicates that the category already exists
                    setTagsError('Tags already exists. Please select from the dropdown.');
                } else {
                    // Update your component's state with the new category
                    GET_Tags("0");
                    setTagSelected(prevTagsSelected => [
                        ...prevTagsSelected,
                        { value: newOptionValue, label: values.tags }
                    ]);
                    setNewTag('');

                    // Clear the error message when a valid value is entered
                    setTagsError('');
                }
            } else {
                // Notify the user that the category already exists
                setTagsError('Tags already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditorContent('');
    };

    const handleOpenModel = () => {
        setShowModal(true);
        setEditorContent('');
        setSelectedFile(null);
        setImagePreview(null);
        setFileName('');
        setCategorySelected([]);
        setTagSelected([]);
    };

    var toolbaroptions = [[{ 'header': 1 }, { 'header': 2 }], ['bold', 'italic'], ['link', 'image'], ['underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]]

    const module = {
        toolbar: toolbaroptions
    }

    const initialValues = {
        title: '',
        cover_image: '',
        description: '',
        category: '',
        visibility_settings: '',
        tags: '',
        created_by: userFullName
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        // category: Yup.string().required('category is required'),
        description: Yup.string().required('Description is required').min(30, 'Min 30 characters required'),
        visibility_settings: Yup.string().required('Visibility Settings is required').min(1, 'Select at least one Visibility Setting'),
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setSelectedFile(selectedFile || null);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setImagePreview(null);
        }
    };

    const onSubmit = async (values: any, { resetForm }: any) => {
        setLoading(true)
        const formData = new FormData();
        if (selectedFile) {
            formData.append('cover_image', selectedFile, selectedFile.name);
        }
        const CategoryValues = categorySelected.map(option => option.value);
        formData.append('category', JSON.stringify(CategoryValues));
        const selectedTagValues = tagSelected.map(option => option.value);
        formData.append('tags', JSON.stringify(selectedTagValues));
        formData.append('created_by', userFullName);
        const { data: auth } = await ArticlesCreate(
            formData,
            values.title,
            values.description,
            values.visibility_settings,
        );
        setEditorContent('');
        setSelectedFile(null);
        setImagePreview(null);
        setFileName('');
        setCategorySelected([]);
        setTagSelected([]);
        handleCloseModal();
        resetForm();
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setLoading(false)
            fetchArticlesData("0");
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Submitted',
                showConfirmButton: true,
                timer: 3000
            });
        }, 500)
    };

    const fetchArticlesData = async (get_articles: any) => {
        try {
            const response = await Articles_get_data(get_articles);
            setArticlesData(response.data.all_articles);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    };

    useEffect(() => {
        fetchArticlesData("0");
    }, []);

    const handleUnpublish = async (article_id: any, article_status: string) => {
        try {
            const response = await Articles_publish_data(article_id, article_status);
            const event_status_new = article_status == '0' ? '1' : '0';
            setArticlesData(datas =>
                datas.map(article => article.article_id === article_id ? { ...article, article_status: event_status_new } : article));
        }
        catch (error) { console.error('Error updating event status:', error); }
    };

    const deleteArticle = (article_del_id: string) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await Articles_delete(article_del_id);
                const filterdata = articlesData.filter(
                    (item) => item.article_id !== article_del_id
                );
                setArticlesData(filterdata);
            } catch (error) {
                console.error("Error deleting event:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (article_del_id: string) => {

        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this Articles..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                deleteArticle(article_del_id);
                Swal.fire(
                    'Deleted!',
                    'Your Article has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleEditArticle = async (article_get_id: any) => {
        try {
            setLoadingEdit(true);
            setShowEditModal(true);
            const response = await getArticleDetails(article_get_id);
            setEditArticle(response.data.artical_details);
            const initialData = response.data.artical_details
            setInitialEditValues(
                {
                    article_id_upd: initialData.length > 0 ? initialData[0].article_id : '',
                    article_title_upd: initialData.length > 0 ? initialData[0].article_title : '',
                    article_description_upd: initialData.length > 0 ? initialData[0].article_description : '',
                    article_visibility_upd: initialData.length > 0 ? initialData[0].article_visibility : '',
                }
            )
            setEventEditorContent(response.data.artical_details[0].article_description);
            setImagePreview(response.data.artical_details[0].article_cover_img);
            setSelectedFile(response.data.artical_details[0].article_cover_img.name)
            const CategoryResponse = response.data.artical_details[0].article_category;
            const CategoryFormatArray = JSON.parse(CategoryResponse);
            const formattedArray = CategoryFormatArray.map((item: any) => ({ value: item, label: item }));
            setCategorySelected(formattedArray);
            const TagsResponse = response.data.artical_details[0].article_tags;
            const TagsArray = JSON.parse(TagsResponse)
            const TagsFormattedArray = TagsArray.map((item: any) => ({ value: item, label: item }));
            setTagSelected(TagsFormattedArray)

        } catch (error) {
            console.error('Error fetching events data:', error);
        }
        finally {
            setLoadingEdit(false);
        }
    };

    const handleUpdateArticle = async (values: any, { resetForm }: any) => {
        setLoading(true)
        const formData = new FormData();
        if (selectedFile) {
            formData.append('article_cover_img_upd', selectedFile, selectedFile.name);
        }
        const CategoryValues = categorySelected.map(option => option.value);
        formData.append('article_category_upd', JSON.stringify(CategoryValues));
        const selectedTagValues = tagSelected.map(option => option.value);
        formData.append('article_tags_upd', JSON.stringify(selectedTagValues));
        formData.append('article_last_modified_by_upd', userFullName);
        const { data: auth } = await Articles_Update(
            formData,
            values.article_id_upd,
            values.article_title_upd,
            values.article_description_upd,
            values.article_visibility_upd,
        );
        setEventEditorContent('');
        setSelectedFile(null);
        setImagePreview(null);
        setFileName('');
        setCategorySelected([]);
        setTagSelected([]);
        setShowEditModal(false)
        resetForm();
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setLoading(false)
            fetchArticlesData("0");
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Updated',
                showConfirmButton: true,
                timer: 3000
            });
        }, 500)
    };

    useEffect(() => {
        // Filter getCate based on the search query
        const filtered = articlesData.filter((Category) => {
            const fullName = `${Category.article_title}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredArticles(filtered);
    }, [searchQuery, articlesData]);

    const itemsPerPage = 10;
    const totalItems = filteredArticles.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const ArticlesData = filteredArticles.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    return (
        <>
            <div className="card card-custom p-5 mt-5">
                <h3>Articles</h3>
                <div className='d-flex justify-content-between align-items-center mt-10'>
                    <div className="input-group">
                        <input type="search" className="form-control w-50"
                            value={searchQuery} placeholder='Search Articles...'
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={handleOpenModel}>
                            Create New Article
                        </button>
                    </div>
                </div>
            </div>

            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Article</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="title" className='form-label mb-2'>Title</label>
                                            <Field type="text" name="title" className="form-control" />
                                            <ErrorMessage name="title" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="cover_image">Cover Image</label>
                                            <Field
                                                type="file"
                                                id="cover_image"
                                                name="cover_image"
                                                className="form-control"
                                                onChange={handleFileChange}
                                                accept="image/png, image/gif, image/jpeg"
                                            />
                                            {selectedFile && <p>Selected File: {fileName}</p>}
                                            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}

                                            <ErrorMessage name="cover_image" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description" className='form-label mt-3 mb-2'>Description</label>
                                            <ReactQuill
                                                value={editorContent}
                                                modules={module}
                                                placeholder="Write something..."
                                                // style={{ height: "200px" }}
                                                onChange={(content) => {
                                                    setEditorContent(content);
                                                    setFieldValue('description', content);
                                                }}
                                            // readOnly={true}
                                            />
                                            <ErrorMessage name="description" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <div className='mt-5'>
                                                <label htmlFor="category" className='form-label mt-3 mb-2'>Category</label>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${CategoryAdd ? 'd-none' : ''}`}
                                                    onClick={() => setCategoryAdd(true)} // Set CategoryAdd to true when clicked
                                                >
                                                    <i className="bi bi-plus plus_icon" />Add New
                                                </button>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${CategoryAdd ? '' : 'd-none'}`}
                                                    onClick={() => {
                                                        setCategoryError(''); // Clear the error message
                                                        setCategoryAdd(false); // Close the category add section
                                                    }}
                                                >
                                                    <i className="bi bi-x plus_icon" />Close
                                                </button>
                                            </div>
                                            {CategoryAdd && (
                                                <div className='d-flex'>
                                                    <Field
                                                        type="text"
                                                        value={newCategory}
                                                        name='cate'
                                                        onChange={(e: any) => {
                                                            setNewCategory(e.target.value);
                                                            // Clear the error message when the user starts typing
                                                            setCategoryError('');
                                                        }}
                                                        className="form-control mt-5"
                                                        placeholder='Enter New Category'
                                                    />
                                                    <button
                                                        type='button'
                                                        onClick={() => Category_Add({ cate: newCategory })}
                                                        className="btn btn-primary Add_new_cate_btn"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            )}
                                            {categoryError && <div className="text-danger">{categoryError}</div>}
                                            <Select
                                                isMulti
                                                options={getCate
                                                    .filter(item => item.article_cat_status === "1")
                                                    .map(item => ({
                                                        value: item.article_cat_title,
                                                        label: item.article_cat_title
                                                    }))}
                                                value={categorySelected}
                                                name='category'
                                                placeholder='Select Category'
                                                onChange={(categorySelected) => {
                                                    handleCategory(categorySelected);
                                                    setCategoryError('')
                                                }}
                                                className='w-100  mt-2'
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className='form-label mt-3'>Visibility Settings</label>
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="radio"
                                                    name="visibility_settings"
                                                    value="Public"
                                                    className="form-check-input"
                                                    id="Public"
                                                    checked={values.visibility_settings === 'Public'}
                                                />
                                                <label className="form-check-label" htmlFor="Public">Public</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="visibility_settings"
                                                    value="Private"
                                                    className="form-check-input"
                                                    id="Private"
                                                    checked={values.visibility_settings === 'Private'}
                                                />
                                                <label className="form-check-label" htmlFor="Private">Private</label>
                                            </div>
                                            <ErrorMessage name="visibility_settings" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label htmlFor="Tags" className='form-label mt-3 mb-2'>Tags</label>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${TagsAdd ? 'd-none' : ''}`}
                                                    onClick={() => setTagsAdd(true)} // Set TagsAdd to true when clicked
                                                >
                                                    <i className="bi bi-plus plus_icon" />Add New
                                                </button>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${TagsAdd ? '' : 'd-none'}`}
                                                    onClick={() => {
                                                        setTagsAdd(false)
                                                        setTagsError('');
                                                    }} // Set TagsAdd to false when clicked
                                                >
                                                    <i className="bi bi-x plus_icon" />Close
                                                </button>
                                            </div>
                                            {TagsAdd && (
                                                <div className='d-flex'>
                                                    <Field
                                                        type="text"
                                                        value={newTag}
                                                        name='tags'
                                                        onChange={(e: any) => {
                                                            setNewTag(e.target.value);
                                                            // Clear the error message when the user starts typing
                                                            setTagsError('');
                                                        }}
                                                        className="form-control mt-5"
                                                        placeholder='Enter New Tags'
                                                    />
                                                    <button
                                                        type='button'
                                                        onClick={() => handleAddOption({ tags: newTag })}
                                                        className="btn btn-primary Add_new_tags_btn"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            )}
                                            {tagsError && <div className="text-danger">{tagsError}</div>}
                                            <Select
                                                isMulti
                                                options={getTags
                                                    .filter(item => item.article_tag_status === "1")
                                                    .map(item => ({
                                                        value: item.article_tag_title,
                                                        label: item.article_tag_title
                                                    }))}
                                                value={tagSelected}
                                                name='tags'
                                                placeholder='Select Tags'
                                                onChange={(tagSelected) => {
                                                    handleTagChange(tagSelected);
                                                    setTagsError('')
                                                }}
                                                className='w-100  mt-2'
                                            />
                                        </div>

                                        <div className="form-group">
                                            <Field type="hidden" name="created_by" className="form-control" />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                                Cancel
                                            </button>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Article</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {loadingEdit ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                <Formik
                                    initialValues={initialEditValues}
                                    // validationSchema={validationSchema}
                                    onSubmit={handleUpdateArticle}>
                                    {({ setFieldValue }) => (
                                        <Form>
                                            <div className="form-group">
                                                <input type="hidden" name="article_id_upd" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="title" className='form-label mb-2'>Title</label>
                                                <Field type="text" name="article_title_upd" className="form-control" />
                                                <ErrorMessage name="title" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="cover_image">Cover Image</label>
                                                <Field
                                                    type="file"
                                                    id="cover_image"
                                                    name="cover_image"
                                                    className="form-control"
                                                    onChange={handleFileChange}
                                                    accept="image/png, image/gif, image/jpeg"
                                                />
                                                {selectedFile && <p>Selected File: {fileName}</p>}
                                                {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}
                                                <ErrorMessage name="cover_image" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="article_description_upd" className='form-label mt-3 mb-2'>Description</label>
                                                <ReactQuill
                                                    value={eventEditorContent}
                                                    modules={module}
                                                    // style={{ height: "200px" }}
                                                    onChange={(content) => {
                                                        setEventEditorContent(content);
                                                        setFieldValue('article_description_upd', content);
                                                    }}
                                                />
                                                <ErrorMessage name="description" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <div className='mt-5'>
                                                    <label htmlFor="category" className='form-label mt-3 mb-2'>Category</label>
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${CategoryAdd ? 'd-none' : ''}`}
                                                        onClick={() => setCategoryAdd(true)} // Set CategoryAdd to true when clicked
                                                    >
                                                        <i className="bi bi-plus plus_icon" />Add New
                                                    </button>
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${CategoryAdd ? '' : 'd-none'}`}
                                                        onClick={() => {
                                                            setCategoryError(''); // Clear the error message
                                                            setCategoryAdd(false); // Close the category add section
                                                        }}
                                                    >
                                                        <i className="bi bi-x plus_icon" />Close
                                                    </button>
                                                </div>
                                                {CategoryAdd && (
                                                    <div className='d-flex'>
                                                        <Field
                                                            type="text"
                                                            value={newCategory}
                                                            name='cate'
                                                            onChange={(e: any) => {
                                                                setNewCategory(e.target.value);
                                                                // Clear the error message when the user starts typing
                                                                setCategoryError('');
                                                            }}
                                                            className="form-control mt-5"
                                                            placeholder='Enter New Category'
                                                        />
                                                        <button
                                                            type='button'
                                                            onClick={() => Category_Add({ cate: newCategory })}
                                                            className="btn btn-primary Add_new_cate_btn"
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                )}
                                                {categoryError && <div className="text-danger">{categoryError}</div>}
                                                <Select
                                                    isMulti
                                                    options={getCate
                                                        .filter(item => item.article_cat_status === "1")
                                                        .map(item => ({
                                                            value: item.article_cat_title,
                                                            label: item.article_cat_title
                                                        }))}
                                                    value={categorySelected}
                                                    name='category'
                                                    placeholder='Select Category'
                                                    onChange={(categorySelected) => {
                                                        handleCategory(categorySelected);
                                                        setCategoryError('')
                                                    }}
                                                    className='w-100  mt-2'
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className='form-label mt-3'>Visibility Settings</label>
                                                <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                    <Field
                                                        type="radio"
                                                        name="article_visibility_upd"
                                                        className="form-check-input"
                                                        id="Public"
                                                        value="Public"
                                                    />
                                                    <label className="form-check-label" htmlFor="Public">Public</label>
                                                </div>
                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                    <Field
                                                        type="radio"
                                                        name="article_visibility_upd"
                                                        value="Private"
                                                        className="form-check-input"
                                                        id="Private"
                                                    />
                                                    <label className="form-check-label" htmlFor="Private">Private</label>
                                                </div>
                                                <ErrorMessage name="visibility_settings" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <div>
                                                    <label htmlFor="Tags" className='form-label mt-3 mb-2'>Tags</label>
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${TagsAdd ? 'd-none' : ''}`}
                                                        onClick={() => setTagsAdd(true)} // Set TagsAdd to true when clicked
                                                    >
                                                        <i className="bi bi-plus plus_icon" />Add New
                                                    </button>
                                                    <button type='button'
                                                        className={`Plus_icon_btn ${TagsAdd ? '' : 'd-none'}`}
                                                        onClick={() => {
                                                            setTagsAdd(false)
                                                            setTagsError('');
                                                        }} // Set TagsAdd to false when clicked
                                                    >
                                                        <i className="bi bi-x plus_icon" />Close
                                                    </button>
                                                </div>
                                                {TagsAdd && (
                                                    <div className='d-flex'>
                                                        <Field
                                                            type="text"
                                                            value={newTag}
                                                            name='tags'
                                                            onChange={(e: any) => {
                                                                setNewTag(e.target.value);
                                                                // Clear the error message when the user starts typing
                                                                setTagsError('');
                                                            }}
                                                            className="form-control mt-5"
                                                            placeholder='Enter New Tags'
                                                        />
                                                        <button
                                                            type='button'
                                                            onClick={() => handleAddOption({ tags: newTag })}
                                                            className="btn btn-primary Add_new_tags_btn"
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                )}
                                                {tagsError && <div className="text-danger">{tagsError}</div>}
                                                <Select
                                                    isMulti
                                                    options={getTags
                                                        .filter(item => item.article_tag_status === "1")
                                                        .map(item => ({
                                                            value: item.article_tag_title,
                                                            label: item.article_tag_title
                                                        }))}
                                                    value={tagSelected}
                                                    name='tags'
                                                    placeholder='Select Tags'
                                                    onChange={(tagSelected) => {
                                                        handleTagChange(tagSelected);
                                                        setTagsError('')
                                                    }}
                                                    className='w-100  mt-2'
                                                />
                                            </div>

                                            <div className="form-group">
                                                <Field type="hidden" name="created_by" className="form-control" />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>
                                                    Cancel
                                                </button>
                                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                        {!loading && 'Save Changes'}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            )}

            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (ArticlesData.length > 0 ? (
                <div className="table-responsive mt-4">
                    <div className="card card-custom mt-4">
                        <div className="card-body">
                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Created On</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ArticlesData.map((item) => (
                                        <tr key={item?.article_id}>
                                            <td>
                                                {item?.article_cover_img && (
                                                    <img src={`${item?.article_cover_img}`} alt="Image"
                                                        style={{ borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px', objectFit: 'cover' }} />
                                                )}
                                            </td>
                                            <td>{item?.article_title}</td>
                                            <td>{item?.article_created_on}</td>
                                            <td>{item?.article_created_by}</td>
                                            <td>
                                                {item.article_status === "0" ? (
                                                    <span className="badge bg-dark text-white">Unpublished</span>
                                                ) : item.article_status === "1" ? (
                                                    <span className="badge bg-success text-white">Published</span>
                                                ) : null}
                                            </td>
                                            <td style={{ paddingTop: "10px" }}>
                                                <div className="dropdown">
                                                    <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-three-dots fs-3"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleEditArticle(item.article_id)}
                                                            >
                                                                Edit
                                                            </button>
                                                        </li>
                                                        <li><button className="dropdown-item" type="button" onClick={() => handleDeleteConfirmation(item.article_id)}>Delete</button></li>
                                                        <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(item.article_id, item.article_status)}>{item.article_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            ) :
                <div className="card card-custom mt-5">
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No articles found</h3>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Articles