import React, { useEffect, useState } from 'react';

export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  contactEmail: string
  companySite: string
  country: string
  country_zipcode: string
  country_city: string
  country_state: string

  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
  user_bio:string
}

export interface ProfileDetailsCreate {
  avatar: string,
   fName: string,
  lName: string,
  company: string,
  country_city: string,
  country_state: string,
  contactPhone: string,
  contactEmail: string,
  companySite: string,
  country: string,
  country_zipcode: string,
  language: string,
  timeZone: string,
  currency: string,
  communications: { email: boolean,phone: boolean },
  allowMarketing: boolean
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
  facebook: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}


export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: '',
  lName: '',
  company: '',
  contactPhone: '',
  contactEmail: '',
  companySite: '',
  country: '',
  country_zipcode: '',
  country_state: '',
  country_city: '',

  language: 'EN',
  timeZone: 'Chennai',
  currency: 'USD',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
  user_bio:'',
}



export const updateEmail: IUpdateEmail = {
  newEmail: '',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
  facebook: true,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}

export const profileCreateInitValues: ProfileDetailsCreate = {
  avatar: '/media/avatars/300-1.jpg',
  fName: '',
  lName: '',
  company: '',
  contactPhone: '',
  contactEmail: '',
  companySite: '',
  country: '',
  country_city: '',
  country_state: '',
  country_zipcode: '',
  language: 'EN',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}