import React, { useState, useEffect, useRef } from "react";
import { Card3 } from "../../_metronic/partials/content/cards/Card3";
import { getMembers,getMembersProfile } from "../modules/auth/core/_requests";
import { KTIcon, toAbsoluteUrl,toAbsoluteUrlImage } from '../../_metronic/helpers';
import MemberBio from './Member_bio';
interface Member {
	first_name: string;
	last_name: string;
	company: string;
	role: string;
	id: number;
	user_pic: string;
	// Add other properties as needed
}
interface MemberData {
	m_id: number;
	m_first_name: string;
	m_last_name: string;
	m_position: string;
	m_description: string;
	m_location: string;
	m_time: string;
	m_role: string;
	m_company: string;
	m_user_pic: string;
	m_bio: string;
	m_timezone: string;
	m_state: string;
	m_twitter_link:string;
	m_linked_link:string;
	m_fb_link:string;
	m_insta_link:string;
}
const Members: React.FC = () => {
	const [membersData, setMembersData] = useState<Member[]>([]);
	const [membersProfile, setMembersProfile] = useState<MemberData[]>([]);
	const [totalEntries, setTotalEntries] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const limit = 12; // Number of records to fetch per page
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const [isFetching, setIsFetching] = useState(false); // For handling on-scroll data append
	const observer = useRef<IntersectionObserver | null>(null);
	const lastItemRef = useRef<HTMLDivElement | null>(null);
	const handleScroll = () => {
		const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
		const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100; // Add a buffer of 20 pixels
		if (isAtBottom) {
			setIsFetching(true);
			setCurrentPage((prevPage) => prevPage + 1);
			//console.log(currentPage,'current_page');
		}
		// if (isAtBottom && !isFetching && currentPage < totalPages) {
		//   // Reached the bottom of the page, and there's    data to fetch
		//   setIsFetching(true);
		//   setCurrentPage((prevPage) => prevPage + 1);
		//   console.log(currentPage,'current_page');
		// }
	};
	useEffect(() => {
		//console.log(currentPage, 'current_page');
		fetchData();
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [currentPage]);
	useEffect(() => {
		// Set up the Intersection Observer when the component mounts
		observer.current = new IntersectionObserver(handleObserver, {
			threshold: 1.0,
		});
		if (lastItemRef.current) {
			//console.log("Observing last item");
			observer.current.observe(lastItemRef.current);
		}
		return () => {
			// Cleanup the Intersection Observer when the component unmounts
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, [lastItemRef]); // Make sure to include lastItemRef as a dependency here
	const handleObserver: IntersectionObserverCallback = (entries) => {
		const target = entries[0];
		if (target.isIntersecting && !isFetching && currentPage < totalPages) {
			setIsFetching(true);
			setCurrentPage((prevPage) => prevPage + 1);
			//console.log("Fetching more data...");
		}
	};
	const fetchData = async () => {
		try {
			const offset = (currentPage - 1) * limit;
      const searchTerm = "";
			const response = await getMembers('get_members', offset, limit,searchTerm);
			const { data } = response;
			setMembersData((prevData) => [...prevData, ...data.members]);
			setTotalEntries(data.total_record); // Update total entries with the new value from API
			setIsLoading(false);
			setIsFetching(false);
			} catch (error) {
			console.error('Error fetching data:', error);
			setIsLoading(false);
			setIsFetching(false);
		}
	};
	const handleGetUser = (id: number) => async () => {
		try {
			const response = await getMembersProfile(id)
			const userData = response.data;
			setMembersProfile(userData.members_profile);
			} catch (error) {
			console.error('Error fetching user data:', error);
			setMembersProfile([]);
		}
	};
	const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const Updateoffset = 0;
    const newSearchTerm = event.target.value;
    //setIsLoading(true);
    setSearchQuery(newSearchTerm);
    setCurrentPage(1);
    const searchTerm = event.target.value; // Capture the search term from the input field
    const response = await getMembers('get_members', Updateoffset, limit, newSearchTerm);
    const { data } = response;

    setMembersData(data.members);
    setTotalEntries(data.total_record); // Update total entries with the new value from API

  };
	// Calculate the total number of pages based on the limit and totalEntries
	const totalPages = Math.ceil(totalEntries / limit);
	// console.log("Total Pages:", totalPages);
	// Filter members based on search query
	// const filteredMembers = membersData.filter(
	// 	(member) =>
	// 	member.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
	// 	member.last_name.toLowerCase().includes(searchQuery.toLowerCase())
	// );
	return (
		<>
		<div className="d-flex flex-wrap flex-stack mb-6">
        <h3 className="fw-bolder my-2">
		Community Members
		<span className="fs-6 text-gray-400 fw-bold ms-1">
		{/* ({totalEntries}) */}
		</span>
        </h3>
        <input
		type="text"
		className="form-control form-select-sm w-250px d-flex align-items-start d-flex my-2"
		placeholder="Search Member"
		value={searchQuery}
		onChange={handleSearchChange}
        />
		</div>
		<div className="row g-6 g-xl-9">
        {isLoading && (
			<span className="indicator-progres">
            Please Wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
			</span>
		)}
        {membersData.length > 0 ? (
			membersData.map((member, index) => (
				<div
				key={index}
				ref={index === membersData.length - 1 ? lastItemRef : null}
				className="col-md-6 col-xxl-3"
				onClick={handleGetUser(member.id)}
				data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
				>
				<Card3
                avatar= {member.user_pic}
                firstname={member.first_name}
                lastname={member.last_name}
                job={member.role}
                company={member.company}
                avgEarnings=""
                totalEarnings=""
                follow="Follow"
				/>
				</div>
			))
			) : !isLoading && (
			<p>No Member found</p>
		)}
        {/* Display loading indicator while fetching next page */}
        { !isLoading && (
			isFetching && <p>Loading more data...</p>
		)
        }
		</div>
		<div className={`offcanvas offcanvas-end`} tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
		<div className="offcanvas-header border-bottom">
		<h5 id="offcanvasRightLabel">Member Profile</h5>
		<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div className="offcanvas-body">
		{membersProfile.map((MemberData) => (
			<React.Fragment key={MemberData.m_id}>
			<div className='d-flex align-items-center mb-7'>
            {/* Display the member profile data here */}
            <div className='symbol symbol-circle symbol-100px me-5'>
            <img src={MemberData.m_user_pic ? toAbsoluteUrlImage(MemberData.m_user_pic)  : toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
            </div>
            <div className='flex-grow-1'>
			<h4 className='text-dark fw-bold text-hover-primary'>
			{/* Content for member profile */}
			{MemberData.m_first_name} {MemberData.m_last_name}
			</h4>
			<span className='text-muted d-block fw-semibold'>{MemberData.m_role}</span>
			<div className="socail_link mt-2">
			{MemberData.m_linked_link ? (
				<a href={MemberData.m_linked_link} className='text-muted d-block fw-semibold social bi bi-linkedin fs-2' target="_new"></a>
				) : (
				''
			)}
			</div>
            </div>
			</div>
			<div className="members_detail">
            {/* Content for member details */}
            <h5>{MemberData.m_role ? MemberData.m_role + ' of ' + MemberData.m_company : ''}</h5>
            <MemberBio bio={MemberData.m_bio} />
            <span className="fs-5"></span> <br />
            <span className="fs-5">{MemberData.m_location}{MemberData.m_state?',':''}  {MemberData.m_state} </span>
			</div>
			</React.Fragment>
		))}
		</div>
		</div>
		</>
	);
};
export { Members };