import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {MyPage} from "../pages/MyPage"
import {Courses} from "../pages/UserCourses"
import {Members} from "../pages/Members"
import {UserEvents} from "../pages/UserEvents"
import {MemberPosts} from "../pages/MemberPosts"
import {UserEventDetails} from "../pages/EventDetails"
import AdminPanel from '../pages/AdminPanel'
import { Channels } from '../pages/Channels'
import { ChannelIndividual } from '../pages/Channel'
import { Articles } from '../pages/Articles'
import { ArticleDetails } from '../pages/ArticleDetails'
import Messages from '../pages/Messages'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage    = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage   = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path="/my-courses" element={<Courses />} />
        <Route path="/my-page" element={<MyPage />} />
        <Route path="/my-courses" element={<Courses />} />
        <Route path="/members" element={<Members />} />
        <Route path="/events" element={<UserEvents />} />
        <Route path="/posts" element={<MemberPosts />} />
        <Route path="/channels" element={<Channels />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/chat" element={<Messages />} />

        <Route path="/channel/:channel_id" element={<ChannelIndividual />} />
        <Route path="/event-details/:event_details_id/" element={<UserEventDetails />} />
                <Route path="/article/:article_details_id/" element={<ArticleDetails />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
         <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
              <MemberPosts />
            </SuspensedView>
          }
        />
         <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
               <UserEvents />
            </SuspensedView>
          }
        />
         <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
               <UserEventDetails />
            </SuspensedView>
          }
        />
         <Route
          path='/admin-panel/*'
          element={
            <SuspensedView>
               <AdminPanel />
            </SuspensedView>
          }
        />
           <Route
          path='apps/Courses/*'
          element={
            <SuspensedView>
              <Courses />
            </SuspensedView>
          }
        />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
