import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import { Link } from 'react-router-dom';
import { KTSVG, KTIcon, toAbsoluteUrlImage } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { FeedPost, getFeed, channels, deleteFeed, commentFeed, feedLike, feedCommentDelete,feedCommandReply, feedReplyCommentDelete } from '../../app/modules/auth/core/_requests'
import ReadMore from "./ReadMore";
import CommentTimeDifference from "./time";

interface Image {
  file: File;
  preview: string;
}

interface FormValues {
  post_content: string;
}

// Validation function
const validateForm = (values: FormValues) => {
  const errors: Partial<FormValues> = {};

  if (!values.post_content) {
    errors.post_content = "Post content is required";
  }
  return errors;
};

interface ChannelData {
  channel_id: string;
  channel_name: string;
  channel_image: string;
  total_members: string;
  channel_status: string;
  created_on: string;
}

interface FeedMedia {
  feed_media_url: string;
}

interface Feedcomments {
  fc_comments: string;
  fc_id: string;
  fc_feed_id :string;
  fc_submitted_on: string;
  fc_user_name: string;
  fc_user_pic: string;
  fc_user_id: number;
  feed_replies: FeedCommentsReply[]; // Include feed_reply data here
}
interface FeedCommentsReply {
  fcr_comments: string;
  fcr_id: string;
  fcr_submitted_on: string;
  fcr_user_name: string;
  fcr_user_pic: string;
  fcr_user_id: number;
  fcr_reply_id:number;
}
interface FeedLikes {
  fl_user_id: number;
}


interface Feed {
  feed_id: string;
  feed_data: string;
  posted_by: string;
  posted_pic: string;
  posted_on: string;
  like_count: string;
  comment_count: string;
  feed_member_id: number;
  feed_media: FeedMedia[];
  feed_comments: Feedcomments[];
  feed_likes: FeedLikes[];
}

export function MemberPosts() {
  const { currentUser, logout } = useAuth()
  const [userpic, setUserPic] = useState(currentUser?.pic || '')
  const [images, setImages] = useState<Image[]>([]);
  const [username, setUsername] = useState(currentUser?.first_name + ' ' + currentUser?.last_name || '')
  const [userid, setUserId] = useState(currentUser?.id as number) || 0
  const [showModal, setShowModal] = useState(false);
  const [showClose, setShowModalClose] = useState({ display: 'none' });
  const [userfirst, setUserfirst] = useState(currentUser?.first_name || '')
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChannel, setIsLoadingChannel] = useState(true);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
  const [channelDataArray, setChannelDataArray] = useState<ChannelData[]>([]);
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarContent, setsnackbarContent] = useState('');
  const [isAdmin, setIsAdmin] = useState(currentUser?.admin)
  const [channel_id, setchannel_id] = useState('0')
  interface CommentStates {
    [key: string]: string;
  }

  interface InnerCommentStates {
    [key: string]: string;
  }


  const [commentStates, setCommentStates] = useState<CommentStates>({});
  const [innercommentStates, setinnerCommentStates] = useState<InnerCommentStates>({});
  const fetchData = useCallback(async (get_all: string) => {

    try {
      // Perform an asynchronous API call using Axios
      const response = await getFeed(get_all);
      setFeeds(response.data);
      setIsLoading(false);

     // console.log(response.data);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
      setFeeds([]);
    }
  }, []);

  useEffect(() => {
    // Call fetchData with 'get_all' value to fetch initial data
    fetchData('0');

  }, []);

  const ChannelData = async (get_channel: string): Promise<any> => {
    try {
      // Perform an asynchronous API call using Axios
      const response = await channels(get_channel);
      //console.log(response); // Check the response in the console
      setChannelDataArray(response.data.channel_datas);
      setIsLoadingChannel(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error fetching user data:', error);
    }
  }
  // useEffect(() => {
  //   ChannelData(channel_id ?? '')
  //     .then(response => {
  //       //console.log(response);
  //       setChannelDataArray(response.data.channel_datas);
  //     })
  //     .catch(error => {
  //       // Handle the error here or show an error message to the user
  //     });
  // }, []);

  //console.log(username);
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files) as File[];
      const selectedImages = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setImages((prevImages) => [...prevImages, ...selectedImages]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    if (submitting) {
      // Form is already being submitted, do nothing
      return;
    }
    setSubmitting(true);
    //console.log(values);
    const formData = new FormData();

    images.forEach((image) => {
      formData.append('feed_images[]', image.file);
    });

    const { data } = await FeedPost(
      formData,
      values.post_content,
      username,
      userid,
      channel_id
    );

    // Send the images to the server (you need to implement the API call)

    // Clear the file input after form submission
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }

    // Set submitting to false when form submission is done
    setSubmitting(false);

    fetchData('get_all');
    resetForm();
    setImages([]);
    setIsSuccessAlertVisible(true);

    setTimeout(() => {
      setShowModal(false);
      setShowModalClose({ display: 'none' });

      // console.log('After setting showModal to false:', showModal);

      setIsSuccessAlertVisible(false);
    }, 1500);



  }, [submitting, images, username, userid]);

  const handleFeedDelete = (feed_id: string) => {
    const handleFeedPost = async () => {
      try {
        const response = await deleteFeed(feed_id);
        const updatedFeeds = feeds.filter(feed => feed.feed_id !== feed_id);
        setFeeds(updatedFeeds);

        setsnackbarContent('Successfully Deleted');
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);

      } catch (error) {
      }
    };

    handleFeedPost();

  };

  // Function to update the comment state for a specific feed item
  const updateCommentState = (feedId: string, comment: string): void => {
    setCommentStates((prevCommentStates) => ({
      ...prevCommentStates,
      [feedId]: comment,
    }));
  };


    // Function to update the comment state for a specific feed item
    const innerupdateCommentState = (feedId: string, comment: string): void => {
      setinnerCommentStates((prevCommentStates) => ({
        ...prevCommentStates,
        [feedId]: comment,
      }));
    };

  // Assuming you have a handleCommentSubmit function defined somewhere
  const handleCommentSubmit = (feedId: string, comment: string): void => {
    // Implement your comment submission logic here
    const trimmedComment = comment.trim();

    // Check if the trimmed comment is not empty
    if (trimmedComment !== '') {
      const handlenewComment = async () => {
        try {
          const response = await commentFeed(feedId, comment, userid);
          fetchData('0');
          updateCommentState(feedId, '');
        } catch (error) {
        }
      };
      handlenewComment();
    }
    else {
      updateCommentState(feedId, '');
    }
  };


  const handleFeedLike = (feedId: any, status: any) => {
    const sendLiketoServer = async () => {
      try {
        const response = await feedLike(feedId, userid, status);
        setsnackbarContent('Success');
        fetchData('0');
      } catch (error) {
      }
    };
    sendLiketoServer();
  };


  const openModal = () => {
    setShowModal(true);
    setShowModalClose({ display: 'block' });
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalClose({ display: 'none' });
  };

  const delete_comment = (fc_id: any, feedId: any) => {
    const deleteCommenttoServer = async () => {
      try {
        const response = await feedCommentDelete(fc_id, userid, feedId);
        const updatedFeeds = feeds.map(feed => {
          if (feed.feed_comments && feed.feed_comments.length > 0) {
            const updatedFeedComments = feed.feed_comments.filter(comment => comment.fc_id !== fc_id);
            return { ...feed, feed_comments: updatedFeedComments };
          }
          return feed;
        });
        setFeeds(updatedFeeds);
      } catch (error) {
      }
    };
    deleteCommenttoServer();
  }

  const deleteReplyComment = (fcr_id: any, fc_id: any) => {
    const deleteCommenttoServer = async () => {
      try {
        const response = await feedReplyCommentDelete(fcr_id, userid, fc_id);
        // Filter out comments based on fc_id

    // Filter out comments based on fcr_id
    const updatedFeeds = feeds.map(feed => {
      if (feed.feed_comments && feed.feed_comments.length > 0) {
        const updatedFeedComments = feed.feed_comments.map(comment => {
          // Filter out replies based on fcr_id
          const updatedReplies = comment.feed_replies.filter(reply => reply.fcr_reply_id !== fcr_id);
          return { ...comment, feed_replies: updatedReplies };
        });
        return { ...feed, feed_comments: updatedFeedComments };
      }
      return feed;
    });

    setFeeds(updatedFeeds);

      } catch (error) {
      }
    };
    deleteCommenttoServer();
  }

   // State to track which comment is being replied to
   const [replyingComment, setComment] = useState<string | null>(null);

   // Function to toggle the reply textbox
   const toggleComment = (commentId: string) => {
     if (replyingComment === commentId) {
      setComment(null); // Hide the reply textbox
     } else {
      setComment(commentId); // Show the reply textbox for the selected comment
     }
   };



  // State to track which comment is being replied to
  const [replyingToComment, setReplyingToComment] = useState<string | null>(null);

  // Function to toggle the reply textbox
  const toggleReply = (commentId: string) => {

    if (replyingToComment === commentId) {
      setReplyingToComment(null); // Hide the reply textbox
    } else {

      setReplyingToComment(commentId);

    }
  };


   // State to track which comment is being replied to
   const [innerreplyingToComment, setinnerReplyingToComment] = useState<any | null>(null);
   // Function to toggle the reply textbox
   const toggleinnerReply = (commentId: any) => {
    //alert(commentId);
     if (innerreplyingToComment === commentId) {
      setinnerReplyingToComment(null); // Hide the reply textbox
     } else {
      setinnerReplyingToComment(commentId);
     }
   };

   // Function to handle the "Send" button click
   const handleReplyInnerComment = (commentId: string,commentName:string): void => {
    const handleNewInnerReply = async () => {
    const replyComment = commentStates[commentId].trim();
    // Check if the trimmed comment is not empty
    if (replyComment !== '') {
      const fullComment = `@<span class='replyingTo'>${commentName}</span>, ${replyComment}`; // Append commentName to the replyComment

      const response = await feedCommandReply(commentId, userid, fullComment);
      if(response)
      {

        innerupdateCommentState(commentId, '');
        setinnerReplyingToComment(null);
         fetchData('0');
         //console.log(`Sending reply for comment ${commentId}: ${replyComment}`);
      }
    }
    else {
      innerupdateCommentState(commentId, '');
    }
  }
  handleNewInnerReply();
  };



   // Function to handle the "Send" button click
   const handleReplyComment = (commentId: string,commentName:string): void => {
    const handleNewReply = async () => {
    const replyComment = commentStates[commentId].trim();
    // Check if the trimmed comment is not empty
    if (replyComment !== '') {
      const fullComment = `@<span class='replyingTo'>${commentName}</span>, ${replyComment}`; // Append commentName to the replyComment

      const response = await feedCommandReply(commentId, userid, fullComment);
      if(response)
      {

         updateCommentState(commentId, '');
         setReplyingToComment(null);
         fetchData('0');
         //console.log(`Sending reply for comment ${commentId}: ${replyComment}`);
      }
    }
    else {
      updateCommentState(commentId, '');
    }
  }
  handleNewReply();
  };

  return (
    <>
      <div className="modal fade" tabIndex={-1} id="post_liked_list">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header h-50px">
              <h5 className="modal-title">    <i className="bi bi-hand-thumbs-up-fill fs-5 text-primary"></i> 7 Likes </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex-grow-1 mb-2"><div className="symbol symbol-45px me-5 symbol-circle"><img src="https://digitalfactory.co.in/lc_app_test/api/avatars/LC_avatars_9400353_Screenshot 2023-07-20 at 9.21.13 AM.png" alt="sdsd" /></div><div className="d-flex flex-column"><span className="text-gray-800 text-hover-primary fs-6 fw-bold">Shilpi Padhy</span></div></div>

            </div>

          </div>
        </div>
      </div>
      <div id="snackbar" className={showSnackbar ? 'show' : ''}>
        {snackbarContent}
      </div>
      {/* Same as */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex={-1} id="lc_post_popup" style={showClose} aria-labelledby="lc_post_popup" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title text-center">Create Post</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <Formik
              initialValues={{
                post_content: "",
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >

              <Form>
                <div className="modal-body">

                  <div className='d-flex align-items-center mb-5'>
                    <div className='d-flex align-items-top flex-grow-1'>
                      <div className='symbol symbol-50px symbol-circle symbol-lg-50px me-2'>
                        {(userpic !== '') ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                        <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
                      </div>
                      <div className="d-flex flex-grow-1">
                        <Field
                          as="textarea"
                          name="post_content"
                          className="form-control border-1 h-150px w-100 mb-2"
                          placeholder={`Hi ${userfirst} ! What's on your mind today?`}
                          style={{ resize: "both" }}
                        />
                      </div>

                      {/* end::Info */}
                    </div>
                    {/* end::User */}
                  </div>
                  <ErrorMessage
                    name="post_content"
                    component="div"
                    className="text-danger"
                  />
                  <div className="image-preview">
                    {images.map((image, index) => (
                      <div key={index} className="preview-item">
                        <img src={image.preview} alt={`Preview ${index}`} />
                        <button onClick={() => handleRemoveImage(index)}><span className="post_img_close">x</span></button>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end mb-5 w-100">
                    <div>
                      <div className="full-width-container d-flex align-items-center border rounded p-2">
                        <span className="me-20 ms-5">Add to your post</span>
                        <label className="file-attachment">
                          <i className="bi bi-images fs-2"></i>
                          <input type="file" multiple onChange={handleImageChange} accept="image/png,image/jpeg" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  {/* Alert for Success */}
                  {isSuccessAlertVisible && (
                    <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                      <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                        {/* Add your SVG icon here */}
                      </span>
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">Successfully Posted</h5>
                      </div>
                    </div>
                  )}
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button
                      className="btn btn-success"
                      type="submit"
                      disabled={submitting}
                    >
                      {submitting ? "Posting..." : "Post"}
                    </button>
                  </div>
                </div>
              </Form>

            </Formik>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-9 ps-xl-20 '>
          {/* begin::Body */}
            <div className="card mb-5 mb-xl-8">
              <div className='card-body pb-0 d-flex flex-column'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::User */}
                  <div className='d-flex align-items-top flex-grow-1'>
                    {/* begin::Avatar */}
                    <div className='symbol symbol-50px symbol-circle symbol-lg-50px me-2'>
                      {(userpic !== '') ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                      <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
                    </div>
                    {/* end::Avatar */}

                    {/* begin::Info */}
                    <div className="d-flex flex-grow-1">
                      <textarea className="form-control h-100px w-100 mb-2"
                        placeholder={`Hi ${userfirst} ! What's on your mind today?`}
                        onClick={openModal}
                        readOnly></textarea>
                    </div>
                    {/* end::Info */}
                  </div>
                  {/* end::User */}
                </div>
                {/* end::Header */}
              </div>
            </div>

          {feeds.length > 0 ?
            feeds.map((feed) => (
              <div key={feed.feed_id}>
                <div className={`card mb-5`}>
                  {/* begin::Body */}
                  <div className='card-body pb-0'>
                    {/* begin::Header */}
                    <div className='d-flex align-items-center mb-3'>
                      {/* begin::User */}
                      <div className='d-flex align-items-center flex-grow-1'>
                        {/* begin::Avatar */}
                        <div className='symbol symbol-45px me-5 symbol-circle'>
                          <img src={feed.posted_pic} alt='' />
                        </div>
                        {/* end::Avatar */}

                        {/* begin::Info */}
                        <div className='d-flex flex-column'>
                          <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                            {feed.posted_by}
                          </span>
                          <small className='text-gray-400 fw-semibold'>Posted On: {feed.posted_on}</small>
                        </div>
                        {/* end::Info */}
                      </div>
                      {/* end::User */}

                      {/* begin::Menu */}

                      {isAdmin === 'Admin' || feed.feed_member_id === userid ? (
                        <div className='my-0'>


                          <div className="btn-group">
                            <button type="button" className="btn btn-sm btn-icon btn-color-grey btn-active-light-success" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor" />
                                <rect x="10" y="3" width="4" height="4" rx="2" fill="currentColor" />
                                <rect x="10" y="17" width="4" height="4" rx="2" fill="currentColor" />
                              </svg>  </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end">
                              <li><button className="dropdown-item" type="button" onClick={() => handleFeedDelete(feed.feed_id)}>Delete Post</button></li>
                            </ul>
                          </div>


                        </div>
                      ) : ''}
                      {/* end::Menu */}
                    </div>
                    {/* end::Header */}

                    {/* begin::Post */}
                    <div className='mb-7'>
                      {/* begin::Text */}
                      <div className='text-gray-800 mb-5'>
                        <div className="post_img">
                          <div className="masonry-container">
                            {feed.feed_media.map((media, index) => (
                              <div key={index} className={`masonry-item ${index === 0 ? 'full-width' : ''}`}>
                                <img src={media.feed_media_url} alt={`Media ${index + 1}`} />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="post_title mt-5">
                          <ReadMore readmore={feed.feed_data} />
                        </div>
                      </div>
                      {/* end::Text */}
                      {/* begin::Toolbar */}
                      <div className='d-flex align-items-center mt-3'>
                        {/* <span
                          className='px-2 py-2 me-auto text-gray-500 likes_count'
                          data-bs-toggle="modal"
                          data-bs-target="#post_liked_list"
                        > */}
                        <span className='px-2 py-2 me-auto text-gray-500 likes_count'>
                          {feed.like_count}   {feed.like_count == '1' ? 'Like' : 'Likes'}
                        </span>
                        <span
                          className='px-4 py-2 text-gray-500 comment_count' onClick={() => toggleComment(feed.feed_id)}>
                          {feed.comment_count}   {feed.comment_count == '1' ? 'Comment' : 'Comments'}
                        </span>
                      </div>
                      <div className='separator'></div>
                      <div className='d-flex align-items-center mb-5 mt-3'>
                        {feed.feed_likes.length === 0 ? (
                          <span
                            className='btn btn-sm btn-light btn-active-light-success px-4 py-2 me-4'
                            onClick={() => handleFeedLike(feed.feed_id, 1)}>
                            <i className="bi bi-hand-thumbs-up fs-5"></i>
                            Like
                          </span>
                        ) : (
                          <div>
                            {feed.feed_likes.some(liked_user => liked_user.fl_user_id === userid) ? (
                              <span
                                className='btn btn-sm btn-light btn-active-light-primary px-4 py-2 me-4'
                                onClick={() => handleFeedLike(feed.feed_id, -1)}>
                                <i className="bi bi-hand-thumbs-up-fill fs-5 text-primary"></i>
                                Like
                              </span>
                            ) : (
                              <span
                                className='btn btn-sm btn-light btn-active-light-success px-4 py-2 me-4'
                                onClick={() => handleFeedLike(feed.feed_id, 1)}>
                                <i className="bi bi-hand-thumbs-up fs-5"></i>
                                Like
                              </span>
                            )}
                          </div>

                        )}
                        <span className='btn btn-sm btn-light  btn-active-light-success px-4 py-2' onClick={() => toggleComment(feed.feed_id)}>
                          <i className="bi bi-chat-right-dots fs-5"></i>
                          Comment
                        </span>
                      </div>
                      {/* end::Toolbar */}
                    </div>
                    {/* end::Post */}
                    {replyingComment === feed.feed_id && (
 <>
 <div className="comments_section">
                    {/* begin::Replies */}
                    {feed.feed_comments && feed.feed_comments.map((comments, index) => (

                      <div className='mb-7 ps-5 mt-2 ' key={index}>

                        {/* begin::Reply */}
                        <div className='d-flex mb-5 '>
                          {/* begin::Avatar */}
                          <div className='symbol symbol-circle symbol-35px me-5'>
                            {(userpic !== '') ? <img src={comments.fc_user_pic} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                          </div>
                          {/* end::Avatar */}
                          {/* begin::Info */}
                          <div className='d-flex flex-column flex-row-fluid'>
                            {/* begin::Info */}
                            <div className='d-flex align-items-center flex-wrap mb-1'>
                              <span className='text-gray-800 text-hover-primary fw-bold me-2'>
                                {comments.fc_user_name}
                              </span>
                              <span className='text-gray-400 fw-semibold fs-7'>
                                <CommentTimeDifference createdAt={new Date(comments.fc_submitted_on)} /></span>
                            </div>
                            {/* end::Info */}
                            {/* begin::Post */}
                            <div className="comment" >
                              <div className="comment-text text-gray-800 fs-7 fw-normal pt-1"  dangerouslySetInnerHTML={{ __html: comments.fc_comments }}>

                              </div>
                              <div className="comment-reply mb-2">
                                {/* Reply textbox */}
                                {replyingToComment === comments.fc_id && (
                                  <div className="message-input">

                                    <textarea
                                      placeholder="Reply to this comment..."
                                      className="form-control border-1 p-3 pe-15 me-2 resize-none min-h-25px message-textbox"
                                      value={commentStates[comments.fc_id] || ''}
                                      onChange={(e) => {
                                        const replyComment = e.target.value;
                                        updateCommentState(comments.fc_id, replyComment);
                                      }}
                                      id={'fc-reply' + comments.fc_id}
                                    />
                                    <button className="btn btn-success btn-sm send-button me-2" onClick={() => handleReplyComment(comments.fc_id,comments.fc_user_name)}>
                                      <i className="bi bi-send"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="comment-actions">
                                {comments.fc_user_id !== userid ? (
                                  <span onClick={() => toggleReply(comments.fc_id)} className="comment-reply text-gray-400 text-hover-primary fw-semibold fs-7  me-2">
                                    Reply
                                  </span>
                                ) : (' ')}
                                {comments.fc_user_id === userid ? (
                                  <span onClick={() => delete_comment(comments.fc_id, feed.feed_id)} className="comment-delete text-gray-400 text-hover-primary fw-semibold fs-7">
                                    Delete
                                  </span >
                                ) : (' ')}
                              </div>

                            </div>
                            {/* end::Post */}
                            {/* Iterate through comment replies */}

                            <div className="comment-replies">
                              {comments.feed_replies.map((reply) => (
                                 <div className='d-flex mb-5 mt-5'>
                                 {/* begin::Avatar */}
                                 <div className='symbol symbol-circle symbol-30px me-2' key={reply.fcr_id}>
                                   {(reply.fcr_user_pic
                                     !== '') ? <img src={reply.fcr_user_pic} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                                 </div>
                                 {/* end::Avatar */}
                                 {/* begin::Info */}
                                 <div className='d-flex flex-column flex-row-fluid'>
                                   {/* begin::Info */}
                                   <div className='d-flex align-items-center flex-wrap mb-1'>
                                     <span className='text-gray-800 text-hover-primary fw-bold me-2'>
                                       {reply.fcr_user_name}
                                     </span>
                                     <span className='text-gray-400 fw-semibold fs-7'>
                                       <CommentTimeDifference createdAt={new Date(reply.fcr_submitted_on)} /></span>
                                   </div>

                                   <div>

                              <span className="comment-text text-gray-800 fs-7 fw-normal pt-1"  dangerouslySetInnerHTML={{ __html: reply.fcr_comments }}>

</span>
</div>

                              <div className="comment-reply mb-2">
                                {/* Reply textbox */}
                                {innerreplyingToComment === reply.fcr_reply_id && (
                                  <div className="message-input">
                                    <textarea
                                      placeholder="Reply to this comment..."
                                      className="form-control border-1 p-3 pe-15 me-2 resize-none min-h-25px message-textbox"
                                      value={commentStates[reply.fcr_id] || ''}
                                      onChange={(e) => {
                                        const replyComment = e.target.value;
                                        updateCommentState(reply.fcr_id, replyComment);
                                      }}
                                      id={'fcr-reply' + reply.fcr_id}
                                    />
                                    <button className="btn btn-success btn-sm send-button me-2" onClick={() => handleReplyInnerComment(reply.fcr_id,reply.fcr_user_name)}>
                                      <i className="bi bi-send"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="comment-actions mt-1">
                                {reply.fcr_user_id !== userid ? (
                                  <span onClick={() => toggleinnerReply(reply.fcr_reply_id)} className="comment-reply text-gray-400 text-hover-primary fw-semibold fs-7  me-2">
                                    Reply
                                  </span>
                                ) : (' ')}
                                {reply.fcr_user_id === userid ? (
                                  <span onClick={() => deleteReplyComment(reply.fcr_reply_id,reply.fcr_id)}  className="comment-delete text-gray-400 text-hover-primary fw-semibold fs-7">
                                    Delete
                                  </span >
                                ) : (' ')}
                              </div>
                                  </div>

                                  </div>

                              ))}
                          </div>
                          </div>
                          {/* end::Info */}
                        </div>
                        {/* end::Reply */}
                       {/* Render Replies */}
                      </div>
                    ))}
                    {/* end::Replies */}

                    </div>
                      {/* begin::Reply input */}
                  <div className='separator'></div>
                    <div className="mb-10 mt-5">
                      <div className="message-input">
                        <div className='symbol symbol-30px me-5 symbol-circle justify-content-start' >
                          {(userpic !== '') ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-1 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                        </div>
                        <textarea
                          placeholder="Enter your comments here..."
                          className="form-control border-1 p-3 pe-10 resize-none min-h-25px message-textbox"
                          value={commentStates[feed.feed_id] || ''}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            const comment = e.target.value;
                            updateCommentState(feed.feed_id, comment);
                          }}
                          id={'fc' + feed.feed_id}
                        />
                        <button onClick={() => handleCommentSubmit(feed.feed_id, commentStates[feed.feed_id])} className="btn btn-success btn-sm send-button">
                          <i className="bi bi-send"></i>
                        </button>
                      </div>
                    </div>
</>
          )}
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            )) : !isLoading && (
              <p className="text-center">No Post Found</p>
            )}

          {isLoading && (
            <span className="indicator-progres">
              Please Wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </div>
        {/*
<div className='col-xl-3 col-md-4 sticky-column'>

<div className={`card mb-5 mb-xl-8`}>
            <div className="card-body p-7">
            <h6 className="text-gray-400 mt-5 mb-5">Channels</h6>
            {channelDataArray.length > 0 ? (
  channelDataArray.map((channel) => (
    <div className='d-flex align-items-center mb-5 flex-row-fluid flex-wrap' key={channel.channel_id}>
      <div className='symbol symbol-40px me-5'>
        <img src={channel.channel_image} style={{ objectFit: "cover" }} alt='' />
      </div>
      <div className='flex-grow-1 me-2'>
        <Link to={`/channel/${channel.channel_id}`} className="text-gray-800 text-hover-primary fs-6 fw-bold">{channel.channel_name}</Link>
      </div>

      <Link to={`/channel/${channel.channel_id}`} className="badge badge-success fw-bold my-2">View</Link>

    </div>
  ))
) : !isLoadingChannel && (
  <p>No result found</p>
)}

{isLoadingChannel && (
          <span className="indicator-progres">

            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
              </div>
              </div>
          </div> */}
      </div>
    </>
  )
}

