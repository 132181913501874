import React, { useState } from 'react';

interface MemberBioProps {
    bio: string;
  }

  const MemberBio: React.FC<MemberBioProps> = ({ bio }) => {
  const MAX_WORDS = 30;
  const bioWords = bio.split(' ');
  const isBioLong = bioWords.length > MAX_WORDS;
  const [showFullBio, setShowFullBio] = useState(false);

  return (
    <p className="mt-3">
      {isBioLong && !showFullBio
        ? bioWords.slice(0, MAX_WORDS).join(' ') + '... '
        : bio }
      {isBioLong && (

        <span className='text-primary' style={{cursor:'pointer'}} onClick={() => setShowFullBio(!showFullBio)}>
          {showFullBio ? ' Read Less' : ' Read More'}
        </span>
      )}
    </p>
  );
};

export default MemberBio;
