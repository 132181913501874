import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../modules/auth'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { events_get_data } from "../../../../modules/auth/core/_requests"
import { events_rsvp_data } from '../../../../modules/auth/core/_requests'
import { events_delete } from '../../../../modules/auth/core/_requests'
import { Events_edit_data } from '../../../../modules/auth/core/_requests'
import { events_post_data } from '../../../../modules/auth/core/_requests'
import { Events_publish_data } from '../../../../modules/auth/core/_requests'
import { Events_Update } from '../../../../modules/auth/core/_requests'

interface EventsDatas {
    event_id: string
    event_name: string;
    event_start_date: string;
    event_end_date: string;
    event_time_zone: string;
    event_created_on: string;
    event_created_by: string;
    event_start_time: string;
    event_end_time: string;
    event_status: string;
}

interface RSVPDatas {
    member_id: string;
    member_name: string;
    member_email: string;
    member_roles: string;
    member_pic: string;
    rsvp_date: string;
    member_company: string;
}

interface EventsPostDatas {
    event_name: string;
    event_time_zone: string;
    event_pricing: string;
    event_start_date: string;
    event_start_time: string;
    event_end_date: string;
    event_end_time: string;
    event_location: string;
    event_location_url: string,
    event_description: string;
    event_lockscreen: boolean;
    event_access_level: string;
    event_visibility: string;
    event_custom_url: string;
    event_cover_img: string;
    event_general_settings: string[];
    event_notifications: string[];
    event_created_by: string;
}

const Upcoming_Events = () => {
    const { currentUser } = useAuth()
    const [showModal, setShowModal] = useState(false);
    const [userfname, setUserFname] = useState(currentUser?.first_name || '')
    const [userlname, setUserLname] = useState(currentUser?.last_name || '')
    const userFullName = `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`;
    const [editorContent, setEditorContent] = useState('');
    const [eventEditorContent, seteventEditorContent] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [fileName, setFileName] = useState('');
    const [eventsData, setEventsData] = useState<EventsDatas[]>([]);
    const [showRSVPModal, setShowRSVPModal] = useState(false);
    const [rsvpData, setRsvpData] = useState<RSVPDatas[]>()
    const [isLoading, setIsLoading] = useState(true);
    const [rsvpLoading, setRsvpLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [editEvent, setEditEvent] = useState<EventsPostDatas[]>([]);
    const [initialEditValues, setInitialEditValues] = useState({
        event_id_upd: '',
        event_name_upd: '',
        event_time_zone_upd: '',
        // event_pricing_upd: '',
        event_start_date_upd: '',
        event_start_time_upd: '',
        event_end_date_upd: '',
        event_end_time_upd: '',
        event_location_upd: '',
        event_location_url_upd: '',
        event_general_settings_upd: [],
        event_notifications_upd: [],
    })



    const initialValues: EventsPostDatas = {
        event_name: '',
        event_time_zone: '',
        event_pricing: '',
        event_start_date: '',
        event_start_time: '',
        event_end_date: '',
        event_end_time: '',
        event_location: '',
        event_location_url: '',
        event_description: '',
        event_lockscreen: false,
        event_access_level: '',
        event_visibility: '',
        event_custom_url: '',
        event_cover_img: '',
        event_general_settings: [],
        event_notifications: [],
        event_created_by: userFullName
    }

    const handleButtonClick = () => {
        setShowModal(true);
        setEditorContent('');
        setSelectedFile(null);
        setImagePreview(null);
        setFileName('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    var toolbaroptions = [[{ 'header': 1 }, { 'header': 2 }], ['bold', 'italic'], ['link', 'image'], ['underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]]

    const module = {
        toolbar: toolbaroptions,
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setSelectedFile(selectedFile || null);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setImagePreview(null);
        }
    };

    const validationSchema = Yup.object().shape({
        event_name: Yup.string().required('Event Name is required'),
        event_time_zone: Yup.string().required('Event Time Zone is required'),
        // event_pricing: Yup.string().required('Event Pricing is required'),
        event_start_date: Yup.date().required('Start Date is required'),
        event_start_time: Yup.string().required('Start Time is required'),
        event_end_date: Yup.date().required('End Date is required'),
        event_end_time: Yup.string().required('End Time is required'),
        event_location: Yup.string().required('Event Location is required'),
        onlineEventUrl: Yup.string().required('Event URL is required'),
        // onlineEventUrl: Yup.string().test('required', 'Online event URL is required', function (value) {
        //     return this.parent?.event_location === 'Online' ? !!value : true;
        // }),
        // inPersonEventUrl: Yup.string().test('required', 'Location event URL is required', function (value) {
        //     return this.parent?.event_location === 'In-Person' ? !!value : true;
        // }),
        event_description: Yup.string().required('Event Description is required'),
        // event_access_level: Yup.string().required('Access Level is required').min(1, 'Select at least one Access Level'),
        // event_visibility: Yup.string().required('Visibility Setting is required').min(1, 'Select at least one Visibility Setting'),
        // event_custom_url: Yup.string().required('Custom URL is required'),
        // event_cover_img: Yup.string().required('Cover image is required'),
        event_general_settings: Yup.array().required('General Settings is required').test('at-least-one-setting', 'Select at least one General Setting', (value) => value && value.length > 0),
        event_notifications: Yup.array().required('Notification is required').test('at-least-one-setting', 'Select at least one Notification', (value) => value && value.length > 0),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const postEventsData = new FormData();
            const imageFileInput = document.querySelector<HTMLInputElement>('#event_cover_img');
            if (imageFileInput?.files && imageFileInput.files.length > 0) {
                postEventsData.append("event_cover_img", imageFileInput.files[0]);
            }
            postEventsData.append('event_access_level', 'Free');
            postEventsData.append('event_visibility', 'Community');
            postEventsData.append('event_general_settings', JSON.stringify(values.event_general_settings));
            postEventsData.append('event_notifications', JSON.stringify(values.event_notifications));
            postEventsData.append('event_created_by', userFullName);
            const { data: auth } = await events_post_data(
                postEventsData,
                values.event_name,
                values.event_time_zone,
                values.event_pricing,
                values.event_start_date,
                values.event_start_time,
                values.event_end_date,
                values.event_end_time,
                values.event_location,
                values.onlineEventUrl,
                values.event_description,
                values.event_lockscreen,
                values.event_custom_url,
            );
            actions.resetForm();
            setEditorContent('');
            setSelectedFile(null);
            setImagePreview(null);
            setShowModal(false)
            setTimeout(() => {
                setLoading(false)
                fetchEventsData("1");
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500); // 5000 milliseconds = 5 seconds
        } catch (error) {
            // Handle error
            console.error('Error submitting form data:', error);
        }
    }

    const fetchEventsData = async (upcoming_events: any) => {
        try {
            const response = await events_get_data(upcoming_events);
            setEventsData(response.data.upcoming_events);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    };

    useEffect(() => {
        fetchEventsData("1");
    }, []);

    const formatDate = (dateString: string) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
        return `${month}`;
    };

    const openRSVPModal = (event_id: any) => {
        setShowRSVPModal(true);
        fetchRSVPData(event_id);
    };

    const closeRSVPModal = () => {
        setShowRSVPModal(false);
    };

    const fetchRSVPData = async (event_rsvp_id: any) => {
        setRsvpLoading(true);
        setShowRSVPModal(true);
        try {
            const response = await events_rsvp_data(event_rsvp_id);
            setRsvpData(response.data.rsvp_deails);
            setRsvpLoading(false)
        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    };

    const deleteEvent = (event_del_id: string) => {
        const handledeleteEvent = async () => {
            try {
                const response = await events_delete(event_del_id);
                const filterdata = eventsData.filter(event => event.event_id !== event_del_id);
                setEventsData(filterdata)
            } catch (error) {
                console.error('Error deleting event:', error);
            }
        };
        handledeleteEvent()
    }

    const handleDeleteConfirmation = (event_del_id: string) => {

        Swal.fire({
            title: 'Are you sure to',
            text: "Delete this Event..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                deleteEvent(event_del_id);
                Swal.fire(
                    'Deleted!',
                    'Your Event has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleEventEdit = async (events_edit_id: string) => {
        setLoadingEdit(true);
        setShowEditModal(true);
        try {
            const response = await Events_edit_data(events_edit_id);
            setEditEvent(response.data.event_details);
            const initialData = response.data.event_details
            const generalSettings = initialData.length > 0 ? JSON.parse(initialData[0].event_general_settings) : [];
            const notifications = initialData.length > 0 ? JSON.parse(initialData[0].event_notifications) : [];
            setInitialEditValues({
                event_id_upd: initialData.length > 0 ? initialData[0].event_id : '',
                event_name_upd: initialData.length > 0 ? initialData[0].event_name : '',
                event_time_zone_upd: initialData.length > 0 ? initialData[0].event_time_zone : '',
                // event_pricing_upd: initialData.length > 0 ? initialData[0].event_pricing : '',
                event_start_date_upd: initialData.length > 0 ? initialData[0].event_start_date : '',
                event_start_time_upd: initialData.length > 0 ? initialData[0].event_start_time : '',
                event_end_date_upd: initialData.length > 0 ? initialData[0].event_end_date : '',
                event_end_time_upd: initialData.length > 0 ? initialData[0].event_end_time : '',
                event_location_upd: initialData.length > 0 ? initialData[0].event_location : '',
                event_location_url_upd: initialData.length > 0 ? initialData[0].event_location_url : '',
                event_general_settings_upd: generalSettings,
                event_notifications_upd: notifications,
            })
            seteventEditorContent(response.data.event_details[0].event_description);
            setImagePreview(response.data.event_details[0].event_cover_img);
            const CategoryResponse = response.data.event_details[0].event_general_settings;
            const CategoryFormatArray = JSON.parse(CategoryResponse);
            setShowEditModal(true);
        } catch (error) {
            console.error('Error fetching event details for edit:', error);
        }
        finally {
            setLoadingEdit(false);
        }
    };

    const handleUnpublish = async (event_status_id: any, event_status: string) => {
        try {
            const response = await Events_publish_data(event_status_id, event_status);
            const event_status_new = event_status == '0' ? '1' : '0';
            setEventsData(prevEvents =>
                prevEvents.map(event => event.event_id === event_status_id ? { ...event, event_status: event_status_new } : event));
        }
        catch (error) { console.error('Error updating event status:', error); }
    };

    const handleSearch = () => {
        // Get the value from the search input and update the searchQuery state
        const searchInput = document.getElementById("form1");
        if (searchInput instanceof HTMLInputElement) {
            setSearchQuery(searchInput.value);
        }
    };

    const handleUpdateEvent = async (values: any, { resetForm }: any) => {
        setLoading(true)
        try {
            const UpdateEventsData = new FormData();
            // const imageFileInput = document.querySelector<HTMLInputElement>('#event_cover_img_upd');
            // if (imageFileInput?.files && imageFileInput.files.length > 0) {
            //     UpdateEventsData.append("event_cover_img_upd", imageFileInput.files[0]);
            // }
            if (selectedFile) {
                UpdateEventsData.append('event_cover_img_upd', selectedFile, selectedFile.name);
            }
            UpdateEventsData.append('event_access_level_upd', 'Free');
            UpdateEventsData.append('event_visibility_upd', 'Community');
            UpdateEventsData.append('event_general_settings_upd', JSON.stringify(values.event_general_settings_upd));
            UpdateEventsData.append('event_notifications_upd', JSON.stringify(values.event_notifications_upd));
            UpdateEventsData.append('event_created_by_upd', userFullName);
            const { data: auth } = await Events_Update(
                UpdateEventsData,
                values.event_id_upd,
                values.event_name_upd,
                values.event_time_zone_upd,
                values.event_pricing_upd,
                values.event_start_date_upd,
                values.event_start_time_upd,
                values.event_end_date_upd,
                values.event_end_time_upd,
                values.event_location_upd,
                values.event_location_url_upd,
                values.event_description_upd,
                // values.event_lockscreen_upd,
                // values.event_custom_url_upd,
            );
            resetForm();
            seteventEditorContent('');
            setSelectedFile(null);
            setImagePreview(null);
            setShowEditModal(false)
            setTimeout(() => {
                setLoading(false)
                fetchEventsData("1");
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500); // 5000 milliseconds = 5 seconds
        } catch (error) {
            // Handle error
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>

            <div className="card card-custom p-5 mt-5">
                <h3>Events</h3>
                <div className='d-flex justify-content-between align-items-center mt-5'>
                    <div className="input-group">
                        <input type="search" placeholder='Search Events...' id="form1" className="form-control w-50"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="button" className="btn btn-primary" onClick={handleSearch}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={handleButtonClick}>
                            Create New Event
                        </button>
                    </div>
                </div>
                <div className="row pt-10">
                    <div className="col-md-5 ps-20">
                        <h5>Events Name</h5>
                    </div>
                    <div className="col-md-4">
                        <h5 className="text-center">Submitted On</h5>
                    </div>
                    <div className="col-md-1">
                        <h5>Status</h5>
                    </div>
                    <div className="col-md-2">
                        <h5 className="text-center">Action</h5>
                    </div>
                </div>
            </div>

            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Event</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                {({ setFieldValue }) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="event_name" className='form-label mb-3'>Event Name</label>
                                            <Field type="text" name="event_name" className="form-control" />
                                            <ErrorMessage name="event_name" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="event_time_zone">Event Time Zone</label>
                                            <Field
                                                as="select"
                                                name="event_time_zone"
                                                aria-label="Select example"
                                                className="form-select"
                                            >
                                                <option value=''>Select a Timezone..</option>
                                                <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                                <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                                <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                                <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                                <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                                <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                <option value="America/Managua">(GMT-06:00) Central America</option>
                                                <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                                <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                                <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                                <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                                <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                                <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                                <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                                <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                                <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                                <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                                <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                                <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                                <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                                <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                                <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                                <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                                <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                                <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                                <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                                <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                                <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                                <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                                <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                                <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                                <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                                <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                                <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                                <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                                <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                                <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                                <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                            </Field>
                                            <ErrorMessage name="event_time_zone" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <Field type="hidden" name="event_pricing" className="form-control form-control-lg" />
                                            <ErrorMessage name="event_pricing" component="div" className="text-danger" />
                                        </div>

                                        <label htmlFor="start_date_time" className="form-label mt-3 mb-3">Start Date & Time</label>
                                        <div className='d-flex'>
                                            <div className="form-group">
                                                <Field type="date" name="event_start_date" className="form-control" />
                                                <ErrorMessage name="event_start_date" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <Field type="time" name="event_start_time" className="form-control ms-5" />
                                                <ErrorMessage name="event_start_time" component="div" className="text-danger" />
                                            </div>
                                        </div>

                                        <label htmlFor="end_date_time" className="form-label mt-3 mb-3">End Date & Time</label>
                                        <div className='d-flex'>
                                            <div className="form-group">
                                                <Field type="date" name="event_end_date" className="form-control" />
                                                <ErrorMessage name="event_end_date" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <Field type="time" name="event_end_time" className="form-control ms-5" />
                                                <ErrorMessage name="event_end_time" component="div" className="text-danger" />
                                            </div>
                                        </div>

                                        <label className='form-label mt-3'>Location</label>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field
                                                type="radio"
                                                name="event_location"
                                                value="Online"
                                                id="Online"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Online">Online</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid'>
                                            <Field
                                                type="radio"
                                                name="event_location"
                                                value="In-Person"
                                                id="In-Person"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="In-Person">In-Person</label>
                                        </div>
                                        <ErrorMessage name="event_location" component="div" className="text-danger" />

                                        <div className="form-group">
                                            <label className="form-label mt-3">Event URL</label>
                                            <Field
                                                type="text"
                                                name="onlineEventUrl"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="onlineEventUrl" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="event_cover_img">Cover Image</label>
                                            <input
                                                type="file"
                                                id="event_cover_img"
                                                name="event_cover_img"
                                                style={{ color: 'transparent' }}
                                                className="form-control"
                                                onChange={handleFileChange}
                                                accept="image/png, image/gif, image/jpeg"
                                            />
                                            {selectedFile && <p>Selected File: {fileName}</p>}
                                            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}
                                            <ErrorMessage name="event_cover_img" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="event_description" className='form-label mt-3 mb-2'>Description</label>
                                            <ReactQuill
                                                value={editorContent}
                                                placeholder="Write something..."
                                                modules={module}
                                                onChange={(content) => {
                                                    setEditorContent(content);
                                                    setFieldValue('event_description', content);
                                                }}
                                            />
                                            <ErrorMessage name="event_description" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-check form-switch">
                                            <Field
                                                className="form-check-input"
                                                name="event_lockscreen"
                                                type="hidden"
                                                id="flexSwitchCheckDefault"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className='form-label'>Access Level</label>
                                            <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="Free">
                                                <div className='form-check form-check-custom form-check-solid'>
                                                    <Field
                                                        type="radio"
                                                        name="event_access_level"
                                                        value="Free"
                                                        className="form-check-input"
                                                        id="Free"
                                                        checked="checked"
                                                    />
                                                    <span className='d-flex flex-column ms-5'>
                                                        <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                                            Free
                                                        </span>
                                                        <span className='fs-6 fw-bold text-gray-400'>
                                                            Free for everyone
                                                        </span>
                                                    </span>
                                                </div>
                                            </label>
                                            <ErrorMessage name="event_access_level" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className='form-label'>Visibility Setting</label>
                                            <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="community">
                                                <div className='form-check form-check-custom form-check-solid'>
                                                    <Field
                                                        type="radio"
                                                        name="event_visibility"
                                                        value="Community"
                                                        className="form-check-input"
                                                        id="community"
                                                        checked="checked"
                                                    />
                                                    <span className='d-flex flex-column ms-5'>
                                                        <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                                            Community
                                                        </span>
                                                        <span className='fs-6 fw-bold text-gray-400'>
                                                            Only community member can view
                                                        </span>
                                                    </span>
                                                </div>
                                            </label>
                                            <ErrorMessage name="event_visibility" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <input type="hidden" name="event_custom_url" className="form-control" />
                                        </div>

                                        <div className="form-group">
                                            <Field type="hidden" name="event_created_by" className="form-control w-50" />
                                        </div>

                                        <div className="form-group">
                                            <label className='form-label'>General Settings:</label>
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="checkbox"
                                                    name="event_general_settings"
                                                    value="Hide members count"
                                                    className="form-check-input"
                                                    id="Hide members"
                                                />
                                                <label className="form-check-label" htmlFor="Hide members">Hide members count</label>
                                            </div>

                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="event_general_settings" value="Hide cover image" className="form-check-input" id="Hide cover" />
                                                <label className="form-check-label" htmlFor="Hide cover">Hide cover image</label>
                                            </div>
                                            <ErrorMessage name="event_general_settings" component="div" className="text-danger" />
                                        </div>

                                        <label className="form-label mt-3 mb-3">Notifications</label>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="event_notifications" value="Send RSVP Confirmation" className="form-check-input" id="Send_RSVP" />
                                            <label className="form-check-label" htmlFor="Send_RSVP">Send RSVP Confirmation</label>
                                        </div>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="event_notifications" value="Send reminder 1 hour before event" className="form-check-input" id="Send_reminder" />
                                            <label className="form-check-label" htmlFor="Send_reminder">Send reminder 1 hour before event</label>
                                        </div>
                                        <ErrorMessage name="event_notifications" component="div" className="text-danger" />

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={handleCloseModal} disabled={loading}>
                                                Cancel
                                            </button>
                                            <div className='d-flex justify-content-end'>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                // Show loading animation using Bootstrap spinner
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                eventsData.length > 0 ? (
                    eventsData
                        .filter((item) =>
                            item?.event_name.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((event) => (
                            <React.Fragment key={event.event_id}>
                                <div className="card mb-3 mt-7">
                                    <div className="card-body row">
                                        <div className="col-md-1">
                                            <div className="symbol symbol-75px me-10">
                                                <div className="symbol-label fs-2 fw-bold text-success text-center">
                                                    {event.event_start_date === event.event_end_date ? (
                                                        <h5>{event.event_start_date.slice(-2)}<br /><span className='pt-5'>{formatDate(event.event_start_date)}</span></h5>
                                                    ) : (
                                                        <h5>
                                                            {event.event_start_date.slice(-2)}-{event.event_end_date.slice(-2)}<br />
                                                            {formatDate(event.event_start_date)}
                                                        </h5>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className='ms-5'>
                                                <h5>{event.event_name}</h5>
                                                <p>{event.event_start_time}-{event.event_end_time} {event.event_time_zone}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <h5>{event.event_created_by}</h5>
                                                <p>{event.event_created_on}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            {event.event_status === "0" ? (
                                                <span className="badge bg-dark text-white">Unpublished</span>
                                            ) : event.event_status === "1" ? (
                                                <span className="badge bg-success text-white">Published</span>
                                            ) : null}
                                        </div>
                                        <div className="col-md-2 dropdown">
                                            <div className='ms-10'>
                                                <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-3"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleEventEdit(event.event_id)}>Edit</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleDeleteConfirmation(event.event_id)}>Delete</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(event.event_id, event.event_status)}>{event.event_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => openRSVPModal(event.event_id)}>RSVP List</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                ) : (
                    <div className="card card-custom mt-5">
                        <div className="d-flex justify-content-center p-10">
                            <div className="car">
                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                <h3 className="text-center">No Events Found</h3>
                            </div>
                        </div>
                    </div>
                )
            )}

            <div className="modal" tabIndex={-1} role="dialog" style={{ display: showRSVPModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                    {rsvpLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">RSVP List</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeRSVPModal}></button>
                            </div>
                            <div className="modal-body">
                                {rsvpData && rsvpData.length > 0 && (
                                    <div className="row pb-10">
                                        <div className="col-md-8 ps-20">
                                            <h5>Member Details</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <h5>Submitted On</h5>
                                        </div>
                                    </div>
                                )}
                                <table className="table table-striped gy-7 gs-7">
                                    <tbody>
                                        {(rsvpData && rsvpData.length > 0) ? (
                                            rsvpData.map((rsvp) => (
                                                <React.Fragment key={rsvp.member_id}>
                                                    <tr>
                                                        <td>
                                                            {rsvp.member_pic ? (
                                                                <img src={rsvp.member_pic} alt='events' style={{ borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px', objectFit: 'cover' }} />
                                                            ) : (
                                                                <div className="symbol symbol-50px">
                                                                    <div className="symbol-label fs-2 fw-bold text-success" style={{ borderRadius: '50%', overflow: 'hidden', width: '60px', height: '60px', objectFit: 'cover' }}>{rsvp.member_name[0]}</div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <h6 className='pt-3'>{rsvp.member_name}</h6>
                                                            <h6>{rsvp.member_company}</h6>
                                                        </td>
                                                        <td>
                                                            <h6 className='pt-5 ps-20'>{rsvp.rsvp_date}</h6>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <p className='text-center'>No RSVPs found.</p>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeRSVPModal}>Close</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Event</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {loadingEdit ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <Formik
                                        initialValues={initialEditValues}
                                        // validationSchema={validationSchema}
                                        onSubmit={handleUpdateEvent}>
                                        {({ setFieldValue }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <input type="hidden" name="event_id_upd" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="event_name_upd" className='form-label mb-3'>Event Name</label>
                                                    <Field type="text" name="event_name_upd" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label mt-3 mb-3" htmlFor="event_time_zone_upd">Event Time Zone</label>
                                                    <Field
                                                        as="select"
                                                        name="event_time_zone_upd"
                                                        aria-label="Select example"
                                                        className="form-select"
                                                    >
                                                        <option value=''>Select a Timezone..</option>
                                                        <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                                        <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                        <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                                        <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                                        <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                        <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                                        <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                                        <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                        <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                        <option value="America/Managua">(GMT-06:00) Central America</option>
                                                        <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                                        <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                        <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                                        <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                        <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                        <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                                        <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                                        <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                                        <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                                        <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                        <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                                        <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                        <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                                        <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                        <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                        <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                        <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                        <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                        <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                                        <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                                        <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                        <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                        <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                        <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                                        <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                                        <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                                        <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                                        <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                        <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                        <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                                        <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                                        <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                        <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                        <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                                        <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                                        <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                        <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                                        <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                                        <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                        <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                        <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                                        <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                        <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                        <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                                        <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                                        <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                        <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                                        <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                        <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                                        <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                        <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                        <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                        <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                        <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                        <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                                        <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                        <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                        <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                                        <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                        <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                        <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                        <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                        <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                        <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                        <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                                        <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                        <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                                        <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                        <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                                        <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                        <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                        <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                                    </Field>
                                                    <ErrorMessage name="event_time_zone_upd" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <Field type="hidden" name="event_pricing_upd" className="form-control form-control-lg" />
                                                    <ErrorMessage name="event_pricing_upd" component="div" className="text-danger" />
                                                </div>

                                                <label htmlFor="start_date_time" className="form-label mt-3 mb-3">Start Date & Time</label>
                                                <div className='d-flex'>
                                                    <div className="form-group">
                                                        <Field type="date" name="event_start_date_upd"
                                                            // value={initialEditValues.event_start_date_upd}
                                                            className="form-control" />
                                                        <ErrorMessage name="event_start_date_upd" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="time" name="event_start_time_upd"
                                                            //    value={initialEditValues.event_start_time_upd}
                                                            className="form-control ms-5" />
                                                        <ErrorMessage name="event_start_time" component="div" className="text-danger" />
                                                    </div>
                                                </div>

                                                <label htmlFor="end_date_time" className="form-label mt-3 mb-3">End Date & Time</label>
                                                <div className='d-flex'>
                                                    <div className="form-group">
                                                        <Field type="date" name="event_end_date_upd" className="form-control" />
                                                        <ErrorMessage name="event_end_date" component="div" className="text-danger" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field type="time" name="event_end_time_upd" className="form-control ms-5" />
                                                        <ErrorMessage name="event_end_time" component="div" className="text-danger" />
                                                    </div>
                                                </div>

                                                <label className='form-label mt-3'>Location</label>
                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                    <Field
                                                        type="radio"
                                                        name="event_location_upd"
                                                        value="Online"
                                                        id="Online"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Online">Online</label>
                                                </div>
                                                <div className='form-check form-check-custom form-check-solid'>
                                                    <Field
                                                        type="radio"
                                                        name="event_location_upd"
                                                        value="In-Person"
                                                        id="In-Person"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="In-Person">In-Person</label>
                                                </div>
                                                <ErrorMessage name="event_location_upd" component="div" className="text-danger" />

                                                <div className="form-group">
                                                    <label className="form-label mt-3">Event URL</label>
                                                    <Field
                                                        type="text"
                                                        name="event_location_url_upd"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="onlineEventUrl" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label mt-3 mb-3" htmlFor="event_cover_img_upd">Cover Image</label>
                                                    <input
                                                        type="file"
                                                        id="event_cover_img_upd"
                                                        name="event_cover_img_upd"
                                                        style={{ color: 'transparent' }}
                                                        className="form-control"
                                                        onChange={handleFileChange}
                                                        accept="image/png, image/gif, image/jpeg"
                                                    />
                                                    {selectedFile && <p>Selected File: {fileName}</p>}
                                                    {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}
                                                    <ErrorMessage name="event_cover_img_upd" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="event_description_upd" className='form-label mt-3 mb-2'>Description</label>
                                                    <ReactQuill
                                                        value={eventEditorContent}
                                                        placeholder="Write something..."
                                                        modules={module}
                                                        onChange={(edit_content) => {
                                                            seteventEditorContent(edit_content);
                                                            setFieldValue('event_description_upd', edit_content);
                                                        }}
                                                    />
                                                    <ErrorMessage name="event_description" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-check form-switch">
                                                    <Field
                                                        className="form-check-input"
                                                        name="event_lockscreen"
                                                        type="hidden"
                                                        id="flexSwitchCheckDefault"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className='form-label'>Access Level</label>
                                                    <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="Free">
                                                        <div className='form-check form-check-custom form-check-solid'>
                                                            <Field
                                                                type="radio"
                                                                name="event_access_level"
                                                                value="Free"
                                                                className="form-check-input"
                                                                id="Free"
                                                                checked="checked"
                                                            />
                                                            <span className='d-flex flex-column ms-5'>
                                                                <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                                                    Free
                                                                </span>
                                                                <span className='fs-6 fw-bold text-gray-400'>
                                                                    Free for everyone
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </label>
                                                    <ErrorMessage name="event_access_level" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label className='form-label'>Visibility Setting</label>
                                                    <label className='d-flex flex-stack mb-5 cursor-pointer' htmlFor="community">
                                                        <div className='form-check form-check-custom form-check-solid'>
                                                            <Field
                                                                type="radio"
                                                                name="event_visibility"
                                                                value="Community"
                                                                className="form-check-input"
                                                                id="community"
                                                                checked="checked"
                                                            />
                                                            <span className='d-flex flex-column ms-5'>
                                                                <span className='fw-bolder text-gray-500 text-hover-primary fs-5'>
                                                                    Community
                                                                </span>
                                                                <span className='fs-6 fw-bold text-gray-400'>
                                                                    Only community member can view
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </label>
                                                    <ErrorMessage name="event_visibility" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <input type="hidden" name="event_custom_url" className="form-control" />
                                                </div>

                                                <div className="form-group">
                                                    <Field type="hidden" name="event_created_by" className="form-control w-50" />
                                                </div>

                                                <div className="form-group">
                                                    <label className='form-label'>General Settings:</label>
                                                    <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                        <Field
                                                            type="checkbox"
                                                            name="event_general_settings_upd"
                                                            value="Hide members count"
                                                            className="form-check-input"
                                                            id="Hide members"
                                                        />
                                                        <label className="form-check-label" htmlFor="Hide members">Hide members count</label>
                                                    </div>

                                                    <div className="form-check form-check-custom form-check-solid mb-3">
                                                        <Field type="checkbox" name="event_general_settings_upd" value="Hide cover image" className="form-check-input" id="Hide cover" />
                                                        <label className="form-check-label" htmlFor="Hide cover">Hide cover image</label>
                                                    </div>
                                                    <ErrorMessage name="event_general_settings_upd" component="div" className="text-danger" />
                                                </div>

                                                <label className="form-label mt-3 mb-3">Notifications</label>
                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                    <Field type="checkbox" name="event_notifications_upd" value="Send RSVP Confirmation" className="form-check-input" id="Send_RSVP" />
                                                    <label className="form-check-label" htmlFor="Send_RSVP">Send RSVP Confirmation</label>
                                                </div>
                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                    <Field type="checkbox" name="event_notifications_upd" value="Send reminder 1 hour before event" className="form-check-input" id="Send_reminder" />
                                                    <label className="form-check-label" htmlFor="Send_reminder">Send reminder 1 hour before event</label>
                                                </div>
                                                <ErrorMessage name="event_notifications_upd" component="div" className="text-danger" />
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)} disabled={loading}>
                                                        Cancel
                                                    </button>
                                                    <div className='d-flex justify-content-end'>
                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                            {!loading && 'Save Changes'}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    )
}

export default Upcoming_Events;