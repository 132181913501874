import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth'
import Swal from 'sweetalert2';
import { channel_create } from '../../../modules/auth/core/_requests';
import { channels } from '../../../modules/auth/core/_requests';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


interface ChannelFormData {
    channel_name: string;
    channel_description: string;
    channel_category: string;
    channel_image: File | '';
    access_level: string;
    visibility_setting: string;
    posting_permission: string;
    general_settings: string[];
    active_lock_screen: boolean;
    created_by: string;
}

interface ChannelDataFromAPI {
    channel_id: number;
    channel_name: string;
    channel_image: string;
    total_members: number;
    created_on: number;
    channel_status: string
}

const Channel = () => {
    const { currentUser } = useAuth()
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [fileName, setFileName] = useState('');
    const [userfname, setUserFname] = useState(currentUser?.first_name || '')
    const [userlname, setUserLname] = useState(currentUser?.last_name || '')
    const [editData, setEditData] = useState<ChannelDataFromAPI[]>([]);
    const [channelData, setChannelData] = useState<ChannelDataFromAPI[]>([]);
    const [editingChannel, setEditingChannel] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const userFullName = `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`;


    const initialValues: ChannelFormData = {
        channel_name: '',
        channel_description: '',
        channel_category: '',
        channel_image: '',
        access_level: '',
        visibility_setting: '',
        posting_permission: '',
        general_settings: [],
        active_lock_screen: false,
        created_by: userFullName,
    };

    const validationSchema = Yup.object().shape({
        channel_name: Yup.string().required('Channel Name is required').max(10, 'Max 10 characters allowed'),
        channel_description: Yup.string().required('Description is required').min(30, 'Min 30 characters required'),
        channel_category: Yup.string().required('Channel Category is required'),
        access_level: Yup.string().required('Access Level is required').min(1, 'Select at least one Access Level'),
        visibility_setting: Yup.string().required('Visibility Setting is required').min(1, 'Select at least one Visibility Setting'),
        posting_permission: Yup.string().required('Posting Permission is required').min(1, 'Select at least one Posting Permission'),
        general_settings: Yup.array().required('General Settings is required').test('at-least-one-setting', 'Select at least one General Setting', (value) => value && value.length > 0),
        active_lock_screen: Yup.boolean().required('Active Lock Screen is required'),
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        setSelectedFile(selectedFile || null);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setImagePreview(null);
        }
    };

    const Onsubmit = async (values: any, { resetForm }: any) => {
        setLoading(true)
        const postchannelData = new FormData();
        if (selectedFile) {
            postchannelData.append('channel_image', selectedFile, selectedFile.name);
        }
        postchannelData.append('general_settings', JSON.stringify(values.general_settings));
        postchannelData.append('created_by', userFullName);
        const { data: auth } = await channel_create(
            postchannelData,
            values.channel_name,
            values.channel_description,
            values.channel_category,
            values.active_lock_screen,
            values.access_level,
            values.visibility_setting,
            values.posting_permission,
        );
        resetForm();
        setSelectedFile(null);
        setImagePreview(null);
        setFileName('');
        handleCloseModal();
        setTimeout(() => {
            setShowAlert(false);
            setLoading(false)
            fetchChannelData("0");
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Submitted',
                showConfirmButton: true,
                timer: 3000
            });
        }, 500)
    }

    const fetchChannelData = async (get_channel: any) => {
        try {
            // Perform an asynchronous API call using Axios
            const response = await channels(get_channel);
            setChannelData(response.data.channel_datas);
            setIsLoading(false);
        } catch (error) {
            // Handle errors if the API call fails
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        fetchChannelData("0");
    }, []);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleSearch = () => {
        // Get the value from the search input and update the searchQuery state
        const searchInput = document.getElementById("form1");
        if (searchInput instanceof HTMLInputElement) {
            setSearchQuery(searchInput.value);
        }
    };

    return (
        <>

            <div className="card card-custom p-5">
                <h3>Channels</h3>
                <div className='d-flex justify-content-between align-items-center mt-10'>
                    <div className="input-group">
                        <input type="search" id="form1" className="form-control w-50"
                            value={searchQuery} placeholder='Search...'
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="button" className="btn btn-primary" onClick={handleSearch}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={handleButtonClick}>
                            Create New Channel
                        </button>
                    </div>
                </div>
            </div>

            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Channel</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik initialValues={initialValues} onSubmit={Onsubmit} validationSchema={validationSchema}>
                                {({ setFieldValue, values }) => (
                                    <Form>
                                        {/* Channel Name input */}
                                        <div className="form-group">
                                            <label htmlFor="channel_name" className='form-label'>Channel Name</label>
                                            <Field type="text" name="channel_name" className="form-control mt-2 w-50" />
                                            <ErrorMessage name="channel_name" component="div" className="text-danger" />
                                        </div>

                                        {/* Description input */}
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="channel_description" >Description</label>
                                            <Field
                                                name="channel_description"
                                                className="form-control w-50"
                                                as="textarea"
                                            />
                                            <ErrorMessage name="channel_description" component="div" className="text-danger" />
                                        </div>

                                        {/* Channel Category input */}
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="channel_category">Channel Category</label>
                                            <Field
                                                as="select"
                                                name="channel_category"
                                                aria-label="Select example"
                                                className="form-select w-50"
                                                onChange={(e: any) => setFieldValue('channel_category', e.target.value)}
                                            >
                                                <option value="">Assign to Category</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Field>
                                            <ErrorMessage name="channel_category" component="div" className="text-danger" />
                                        </div>

                                        {/* Channel Image input */}
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="channel_image">Channel Image</label>
                                            <Field
                                                type="file"
                                                id="channel_image"
                                                name="channel_image"
                                                className="form-control w-50"
                                                onChange={handleFileChange}
                                                accept="image/png, image/gif, image/jpeg"
                                            />
                                            {selectedFile && <p>Selected File: {fileName}</p>}
                                            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />}
                                            <ErrorMessage name="channel_image" component="div" className="text-danger" />
                                        </div>

                                        <label className='form-label mt-3'>Customize the Lock Screen ?</label>
                                        <div className="form-check form-switch">
                                            <Field
                                                className="form-check-input"
                                                name="active_lock_screen"
                                                type="checkbox"
                                                id="flexSwitchCheckDefault"
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                Active Lock Screen and Customize the call to action
                                            </label>
                                        </div>

                                        {/* Access Level input */}
                                        <div className="form-group">
                                            <label className="form-label mt-3">Access Level :</label>
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="radio"
                                                    name="access_level"
                                                    value="Open"
                                                    className="form-check-input"
                                                    id="Open"
                                                    checked={values.access_level === 'Open'}
                                                />
                                                <label className="form-check-label" htmlFor="Open">Open</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="access_level"
                                                    value="Private"
                                                    id="Private"
                                                    className="form-check-input"
                                                    checked={values.access_level === 'Private'}
                                                />
                                                <label className="form-check-label" htmlFor="Private">Private</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="access_level"
                                                    value="Paid Members"
                                                    id="Paid_Members"
                                                    className="form-check-input"
                                                    checked={values.access_level === 'Paid Members'}
                                                />
                                                <label className="form-check-label" htmlFor="Paid_Members">Paid Members</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="access_level"
                                                    value="By plans"
                                                    id="By_plans"
                                                    className="form-check-input"
                                                    checked={values.access_level === 'By plans'}
                                                />
                                                <label className="form-check-label" htmlFor="By_plans">By plans</label>
                                            </div>
                                            <ErrorMessage name="access_level" component="div" className="text-danger" />
                                        </div>


                                        {/* Visibility Setting input */}
                                        <div className="form-group">
                                            <label className='form-label'>Visibility Setting:</label>
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="radio"
                                                    name="visibility_setting"
                                                    value="Public"
                                                    className="form-check-input"
                                                    id="Public"
                                                    checked={values.visibility_setting === 'Public'}
                                                />
                                                <label className="form-check-label" htmlFor="Public">Public</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="visibility_setting"
                                                    value="Restricted"
                                                    className="form-check-input"
                                                    id="Restricted"
                                                    checked={values.visibility_setting === 'Restricted'}
                                                />
                                                <label className="form-check-label" htmlFor="Restricted">Restricted</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="visibility_setting"
                                                    value="Community"
                                                    className="form-check-input"
                                                    id="Community"
                                                    checked={values.visibility_setting === 'Community'}
                                                />
                                                <label className="form-check-label" htmlFor="Community">Community</label>
                                            </div>
                                            <ErrorMessage name="visibility_setting" component="div" className="text-danger" />
                                        </div>



                                        {/* Posting Permission input */}
                                        <div className="form-group">
                                            <label>Posting Permission:</label>
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="radio"
                                                    name="posting_permission"
                                                    value="Members & admin"
                                                    className="form-check-input"
                                                    id="Members & admin"
                                                    checked={values.posting_permission === 'Members & admin'}
                                                />
                                                <label className="form-check-label" htmlFor="Members & admin">Members & admin</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field
                                                    type="radio"
                                                    name="posting_permission"
                                                    value="Admin only"
                                                    className="form-check-input"
                                                    id="Admin only"
                                                    checked={values.posting_permission === 'Admin only'}
                                                />
                                                <label className="form-check-label" htmlFor="Admin only">Admin only</label>
                                            </div>
                                            <ErrorMessage name="posting_permission" component="div" className="text-danger" />
                                        </div>


                                        {/* General Settings input */}
                                        <div className="form-group">
                                            <label className='form-label'>General Settings:</label>
                                            {/* Replace radio inputs with checkboxes to allow multiple selections */}
                                            <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                                                <Field
                                                    type="checkbox"
                                                    name="general_settings"
                                                    value="Hide members count" // Add a value for each checkbox
                                                    className="form-check-input"
                                                    id="Hide members"
                                                />
                                                <label className="form-check-label" htmlFor="Hide members">Hide members count</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Enable moderation" className="form-check-input" id="Enable moderation" />
                                                <label className="form-check-label" htmlFor="Enable moderation">Enable moderation</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Auto-join channel" className="form-check-input" id="Auto-join" />
                                                <label className="form-check-label" htmlFor="Auto-join">Auto-join channel</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Exclude from the weekly digest" className="form-check-input" id="Exclude from" />
                                                <label className="form-check-label" htmlFor="Exclude from">Exclude from the weekly digest</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Display available plans" className="form-check-input" id="Display available" />
                                                <label className="form-check-label" htmlFor="Display available">Display available plans</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Require post title" className="form-check-input" id="Require post" />
                                                <label className="form-check-label" htmlFor="Require post">Require post title</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Hide cover image" className="form-check-input" id="Hide cover" />
                                                <label className="form-check-label" htmlFor="Hide cover">Hide cover image</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Disable comments & reaction" className="form-check-input" id="Disable comments" />
                                                <label className="form-check-label" htmlFor="Disable comments">Disable comments & reaction</label>
                                            </div>
                                            <div className="form-check form-check-custom form-check-solid mb-3">
                                                <Field type="checkbox" name="general_settings" value="Require post cover image" className="form-check-input" id="Require post cover" />
                                                <label className="form-check-label" htmlFor="Require post cover">Require post cover image </label>
                                            </div>
                                            <ErrorMessage name="general_settings" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="channel_name">Created by</label> */}
                                            <Field type="hidden" name="created_by" className="form-control w-50" />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                                Cancel
                                            </button>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                // Show loading animation using Bootstrap spinner
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive mt-4">
                    <div className="card card-custom mt-4">
                        <div className="card-body">
                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Channel Image</th>
                                        <th>Channel Name</th>
                                        <th>Total Members</th>
                                        <th>Created On</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channelData.length > 0 ? (
                                        channelData
                                            .filter((item) =>
                                                item?.channel_name.toLowerCase().includes(searchQuery.toLowerCase())
                                            )
                                            .map((item) => (
                                                <tr key={item?.channel_id}>
                                                    <td>
                                                        {item?.channel_image && (
                                                            <img
                                                                src={`${item?.channel_image}`}
                                                                alt="Channel Image"
                                                                style={{
                                                                    borderRadius: '50%',
                                                                    overflow: 'hidden',
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>{item?.channel_name}</td>
                                                    <td>{item?.total_members}</td>
                                                    <td>{item?.created_on}</td>
                                                    <td>
                                                        {item.channel_status === 'Unpublished' ? (
                                                            <span className="badge bg-dark text-white">Unpublished</span>
                                                        ) : item.channel_status === 'Published' ? (
                                                            <span className="badge bg-success text-white">Published</span>
                                                        ) : null}
                                                    </td>
                                                    <td style={{ paddingTop: '10px' }}>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn"
                                                                type="button"
                                                                id="dropdownMenu2"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="bi bi-three-dots fs-3"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                <li>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                    // onClick={() => handleEditChannel(item.channel_id.toString())}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button className="dropdown-item" type="button">
                                                                        Delete
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                    ) : (
                                        <div className="card card-custom mt-5">
                                        <div className="d-flex justify-content-center p-10">
                                            <div className="car">
                                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                                <h3 className="text-center">No Channels Found</h3>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Channel;





// const handleEditChannel = async (channelId: string) => {
//     try {
//         const response = await channel_edit(channelId);
//         if (response) {
//             // setEditingChannel(get_channel_id);
//             setEditData(response.data.channel_datas);
//             setShowModal(true); // Open the modal for editing
//         }
//     } catch (error) {
//         console.error('Error fetching event details for edit:', error);
//     }
// };