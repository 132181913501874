import React from 'react'
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';


const Setup = () => {
    const location = useLocation();
    return (
        <>
            <div className="card card-custom">
                <img src={toAbsoluteUrl('/media/stock/Banner.png')} />
                <div className='ps-6 pe-6 mt-10'>
                    <h3>Create A Community</h3>
                    <p>Set up the key elements of your community</p>
                    <div className="w-95 bg-light mb-3">
                        <div className="bg-success rounded h-15px" role="progressbar" style={{ width: "50%" }}></div> {/* Increased height from h-5px to h-10px */}
                    </div>
                </div>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_2'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_2'
                            >
                                Create Channel
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_2'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_2'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>
                                <div className='pt-10 pb-20 pe-7'>
                                    <Link to='/admin-panel/settings-channel' className={"nav-link " + (location.pathname === '/admin-panel/settings-channel' && 'active')}>
                                        <button className="btn btn-primary float-end">
                                            Create Channel
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_3'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_3'
                            >
                                Create Event
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_3'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_3'
                            data-bs-parent='#kt_accordion_1'
                        >
                            {/* <div className='accordion-body'>
                                <div className='pt-10 pb-20 pe-7'>
                                    <Link to='/admin-panel/settings-events' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-events') && 'active')}>
                                        <button className="btn btn-primary float-end">
                                            Create Event
                                        </button>
                                    </Link>
                                </div>
                            </div> */}

                            <div className='accordion-body'>
                                <p className='setup_event_para'>
                                    Engage your audience with seamless event management.Plan both online and in-person gatherings effortlessly with clear start and end times.Customize general settings,get real-time notifications,and enjoy the convenience of editing,deleting,publishing or unpublishing events.Keep a watchful eye on RSVPs for a seamless event experience.
                                </p>
                                <Link to='/admin-panel/settings-events' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-events') && 'active')}>
                                    <button className="btn btn-primary float-end mt-5 mb-10">
                                        Create Event
                                    </button>
                                </Link>
                                <div className="embed-responsive embed-responsive-21by9">
                                    <video width="100%" height="400" src={toAbsoluteUrl('/media/video/event.mp4')} controls controlsList="nodownload"></video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_4'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_4'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_4'
                            >
                                Create Article
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_4'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_4'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>  
                                    <p className='setup_event_para'>With our simple article management system,you can easily transform your content.Articles can be easily edited,deleted,published or unpublished while being arranged into pertinent categories and tags.Streamline your content strategy right away!</p>
                                    <Link to='/admin-panel/settings-article/create-articles' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-article/create-articles') && 'active')}>
                                        <button className="btn btn-primary float-end mt-10 mb-10">
                                            Create Article
                                        </button>
                                    </Link> 
                                    <div className="embed-responsive embed-responsive-21by9">
                                        <video width="100%" height="400" src={toAbsoluteUrl('/media/video/article.mp4')} controls controlsList="nodownload"></video>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setup