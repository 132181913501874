import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { POST_Articles_Cate, GET_Articles_Cat, DELETE_Articles_Cate, Articles_Cat_Unpublish, Edit_Cat_Details, Articles_Update_Category } from '../../../modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


const Category = () => {

    const [getCate, setGetCate] = useState<{ article_cat_id: string; article_cat_title: string, article_cat_status: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModel, setEditModel] = useState(false)
    const [searchQuery, setSearchQuery] = useState(''); 
    const [newCategory, setNewCategory] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [loading, setLoading] = useState(false) 
    const [editCat, setEditCat] = useState<{ article_cat_id: string; article_cat_title: string; article_cat_status: string }[]>([]);
    const [categoryId, setCategoryId] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredCategory, setFilteredCategory] = useState<{ article_cat_id: string; article_cat_title: string; article_cat_status: string }[]>([]);
 
 
    const handleOpenModel = () => {
        setShowModal(true); 
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewCategory('');
        setCategoryError('');
    };
 
    const Category_Add = async (values: any) => {
        setLoading(true)
        try {
            const newCategoryValue = values.cate;

            if (!newCategoryValue) {
                setCategoryError('Category name cannot be empty');
                setLoading(false);
                return; // Exit early if the category is empty
            }

            const { data: auth } = await POST_Articles_Cate(newCategoryValue);

            // Check if the API request was successful and log the response
            console.log('API Response:', auth);

            if (auth === 'Already Exist') {
                // Handle the case where the API response indicates that the category already exists
                setCategoryError('Category already exists');
                setLoading(false);
            } else {
                setNewCategory('');
                setCategoryError('');
                setShowModal(false);
                setTimeout(() => {
                    setLoading(false)
                    GetCategory("0");
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successfully Submitted',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }, 500)
            }
        } catch (error) {
            console.error('Error adding category:', error);
            setLoading(false);
        }
    };

    const GetCategory = async (get_articles_cat: any) => {
        try {
            const response = await GET_Articles_Cat(get_articles_cat);
            setGetCate(response.data.article_cat_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetCategory("0");
    }, []);

    const handleCategoryDelete = (article_cat_id: any) => {
        const handleDelete = async () => {
            try { 
                const response = await DELETE_Articles_Cate(article_cat_id); 
                const filterdata = getCate.filter(
                    (item) => item.article_cat_id !== article_cat_id
                );
                setGetCate(filterdata); 
            } catch (error) {
                console.error("Error deleting event:", error);
            }
        };
        handleDelete();
    };

    const handleDeleteConfirmation = (article_cat_id: string) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this Category..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                handleCategoryDelete(article_cat_id);
                Swal.fire(
                    'Deleted!',
                    'Your category has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const handleUnpublish = async (article_cat_id: string, article_cat_status: string) => {
        try {
            const response = await Articles_Cat_Unpublish(article_cat_id, article_cat_status);
            const event_status_new = article_cat_status == '0' ? '1' : '0';
            setGetCate(datas =>
                datas.map(article => article.article_cat_id === article_cat_id ? { ...article, article_cat_status: event_status_new } : article));
        }
        catch (error) { console.error('Error updating event status:', error); }
    };

    const handleEditCategory = async (article_get_id: any) => { 
        try {
            const response = await Edit_Cat_Details(article_get_id); 
            setEditCat(response.data.article_cat_details);
            setEditModel(true)
            setCategoryId(article_get_id)
        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    };

    const handleUpdateCategory = async () => {
        const category_update = document.getElementById('category_title') as HTMLInputElement;
        if (category_update) {
            // Use optional chaining to access the value property safely
            const cat_title = category_update.value;
            setLoading(true)
            try {
                const response = await Articles_Update_Category(categoryId, cat_title);
                GetCategory("0");
                setLoading(false)
                setEditModel(false);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error updating category:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update category. Please try again.',
                });
            }
        }

    }

    useEffect(() => {
        // Filter getCate based on the search query
        const filtered = getCate.filter((Category) => {
            const fullName = `${Category.article_cat_title}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredCategory(filtered);
    }, [searchQuery, getCate]);

    const itemsPerPage = 10;
    const totalItems = filteredCategory.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const CategoryDatas = filteredCategory.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    return (
        <>
            <div className="card card-custom p-5 mt-5">
                <h3>Category</h3>
                <div className='d-flex justify-content-between align-items-center mt-10'>
                    <div className="input-group">
                        <input type="search"  className="form-control w-50"
                            value={searchQuery} placeholder='Search Category...'
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={handleOpenModel}>
                            Create New Category
                        </button>
                    </div>
                </div>
            </div>
            
            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Category</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                value={newCategory}
                                name='cate'
                                onChange={(e: any) => {
                                    setNewCategory(e.target.value);
                                    setCategoryError('');
                                }}
                                className="form-control mt-5"
                                placeholder='Enter New Category'
                            />
                            {categoryError && <div className="text-danger mt-2">{categoryError}</div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Cancel
                                </button>
                                <button type='submit' className={`btn btn-primary ${loading ? 'disabled' : ''}`} onClick={() => Category_Add({ cate: newCategory })}>
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${editModel ? ' show' : ''}`} role="dialog" style={{ display: editModel ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Category</h5>
                            <button type="button" className="btn-close" onClick={() => setEditModel(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="text"
                                id='category_title'
                                value={editCat.map((item) =>
                                    item.article_cat_title
                                )}
                                name='article_cat_upd_title'
                                onChange={(e: any) => {
                                    const updatedEditCat = editCat.map((item: any) => ({
                                        ...item,
                                        article_cat_title: e.target.value
                                    }));
                                    setEditCat(updatedEditCat);
                                    setCategoryError('');
                                }}

                                className="form-control mt-5"
                                placeholder='Enter New Category'
                            />
                            {categoryError && <div className="text-danger mt-2">{categoryError}</div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setEditModel(false)}>
                                    Cancel
                                </button>
                                <button type='submit' className={`btn btn-primary ${loading ? 'disabled' : ''}`} onClick={() => handleUpdateCategory()}>
                                    {loading ? (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (CategoryDatas.length > 0 ? (
                <div className="table-responsive mt-4">
                    <div className="card card-custom mt-4">
                        <div className="card-body">
                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                        <th>Category Name</th>
                                        <th className='text-center'>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CategoryDatas?.map((cat) =>
                                        <tr key={cat.article_cat_id}>
                                            <td>{cat.article_cat_title}</td>
                                            <td className='text-center'>
                                                {cat.article_cat_status === "0" ? (
                                                    <span className="badge bg-dark text-white">Unpublished</span>
                                                ) : cat.article_cat_status === "1" ? (
                                                    <span className="badge bg-success text-white">Published</span>
                                                ) : null}
                                            </td>
                                            <div className="dropdown">
                                                <button className="btn" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi bi-three-dots fs-3"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleEditCategory(cat.article_cat_id)}>Edit</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleDeleteConfirmation(cat.article_cat_id)}>Delete</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleUnpublish(cat.article_cat_id, cat.article_cat_status)}>{cat.article_cat_status === "1" ? ('Unpublish') : ('Publish')}</button></li>
                                                </ul>
                                            </div>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            ) :
                <div className="card card-custom mt-5">
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                            <h3 className="text-center">No Category found</h3>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Category 