import React from 'react'
import { ErrorMessage, Field } from 'formik';

const Settings = () => {
    return (
        <div>
            <div className="form-group">
                <label className='form-label'>General Settings:</label>
                <div className="form-check form-check-custom form-check-solid mt-3 mb-3">
                    <Field
                        type="checkbox"
                        name="event_general_settings"
                        value="Hide members count" // Add a value for each checkbox
                        className="form-check-input"
                        id="Hide members"
                    />
                    <label className="form-check-label" htmlFor="Hide members">Hide members count</label>
                </div>
                {/* <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Enable moderation" className="form-check-input" id="Enable moderation" />
                    <label className="form-check-label" htmlFor="Enable moderation">Enable moderation</label>
                </div>
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Auto-join channel" className="form-check-input" id="Auto-join" />
                    <label className="form-check-label" htmlFor="Auto-join">Auto-join channel</label>
                </div>
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Exclude from the weekly digest" className="form-check-input" id="Exclude from" />
                    <label className="form-check-label" htmlFor="Exclude from">Exclude from the weekly digest</label>
                </div>
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Display available plans" className="form-check-input" id="Display available" />
                    <label className="form-check-label" htmlFor="Display available">Display available plans</label>
                </div>
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Require post title" className="form-check-input" id="Require post" />
                    <label className="form-check-label" htmlFor="Require post">Require post title</label>
                </div> */}
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Hide cover image" className="form-check-input" id="Hide cover" />
                    <label className="form-check-label" htmlFor="Hide cover">Hide cover image</label>
                </div>
                {/* <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Disable comments & reaction" className="form-check-input" id="Disable comments" />
                    <label className="form-check-label" htmlFor="Disable comments">Disable comments & reaction</label>
                </div>
                <div className="form-check form-check-custom form-check-solid mb-3">
                    <Field type="checkbox" name="event_general_settings" value="Require post cover image" className="form-check-input" id="Require post cover" />
                    <label className="form-check-label" htmlFor="Require post cover">Require post cover image </label>
                </div> */}
                <ErrorMessage name="event_general_settings" component="div" className="text-danger" />
            </div>
            <label className="form-label mt-3 mb-3">Notifications</label> 
            <div className="form-check form-check-custom form-check-solid mb-3">
                <Field type="checkbox" name="event_notifications" value="Send RSVP Confirmation" className="form-check-input" id="Send_RSVP" />
                <label className="form-check-label" htmlFor="Send_RSVP">Send RSVP Confirmation</label>
            </div>
            <div className="form-check form-check-custom form-check-solid mb-3">
                <Field type="checkbox" name="event_notifications" value="Send reminder 1 hour before event" className="form-check-input" id="Send_reminder" />
                <label className="form-check-label" htmlFor="Send_reminder">Send reminder 1 hour before event</label>
            </div>
            <ErrorMessage name="event_notifications" component="div" className="text-danger" /> 
        </div>
    )
}

export default Settings