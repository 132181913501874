import React, { FC, useState, useRef, useEffect } from 'react'
import {
    KTIcon, toAbsoluteUrl, toAbsoluteUrlImage, MessageModel, defaultUserInfos,
    defaultMessages, UserInfoModel, messageFromClient, KTSVG
} from '../../_metronic/helpers'
import clsx from 'clsx'
import { getMembers, getMembersProfile } from "../modules/auth/core/_requests";
import { useAuth } from '../modules/auth'

type Props = {
    isDrawer?: boolean
}

const bufferMessages = defaultMessages

interface Member {
    first_name: string;
    last_name: string;
    company: string;
    role: string;
    id: number;
    user_pic: string;
    // Add other properties as needed
}

interface MemberData {
    m_id: number;
    m_first_name: string;
    m_last_name: string;
    m_position: string;
    m_description: string;
    m_location: string;
    m_time: string;
    m_role: string;
    m_company: string;
    m_user_pic: string;
    m_bio: string;
    m_timezone: string;
    m_state: string;
    m_twitter_link: string;
    m_linked_link: string;
    m_fb_link: string;
    m_insta_link: string;
}


const Messages: FC<Props> = ({ isDrawer = false }) => {
    const { currentUser } = useAuth()
    const [userPic, setUserPic] = useState(currentUser?.pic || '')
    const [userName, setUserName] = useState(currentUser?.first_name || '')
    const [membersData, setMembersData] = useState<Member[]>([]);
    const [memberProfileOpen, setMembersProfileOpen] = useState(false);
    const [membersProfile, setMembersProfile] = useState<MemberData[]>([]);
    const [message, setMessage] = useState<string>('')
    const [totalEntries, setTotalEntries] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 12; // Number of records to fetch per page
    const [isLoading, setIsLoading] = useState(true);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [isFetching, setIsFetching] = useState(false); // For handling on-scroll data append
    const observer = useRef<IntersectionObserver | null>(null);
    const lastItemRef = useRef<HTMLDivElement | null>(null);
    const [messages, setMessages] = useState<MessageModel[]>(bufferMessages)
    const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
    const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
    const [activeMemberId, setActiveMemberId] = useState<number | null>(null);


    const totalPages = Math.ceil(totalEntries / limit);

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20; // Add a buffer of 20 pixels
        // console.log(isAtBottom,'bottom');
        if (isAtBottom && !isFetching && currentPage < totalPages) {
            // Reached the bottom of the page, and there's more data to fetch
            setCurrentPage((prevPage) => prevPage + 1);
            setIsFetching(true);
        }
        if (isAtBottom) {
            setIsFetching(true);
            setCurrentPage((prevPage) => prevPage + 1);
            // console.log(currentPage,'current_page');
        }
    };

    const sendMessage = () => {
        const newMessage: MessageModel = {
            user: 2,
            type: 'out',
            text: message,
            time: 'Just now',
        }

        bufferMessages.push(newMessage)
        setMessages(bufferMessages)
        toggleChatUpdateFlat(!chatUpdateFlag)
        setMessage('')
        setTimeout(() => {
            bufferMessages.push(messageFromClient)
            setMessages(() => bufferMessages)
            toggleChatUpdateFlat((flag) => !flag)
        }, 1000)
    }

    const handleObserver: IntersectionObserverCallback = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isFetching && currentPage < totalPages) {
            setIsFetching(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        fetchData();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage]);

    useEffect(() => {
        // Set up the Intersection Observer when the component mounts
        observer.current = new IntersectionObserver(handleObserver, {
            threshold: 1.0,
        });

        if (lastItemRef.current) {
            //console.log("Observing last item");
            observer.current.observe(lastItemRef.current);
        }
        return () => {
            // Cleanup the Intersection Observer when the component unmounts
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [lastItemRef]); // Make sure to include lastItemRef as a dependency here

    const fetchData = async () => {
        try {
            const offset = (currentPage - 1) * limit;
            const searchTerm = "";
            const response = await getMembers('get_members', offset, limit,searchTerm);
            const { data } = response;
            setMembersData((prevData) => [...prevData, ...data.members]);
            setTotalEntries(data.total_record);
            setIsLoading(false);
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
            setIsFetching(false);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleGetUser = (id: number) => async () => {
        setActiveMemberId(id);
        try {
            const response = await getMembersProfile(id)
            const userData = response.data;
            setMembersProfile(userData.members_profile);

            setMembersProfileOpen(true)
            setDetailsLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setMembersProfile([]);
            setDetailsLoading(false);
        }
    };

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    return (
        <>
            <div className='d-flex flex-column flex-lg-row'>
                <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
                    <div className='card card-flush'>
                        <div className='card-header pt-7 search-container' id='kt_chat_contacts_header'>
                            {/* <div className='search-container'> */}
                            <form className='w-100 position-relative' autoComplete='off'>
                                <KTIcon
                                    iconName='magnifier'
                                    className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                                />
                                <input
                                    type='text'
                                    className='form-control form-control-solid px-15'
                                    name='search'
                                    placeholder="Search Member"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </form>
                            {/* </div> */}
                        </div>
                        <div className='card-body pt-5' id='kt_chat_contacts_body'>
                            <div
                                className='scroll-y me-n5 pe-5 h-lg-500px'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                                data-kt-scroll-offset='0px'
                            >
                                {isLoading ? (
                                    // Show loading animation using Bootstrap spinner
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    membersData.filter((item) =>
                                        item?.first_name.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                        .map((members) => (
                                            <>
                                                <div
                                                    key={members.id}
                                                    className={clsx('d-flex flex-stack mb-5', {
                                                        'active_members': members.id === activeMemberId,
                                                    })}
                                                    onClick={handleGetUser(members.id)}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        <div className='symbol symbol-45px symbol-circle'>
                                                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                                {members.user_pic ? (
                                                                    <img src={members.user_pic ? toAbsoluteUrlImage(members.user_pic) : toAbsoluteUrl('/media/avatars/blank.png')} alt='events' style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }} />
                                                                ) : (
                                                                    <div className="symbol symbol-50px">
                                                                        <div className="symbol-label fs-2 fw-bold text-success" style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }}>{members.first_name[0]}</div>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        </div>

                                                        <div className='ms-5'>
                                                            <span className='fs-5 fw-bolder text-gray-900 text-hover-primary cursor-pointer mb-2'>
                                                                {members.first_name} {members.last_name}
                                                            </span>
                                                            <div className='fw-bold text-gray-400'>{members.company}</div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column align-items-end ms-2'>
                                                        <span className='text-muted fs-7 mb-1'>5 hrs</span>
                                                    </div>
                                                </div>
                                                <div className='separator separator-dashed d-none'></div>
                                            </>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {memberProfileOpen ? (
                    detailsLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        // Render the chat content when data has been loaded
                        membersProfile.map((MemberData) => (
                            <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                                <div className='card' id='kt_chat_messenger'>
                                    <div className='card-header search-container' id='kt_chat_messenger_header'>
                                        <div className='card-title'>
                                            <div className='symbol-group symbol-hover'></div>
                                            <div className='d-flex justify-content-center flex-column me-3'>
                                                <span className='fs-4 fw-bolder text-gray-900 text-hover-primary cursor-pointer me-1 mb-2 lh-1'
                                                >
                                                    {MemberData.m_first_name} {MemberData.m_last_name}
                                                </span>

                                                <div className='mb-0 lh-1'>
                                                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                                                    <span className='fs-7 fw-bold text-gray-400'>Active</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='card-toolbar'>
                                            <div className='me-n3'>
                                                <button
                                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                                    data-kt-menu-trigger='click'
                                                    data-kt-menu-placement='bottom-end'
                                                    data-kt-menu-flip='top-end'
                                                >
                                                    <i className='bi bi-three-dots fs-2'></i>
                                                </button>
                                                <Dropdown1 />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div
                                        className='card-body'
                                        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
                                    >
                                        <div
                                            className={clsx('scroll-y me-n5 pe-5', { 'h-350px': !isDrawer })}
                                            data-kt-element='messages'
                                            data-kt-scroll='true'
                                            data-kt-scroll-activate='{default: false, lg: true}'
                                            data-kt-scroll-max-height='auto'
                                            data-kt-scroll-dependencies={
                                                isDrawer
                                                    ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                                                    : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                                            }
                                            data-kt-scroll-wrappers={
                                                isDrawer
                                                    ? '#kt_drawer_chat_messenger_body'
                                                    : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                                            }
                                            data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
                                        >
                                            {messages.map((message, index) => {
                                                const userInfo = userInfos[message.user]
                                                const state = message.type === 'in' ? 'info' : 'primary'
                                                const templateAttr = {}
                                                if (message.template) {
                                                    Object.defineProperty(templateAttr, 'data-kt-element', {
                                                        value: `template-${message.type}`,
                                                    })
                                                }
                                                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'in' ? 'start' : 'end'
                                                    } mb-10`
                                                return (
                                                    <div
                                                        key={`message${index}`}
                                                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                                                        {...templateAttr}
                                                    >
                                                        <div
                                                            className={clsx(
                                                                'd-flex flex-column align-items',
                                                                `align-items-${message.type === 'in' ? 'start' : 'end'}`
                                                            )}
                                                        >
                                                            <div className='d-flex align-items-center mb-2'>
                                                                {message.type === 'in' ? (
                                                                    <>
                                                                        <div className='symbol  symbol-35px symbol-circle '>
                                                                            {MemberData.m_user_pic ? (
                                                                                <img src={MemberData.m_user_pic ? toAbsoluteUrlImage(MemberData.m_user_pic) : toAbsoluteUrl('/media/avatars/blank.png')} alt='events' style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }} />
                                                                            ) : (
                                                                                <div className="symbol symbol-50px">
                                                                                    <div className="symbol-label fs-2 fw-bold text-success" style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }}>{MemberData.m_first_name[0]}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className='ms-3'>
                                                                            <span className='fs-5 fw-bolder text-gray-900 text-hover-primary cursor-pointer me-1'
                                                                            >
                                                                                {MemberData.m_first_name}
                                                                            </span>
                                                                            <span className='text-muted fs-7 mb-1'>{message.time}</span>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className='me-3'>
                                                                            <span className='text-muted fs-7 mb-1'>{MemberData.m_time}</span>
                                                                            <span className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1 cursor-pointer'
                                                                            >
                                                                                You
                                                                            </span>
                                                                        </div>
                                                                        <div className='symbol  symbol-35px symbol-circle '>
                                                                            {userPic ? (
                                                                                <img src={userPic ? toAbsoluteUrlImage(userPic) : toAbsoluteUrl('/media/avatars/blank.png')} alt='events' style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }} />
                                                                            ) : (
                                                                                <div className="symbol symbol-50px">
                                                                                    <div className="symbol-label fs-2 fw-bold text-success" style={{ borderRadius: '50%', overflow: 'hidden', width: '45px', height: '45px', objectFit: 'cover' }}>{userName[0]}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>

                                                            <div
                                                                className={clsx(
                                                                    'p-5 rounded',
                                                                    `bg-light-${state}`,
                                                                    'text-dark fw-bold mw-lg-400px',
                                                                    `text-${message.type === 'in' ? 'start' : 'end'}`
                                                                )}
                                                                data-kt-element='message-text'
                                                                dangerouslySetInnerHTML={{ __html: message.text }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div
                                            className='card-footer pt-4'
                                            id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                                        >
                                            <textarea
                                                className='form-control form-control-flush mb-3'
                                                rows={1}
                                                data-kt-element='input'
                                                placeholder='Type a message'
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                onKeyDown={onEnterPress}
                                            ></textarea>

                                            <div className='d-flex flex-stack'>
                                                <div className='d-flex align-items-center me-2'>
                                                    <button
                                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                                        type='button'
                                                        data-bs-toggle='tooltip'
                                                        title='Coming soon'
                                                    >
                                                        <i className='bi bi-paperclip fs-3'></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                                        type='button'
                                                        data-bs-toggle='tooltip'
                                                        title='Coming soon'
                                                    >
                                                        <i className='bi bi-upload fs-3'></i>
                                                    </button>
                                                </div>
                                                <button
                                                    className='btn btn-primary'
                                                    type='button'
                                                    data-kt-element='send'
                                                    onClick={sendMessage}
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                ) : (
                    // Render alternative content when memberProfileOpen is false
                    <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                        <div className='card h-100' id='kt_chat_messenger'>
                            <div
                                className='card-body'
                                id='kt_chat_messenger_body'
                            >
                                <div className="d-flex justify-content-center p-10">
                                    <div className="car">
                                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                        <h3 className="text-center">Start the Message</h3>
                                    </div>
                                </div>
                                <div
                                    className='card-footer pt-4'
                                    id='kt_chat_messenger_footer'
                                >
                                    {/* Footer content for the alternative view */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Messages

{/* <span>
        <KTSVG path="/media/icons/duotune/arrows/arr002.svg" className="svg-icon-muted svg-icon-2hx" />
     </span> */}