/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,useState,useEffect } from 'react'
import {Link} from 'react-router-dom'
import { DashboardOnboarding } from './DashboardOnboarding'
import { events_get_data } from '../../modules/auth/core/_requests'
import {
  ListsWidget2,
  MixedWidget7,
  TablesWidget9

} from '../../../_metronic/partials/widgets'

import {
  ListsWidgetUpcomingEvents,

} from '../../../_metronic/partials/widgets/lists/UpcomingEventsList'


import {useAuth} from '../../modules/auth'


interface Props {
  userfname: string;
}

 //const [show, setShow] = useState(false);


 interface EventsDatas {
  event_name: string;
  event_start_date: string;
  event_end_date: string;
  event_time_zone: string;
  event_created_on: string;
  event_created_by: string;
  event_start_time: string;
  event_end_time: string;
  event_status: string;
  event_description:string;
  event_id:string;
  event_location:string;
}




const DashboardPage: React.FC<Props> =  ({userfname}) => (

  <>
  <div className='row gy-5 g-xl-8'>
     <div className='col-xl-4 u_intro pt-20 ps-10'>
          <h1 className='dash_userintro'>Hi { userfname }!  </h1>
          <h2 className='dash_intro'>What do you want<br></br>
          to <span className='learn_highlight'>learn</span> today?</h2>
          <p>Invest in yourself and take the first<br></br>
          step towards achieving your dreams</p>
          <Link to='/members'>
          <button
                type='button'
                className={`btn btn-success border-0  w-50 me-n3 mt-5 mb-10`}
              >Start Networking</button>
          </Link>
      </div>
      <div className='col-xl-4 mb-2'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4 mb-2'>
          <MixedWidget7
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='200px'
          />
        </div>

    </div>

  </>
)

const DashboardWrapper: FC = () => {
 // const intl = useIntl()
 const {currentUser, logout} = useAuth()
 const [userfname, setUserFname] = useState(currentUser?.first_name || '')
 const [onboardingcheck, setonboardingcheck] = useState(currentUser?.onboarding || '')
 const [usertoken, setUsertoken] = useState(currentUser?.auth?.api_token || '')

 const [upcomingEvents, setupcomingEvents] = useState<EventsDatas[]>([]);
 const [loading, setLoading] = useState(false);

   const handleUpcomingEvents = async (upcoming_events: any) => {
       try {
         setLoading(true);
          const response = await events_get_data(upcoming_events);
          setupcomingEvents(response.data.upcoming_events);
          setLoading(false);
       } catch (error) {
          console.error('Error fetching events data:', error);
       }
   }
   useEffect(() => {
    handleUpcomingEvents("1");
  }, []);

 return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage
      userfname={userfname}
      />
       {onboardingcheck==='0'? <DashboardOnboarding /> : ''}
       <div className='card p-3'>
        <h3 className='mb-5 p-5'>Upcoming Events</h3>
       {loading ? (
        <div
        className="spinner-border text-success"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
     upcomingEvents.length > 0 ? (
       upcomingEvents.map((upcomingEvent, index) => (
                <div key={index}>
       { upcomingEvent.event_status=='1'?(
 <ListsWidgetUpcomingEvents
event_title= {upcomingEvent.event_name}
event_description = {upcomingEvent.event_description}
event_startdate = {upcomingEvent.event_start_date}
event_starttime = {upcomingEvent.event_start_time}
event_enddate = {upcomingEvent.event_end_date}
event_endtime = {upcomingEvent.event_end_time}
event_timezone = {upcomingEvent.event_time_zone}
event_id= {upcomingEvent.event_id}
event_location= {upcomingEvent.event_location}
event_status = {upcomingEvent.event_status}
/>
):('')}
             </div>
          ))
          ) : !loading && (
            <p>No Event found</p>
          )
    )}
</div>
    </>
  )
}

export {DashboardWrapper}
