import React from 'react'
import { Link } from 'react-router-dom'
import {useLocation} from 'react-router'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Analytics = () => {
    const location = useLocation()

    return (
        <>
            {/* <div className="card card-custom">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 members_tab">
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-analytics/engagement' className={"nav-link " + (location.pathname === '/admin-panel/settings-analytics/engagement' && 'active')}>
                            Engagement
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-analytics/subscription' className={"nav-link " + (location.pathname === '/admin-panel/settings-analytics/subscription' && 'active')}>
                            Subscription
                        </Link>
                    </li>
                </ul>
            </div> */}
            <div className="card card-custom">
                <div className="d-flex justify-content-center p-10">
                    <div className="car">
                        <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                        <h3 className="text-center">Coming Soon!</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analytics