import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

const AdminPanelTab = () => {
    const location = useLocation();

    return (
        <>
            <div className="admin_panel_tab shadow-sm p-3 mb-5 bg-body rounded h-100">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 flex-column">
                    <li className="nav-item admin_panel_setup">
                        <Link to="/admin-panel/settings-setup" className={'nav-link ' + (location.pathname === '/admin-panel/settings-setup' && 'active')}>
                            Set up
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-channel' className={"nav-link " + (location.pathname === '/admin-panel/settings-channel' && 'active')}>
                            Channels
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-members' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-members') && 'active')}>
                            Members
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-analytics' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-analytics') && 'active')}>
                            Analytics
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-message' className={"nav-link " + (location.pathname === '/admin-panel/settings-message' && 'active')}>
                            Message
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to='/admin-panel/settings-get-started' className={"nav-link "+ (location.pathname === '/admin-panel/settings-get-started' && 'active')}>
                            Get Started
                        </Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link to='/admin-panel/settings-onboarding' className={"nav-link "+ (location.pathname.includes('/admin-panel/settings-onboarding') && 'active')}>
                            Onboarding
                        </Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-events' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-events') && 'active')}>
                            Events
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settingss-members_questions' className={"nav-link " + (location.pathname.includes('/admin-panel/settingss-members_questions') && 'active')}>
                            Members Questions
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/admin-panel/settings-article' className={"nav-link " + (location.pathname.includes('/admin-panel/settings-article') && 'active')}>
                            Articles
                        </Link>
                    </li> 
                </ul>
            </div>
        </>
    );
}

export default AdminPanelTab;
