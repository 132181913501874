import React from 'react'

const RSVP = () => {
    return (
        <div className="tab-pane" id="rsvp" role="tabpanel">
            <div className='d-flex justify-content-between align-items-center mt-10'>
                <div className="input-group">
                    <input type="search" id="form1" className="form-control w-50" />
                    <button type="button" className="btn btn-primary">
                        <i className="fas fa-search"></i>
                    </button>
                </div>
                <div>
                    <a href="#" className="btn btn-primary">Export List</a>
                    <a href="#" className="btn btn-primary ms-10">Invite</a>
                </div>
            </div>
            <div className="py-5">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                    <thead>
                        <tr className="fw-bolder fs-6 text-gray-800">
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Date Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Tiger Nixon</td>
                            <td>System Architect</td>
                            <td>Edinburgh</td>
                            <td>61</td>
                        </tr>
                        <tr>
                            <td>Garrett Winters</td>
                            <td>Accountant</td>
                            <td>Tokyo</td>
                            <td>63</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RSVP