import React from 'react'

const Pending_invition = () => {

    return (
        <>
            <div className="card card-custom p-5 mt-5">
                <div className='d-flex justify-content-between align-items-center mt-10'>
                    <div className="input-group">
                        <input type="search" id="form1" className="form-control w-50" />
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div>
                        <a href="#" className="btn btn-primary">Invite Members</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pending_invition