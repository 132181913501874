import React, { useState } from 'react';

interface ReadMoreProps {
    readmore: string;
}

  const ReadMore: React.FC<ReadMoreProps> = ({ readmore }) => {
  const MAX_WORDS = 60;
  const contnetWords = readmore.split(' ');
  const iscontentLong = contnetWords.length > MAX_WORDS;
  const [showFullContent, setshowFullContent] = useState(false);

  return (
    <p className="mt-3" style={{ whiteSpace: 'pre-wrap' }}>
      {iscontentLong && !showFullContent
        ? contnetWords.slice(0, MAX_WORDS).join(' ') + '... '
        : readmore }
      {iscontentLong && (
        <span className='text-primary' style={{cursor:'pointer'}} onClick={() => setshowFullContent(!showFullContent)}>
          {showFullContent ? ' View Less' : ' View More'}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
