/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { KTIcon, KTSVG, toAbsoluteUrl, toAbsoluteUrlImage } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  const [userpic, setUserPic] = useState(currentUser?.pic || '')
  const [userrole, setUserrole] = useState(currentUser?.admin || '')

  return (
    <>
      <div className="aside-user d-flex align-items-sm-center justify-content-center pb-5 mb-5 ps-2  pe-2">

        <div className="symbol symbol-50px">
          {(userpic) ? <img src={toAbsoluteUrlImage(userpic)} alt='User Pic' /> : <div className="symbol-label fs-5 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
        </div>

        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">

          <div className="d-flex">

            <div className="flex-grow-1 me-2">

              <a href="#" className="text-white text-hover-primary fs-6 fw-bold">    {currentUser?.first_name} {currentUser?.last_name}</a>

              <span className="text-gray-600 fw-semibold d-block fs-8 mb-1">{currentUser?.role}</span>

              <div className="d-flex align-items-center text-success fs-9">
                <span className="bullet bullet-dot bg-success me-1"></span>online</div>

            </div>

            {/* <div className="me-n2">

              <a href="#" className="btn btn-icon btn-sm btn-active-color-primary mt-n2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-overflow="true">
                <i className="ki-duotone ki-setting-2 text-muted fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </a>

              <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">

                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">

                    <div className="symbol symbol-50px me-5">
                    {(currentUser?.pic!=null)? <img src={toAbsoluteUrl(currentUser?.pic)} alt='User Pic' /> : <div className="symbol-label fs-5 fw-bold text-success"> {currentUser?.first_name.charAt(0)}{currentUser?.last_name.charAt(0)}</div>}
                    </div>

                    <div className="d-flex flex-column">
                      <div className="fw-bold d-flex align-items-center fs-5">    {currentUser?.first_name} {currentUser?.last_name}
                        <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span>
                        </div>
                      <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{currentUser?.role} </a>
                    </div>

                  </div>
                </div>

                <div className="separator my-2"></div>


                <div className='menu-item px-5 my-1'>
                  <Link to='/crafted/account/settings' className='menu-link px-5'>
                    Account Settings
                  </Link>
                </div>

                <div className='menu-item px-5'>
                  <a onClick={logout} className='menu-link px-5'>
                    Sign Out
                  </a>
                </div>
              </div>

            </div> */}

          </div>

        </div>

      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'>
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}

      <SidebarMenuItem
        to='/posts'
        icon='bi bi-link-45deg'
        title='Feed'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem to="/members"
        fontIcon='bi-layers'
        title="Members"
        icon='abstract-12' />

      <SidebarMenuItem to="/events"
        fontIcon='bi-layers'
        title="Events"
        icon='abstract-26' />

      <SidebarMenuItem to="/articles"
        fontIcon='bi-layers'
        title="Articles"
        icon='abstract-17' />

      {/* <SidebarMenuItem
        to='/channels'
        title='Channels'
        fontIcon='bi-chat-left'
        icon='message-text-2' /> */}

{/* <SidebarMenuItem
        to='/chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
        title='Message'
      /> */}

      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Channels'
        fontIcon='bi-chat-left'
        icon='message-text-2'>
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
    <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem to="/my-courses"
        fontIcon='bi-layers'
        title="Learning"
        icon='abstract-27' /> */}

      <SidebarMenuItem
        to='/crafted/account/overview'
        icon='profile-circle'
        fontIcon='bi-person'
        title='Profile'
      />

      {userrole === "Admin" ? (
        <div>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin Panel</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/admin-panel'
            icon='setting-2'
            title='Settings'
          />
        </div>
      ) : null}


      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}



    </>
  )
}

export { SidebarMenuMain }
