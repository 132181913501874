import React, { useEffect, useRef, useState } from 'react' 
import axios from 'axios'
import { useAuth } from '../../../../modules/auth'
import { KTIcon } from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import EventDetails from './EventDetails'
import Settings from './Settings'
import RSVP from './RSVP'


export interface EventsPostDatas {
  event_name: string;
  event_time_zone: string;
  event_pricing: string;
  event_start_date: Date | string;
  event_start_time: string;
  event_end_date: Date | string;
  event_end_time: string;
  event_location: string;
  onlineEventUrl: string;
  inPersonEventUrl: string;
  event_description: string;
  event_lockscreen: boolean;
  event_access_level: string;
  event_visibility: string;
  event_custom_url: string;
  event_cover_img: string;
  event_general_settings: string[];
  event_notifications: string[];
  event_created_by: string;
}

const Horizontal = () => {
      
    const { currentUser, logout } = useAuth()
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const [createData, setCreateData] = useState<EventsPostDatas[]>([]);
    const [isSubmitButton, setSubmitButton] = useState(false);
    const [userfname, setUserFname] = useState(currentUser?.first_name || '')
    const [userlname, setUserLname] = useState(currentUser?.last_name || '')
    const userFullName = `${currentUser?.first_name || ''} ${currentUser?.last_name || ''}`;
      

    const initialValues: EventsPostDatas ={
        event_name: '',
        event_time_zone: '',
        event_pricing: '',
        event_start_date: '',
        event_start_time: '',
        event_end_date: '',
        event_end_time: '',
        event_location: '',
        onlineEventUrl: '',
        inPersonEventUrl: '',
        event_description: '',
        event_lockscreen: false,
        event_access_level: '',
        event_visibility: '',
        event_custom_url: '',
        event_cover_img: '',
        event_general_settings: [],
        event_notifications: [],
        event_created_by: userFullName
    }

    const validationSchema = Yup.object().shape({
        event_name: Yup.string().required('Event Name is required'),
        event_time_zone: Yup.string().required('Event Time Zone is required'),
        event_pricing: Yup.string().required('Event Pricing is required'),
        event_start_date: Yup.date().required('Start Date is required'),
        event_start_time: Yup.string().required('Start Time is required'),
        event_end_date: Yup.date().required('End Date is required'),
        event_end_time: Yup.string().required('End Time is required'),
        onlineEventUrl: Yup.string().test('required', 'Online event URL is required', function (value) {
            return this.parent?.event_location === 'Online' ? !!value : true;
        }),
        inPersonEventUrl: Yup.string().test('required', 'Location event URL is required', function (value) {
            return this.parent?.event_location === 'In-Person' ? !!value : true;
        }),
        event_description: Yup.string().required('Event Description is required'),
        event_access_level: Yup.string().required('Access Level is required').min(1, 'Select at least one Access Level'),
        event_visibility: Yup.string().required('Visibility Setting is required').min(1, 'Select at least one Visibility Setting'),
        event_custom_url: Yup.string().required('Custom URL is required'),
        // event_cover_img: Yup.string().required('Cover image is required'),
        // event_general_settings: Yup.array().required('General Settings is required').test('at-least-one-setting', 'Select at least one General Setting', (value) => value && value.length > 0),
        // event_notifications: Yup.array().required('Notification is required').test('at-least-one-setting', 'Select at least one Notification', (value) => value && value.length > 0),
    });

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    };

    const handlePrevious = () => {
        if (!stepper.current) {
            return;
        }
        stepper.current.goPrev();
        const updatedEventsData = [...createData];
        setCreateData(updatedEventsData);
        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber);
    }
 
    const handleSubmit = async (values: any, actions: FormikValues) => {
        console.log('Form values:', values);
        if (!stepper.current) {
            return;
        }

        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            stepper.current.goNext();
        } else {
            try {
                const formData = new FormData();
                console.log('FormData:', formData)
                // Append form fields
                formData.append('event_name', values.event_name);
                formData.append('event_time_zone', values.event_time_zone);
                formData.append('event_pricing', values.event_pricing);
                formData.append('event_start_date', values.event_start_date);
                formData.append('event_start_time', values.event_start_time);
                formData.append('event_end_date', values.event_end_date);
                formData.append('event_location', values.event_location);
                formData.append('event_end_time', values.event_end_time);
                formData.append('onlineEventUrl', values.onlineEventUrl);
                formData.append('inPersonEventUrl', values.inPersonEventUrl);
                formData.append('event_description', values.event_description);
                formData.append('event_lockscreen', values.event_lockscreen);
                formData.append('event_access_level', values.event_access_level);
                formData.append('event_visibility', values.event_visibility);
                formData.append('event_custom_url', values.event_custom_url);
                formData.append('event_general_settings', JSON.stringify(values.event_general_settings));
                formData.append('event_notifications', JSON.stringify(values.event_notifications));
                formData.append('event_created_by', userFullName)
                // Append cover image as binary data 
                //  formData.append('event_cover_img', values.event_cover_img); 
                const imageFileInput = document.querySelector<HTMLInputElement>('#event_cover_img');
                // console.log('Image File Input:', imageFileInput);
                if (imageFileInput?.files && imageFileInput.files.length > 0) {
                    formData.append("event_cover_img", imageFileInput.files[0]);
                    console.log('After appending file, FormData:', formData);
                }

                // Send the POST request with FormData
                const response = await axios.post('https://digitalfactory.co.in/lc_app_test/api/events', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Important header for sending binary data
                    },
                });

                if (response.status === 200) {
                    // Handle successful response
                    console.log('Form data submitted successfully');
                }

                // Reset the form after successful submission
                // actions.resetForm();
                // setSelectedFile(null);
                // setImagePreview(null);
                // setFileName('');
                // stepper.current?.goto(1);
            } catch (error) {
                // Handle error
                console.error('Error submitting form data:', error);
            }
        }
        setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber);
    };

    useEffect(() => {
        if (!stepperRef.current) {
            return;
        }

        loadStepper();
    }, [stepperRef]);


    return (
        <div className='card'>
            <div className='card-body'>
                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='stepper-nav'>
                        <div className='stepper-item current' data-kt-stepper-element='nav'>
                            <h3 className='stepper-title'>Event Details</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 className='stepper-title'>RSVP</h3>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                            <h3 className='stepper-title'>Settings</h3>
                        </div>
                    </div>

                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                        {() => (
                            <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                                <div className='current' data-kt-stepper-element='content'> 
                                    <EventDetails   />
                                </div>
                                <div data-kt-stepper-element='content'>
                                    <RSVP />
                                </div>
                                <div data-kt-stepper-element='content'>
                                    <Settings />
                                </div>
                                <div className='d-flex flex-stack pt-15'>
                                    <div className='mr-2'>
                                        <button
                                            onClick={handlePrevious}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button
                                            type='submit'
                                            className='btn btn-lg btn-primary me-3' 
                                        >
                                            <span className='indicator-label'>
                                                {!isSubmitButton && 'Continue'}
                                                {isSubmitButton && 'Submit'}
                                                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Horizontal