import {useState,useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestOTP} from '../core/_requests'
import {useNavigate} from "react-router-dom";

const current_lc  = (localStorage.getItem('lc_useremail')!);
//console.log(current_lc);

const initialValues = {
  otp: '',
  lc_email: ''
}

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .min(6, 'Enter 6 Digits OTP')
    .max(6, 'Enter 6 Digits OTP')
    .required('OTP is required'),
})

export function Otp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [lc_email,setuserEmail] = useState(localStorage.getItem('lc_useremail')!);


  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestOTP(values.otp,lc_email)
          .then(response => {
            //console.log(response.data)
         const response_status =  (response.data!='Not Validated') ?  (navigate("/auth/password"),(setLoading(false)),setSubmitting(false),setHasErrors(false))
         : (formik.setErrors({otp:"OTP is Invalid"}),(setLoading(false)),setSubmitting(false));

          })
       .catch(({response}) => {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('The Otp is InCorrect')
       })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_otp_validate'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        {/* <h1 className='text-dark fw-bolder mb-3'>OTP</h1> */}
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6 p-2'>
        Please enter the OTP below to verify your Email Address. If you do not find the email in your inbox, make sure you check your spam folder.        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-success p-5 rounded'>
          <div className='text-dark'>Successfully Verified. Please Wait...</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6 text-center'>  Enter the 6 digit OTP</label>
        <input
          type='text'
          placeholder=''
          autoComplete='off'
          maxLength={6}
          {...formik.getFieldProps('otp')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.otp && formik.errors.otp},
            {
              'is-valid': formik.touched.otp && !formik.errors.otp,
            }
          )}
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.otp}</span>
            </div>
          </div>
        )}
      </div>
      <input
          type='hidden'
          name='lc_email'
        value='323'
        />
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
