import React from 'react'

const Invite_link = () => {
    return (
        <>
            Invite_link
        </>
    )
}

export default Invite_link